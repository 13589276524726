import React from 'react';
import PageMargin from '../page-margin/page-margin';

export const PrivacyPolicy: React.FC = () => {
  return (
    <PageMargin>
      <div className="my-20 flex flex-col gap-12 text-3xl font-medium leading-loose">
        <h1 className="text-4xl font-bold">
          Política de Privacidade - The Blue City - Atualizada em 20 de maio de 2023
        </h1>
        <p>
          Nós da The Blue City (“The Blue City”, “Nós” ou “Plataforma”) somos uma empresa de
          tecnologia que oferece aplicativos de jogos para computadores e dispositivos móveis e que
          oferecem experiências imersivas (“Aplicativos”) assim como outros serviços relevantes
          (“Serviços”) para nossos usuários (“Usuário”, “Usuários”, “Você”), marcas e empresas
          contratantes, parceiros e/ou terceiros que prestam ou utilizam nossos serviços
          (“Terceiros”).
        </p>
        <p>
          Utilizamos o site “https://missaocidadeazul.app” (“site da The Blue City”) e nossos
          aplicativos para a prestação dos nossos serviços, disponibilização de conteúdo
          informativo, divulgação dos nossos Serviços, assim como para coleta de e-mails, dados de
          geolocalização e outros dados relevantes dos Usuários.
        </p>
        <p>
          A presente Política de Privacidade tem como objetivos fornecer todas as informações
          necessárias sobre a coleta, utilização, armazenamento, compartilhamento e outros tipos de
          tratamento dos seus dados pessoais em relação a utilização dos nossos Serviços e navegação
          em nosso site, além de mostrar as nossas práticas de proteção de dados, tudo de forma
          regulamentada e de acordo com as melhores práticas da Lei Geral de Proteção de Dados
          Pessoais (LGPD) e de toda a legislação considerada pertinente.{' '}
        </p>
        <p>
          Nós levamos a sua privacidade muito a sério e só utilizaremos os seus dados para as
          finalidades específicas mencionadas na presente Política de Privacidade!
        </p>
        <p>
          Ao utilizar nossos Serviços você declara o seu consentimento expresso, ou seja, que você
          concorda de forma explícita, que nós podemos coletar, tratar e armazenar os seus dados de
          acordo com os termos e condições descritos abaixo.
        </p>
        <p>
          A The Blue City somente realizará o tratamento de dados do Usuário de acordo com os Termos
          de Uso e Política de Privacidade aqui mencionados e obterá seu consentimento conforme seja
          necessário para a prestação dos Serviços oferecida, mas o Usuário entende que esses
          terceiros são responsáveis por obter o seu consentimento relacionado ao tratamento de
          dados realizados por eles e que a The Blue City, como entidade independente, não possui
          qualquer tipo de controle ou direcionamento e não pode ser responsabilizado quanto ao
          tratamento realizado por eles no âmbito de suas operações ou a segurança desses dados no
          ambiente dessas instituições, especialmente considerando o fato de que eles estarão
          sujeitos às políticas de privacidade, segurança e outras políticas relevantes desses
          terceiros.
        </p>
        <h1 className="text-4xl font-bold">
          1. Quais informações coletamos e para quais finalidades as utilizamos?
        </h1>
        <p>
          Ao utilizar o site da The Blue City e/ou os seus Serviços você autoriza expressamente a
          coleta e o uso dos seguintes dados para as seguintes finalidades:
        </p>
        <p>
          Dados para Prestação de Serviços. Para o fim da prestação dos Serviços que oferecemos,
          coletamos os dados fornecidos por você no cadastro, assim como outros dados fornecidos por
          você por meio do uso de QR codes e outras formas de interação com os nossos Serviços, com
          destaque para nome e sobrenome, endereço para correspondência, endereço de e-mail,
          informações de pagamento, localização e outras informações de contato online ou número de
          telefone.
        </p>
        <p>
          Quando você usa nossos Serviços, pode ser necessário ter uma conta em um serviço externo
          de login único. Nesses casos, os dados pessoais que coletamos dependem das contas externas
          que você escolhe usar, das políticas de privacidade dessas contas e das configurações de
          privacidade que você permite que visualizemos quando usa esses serviços para acessar
          nossos Serviços.
        </p>
        <p>
          Se você decidir vincular sua conta do Google aos nossos Serviços, coletaremos seu endereço
          de e-mail do Google e um token de autenticação fornecido pelo Google.
        </p>
        <p>
          Se você optar por vincular sua conta do Tik tok aos nossos Serviços, coletaremos um ID de
          usuário exclusivo fornecido pelo Tik Tok e, se você autorizar, seu e-mail registrado no
          Tik Tok.
        </p>
        <p>
          Dados de Menores de Idade. Não realizamos o tratamento de dados de Crianças. Podemos
          realizar a coleta e o tratamento de dados pessoais de menores de idade entre 12 e 17 anos
          sempre no melhor interesse destes titulares e em observância às diretrizes da Lei nº.
          13.709/2018 – Lei Geral de Proteção de Dados Pessoais (LGPD).
        </p>
        <p>
          Comunicação. Nós registramos e gravamos todos os dados fornecidos por você em comunicação
          com nossa equipe através de e-mail, mensagens de WhatsApp e outras formas de comunicação
          para fins de registro e boa prestação dos Serviços contratados.
        </p>
        <p>
          Informações sobre pagamentos. Ao submeter seus dados para pagamento, nós coletamos as
          informações sobre a transação realizada. Isso abrange suas informações de pagamento, como
          os dados do seu cartão de crédito ou débito, informações de conta e autenticação, além dos
          dados de faturamento, tais como endereço completo, CPF e CNPJ.
        </p>
        <p>
          Dados de Ações e Dispositivos dos Usuários. Coletamos e utilizamos as ações e conquistas
          no(s) jogo(s) e no(s) aplicativo(s), assim como algumas informações sobre o dispositivo
          móvel do Usuário (como identificadores de dispositivo, sistema operacional, modelo,
          configuração, ajustes e informações sobre aplicativos ou software de terceiros instalados
          no dispositivo). Esses dados são coletados para operar nossos Serviços e personalizar a
          experiência do Usuário. A The Blue City também gera um ID de conta interno quando você usa
          determinados Serviços, para associá-lo a uma conta específica.
        </p>
        <p>
          Fornecer, melhorar e desenvolver os Serviços disponíveis. Usamos os seus dados para
          melhorar a qualidade, personalização e eficiência dos nossos Serviços.
        </p>
        <p>
          Informações que você oferece. Coletamos os dados fornecidos por você como nome e
          sobrenome, endereço para correspondência, endereço de e-mail, informações de pagamento,
          endereço IP, bem como outras informações de contato online ou número de telefone, foto e
          demais informações requeridas no cadastro ou vinculados às mídias utilizados no login que
          você nos fornecer.
        </p>
        <p>
          Podemos registrar e gravar todos os dados fornecidos em toda comunicação realizada com
          nossa equipe através dos formulários de contato do site da The Blue City e trocas de
          e-mails ou Aplicativos realizados após esse contato inicial.
        </p>
        <p>
          Endereço eletrônico (e-mail). Ao fazer login no Aplicativo ou preencher e enviar um
          formulário de contato no site da The Blue City, nós coletamos o seu e-mail para fins
          cadastrais, pelo qual ocorrerão as comunicações de atualizações do Aplicativo e
          gerenciamento da sua conta.
        </p>
        <p>
          Cookies. Registramos dados de sua visita ao site da The Blue City através de cookies
          (pequenos arquivos que permitem identificar as preferências do Usuário) e outras
          tecnologias incluindo seu endereço IP e nome de domínio, a versão do seu navegador e do
          seu sistema operacional, dados de tráfego online, dados de localização, logs da web,
          modelo do dispositivo utilizado para o acesso e outros dados de navegação.
        </p>
        <p>
          Utilizamos os cookies e outras tecnologias semelhantes somente para fornecer e apoiar
          nossos Serviços através da personalização do conteúdo informativo compartilhado, de forma
          a proporcionar a melhor experiência possível para nossos clientes.
        </p>
        <p>
          Construção de conteúdo. Todo conteúdo compartilhado, ou divulgado, seja através de
          comentários, dicas ou sugestões de melhorias compõem o banco de dados da The Blue City
          viabilizando a melhoria e conhecimento dos produtos e serviços oferecidos pela empresa.
        </p>
        <p>
          Para reorganização interna ou mudanças operacionais e gerenciais. Os dados coletados
          poderão ser utilizados para uma eventual reorganização interna ou para transferência de
          dados a terceiros ou seus assessores como parte de um processo de due diligence com a
          finalidade de analisar uma proposta de reorganização;
        </p>
        <p>
          Monitoramento de uso. Utilizamos seus dados para monitorar atividades dentro do(s) jogo(s)
          e aplicativo(s) com finalidade de garantir a qualidade do serviço, o atendimento às leis
          aplicáveis, o cumprimento de procedimentos internos como a Política de Propriedade
          Intelectual, as Regras e Diretrizes da The Blue City e o Código de Conduta da The Blue
          City e para combater a fraude.
        </p>
        <p>
          Obrigações legais. Podemos ser obrigados por lei a compartilhar seus dados com terceiros
          para cumprir exigências legais, regulatórias ou fiscais, envolvendo a divulgação de seus
          dados pessoais a terceiros, a um tribunal, reguladores ou agências governamentais.
        </p>
        <p>
          Personalização de anúncios. Utilizamos as informações acima mencionadas para personalizar
          os anúncios mostrados em nossos aplicativos, tornando-os mais relevantes para o Usuário.
          Além disso, exibimos presentes patrocinados com base na sua localização, contendo
          mensagens e/ou ofertas dos nossos anunciantes em locais patrocinados e/ou relevantes para
          a sua área no mapa do jogo.
        </p>
        <p>
          O Usuário pode optar por não receber presentes patrocinados visitando as configurações do
          aplicativo. Além disso, ele pode escolher cancelar os anúncios personalizados visitando as
          configurações do seu dispositivo e ativando a opção "Limitar o Rastreamento de Anúncios"
          em dispositivos Apple ou "Cancelar a Personalização de Anúncios" em dispositivos Android.
        </p>
        <p>
          Criação de Conteúdo. Conforme disposto nos Termos de Uso, também coletamos todo e qualquer
          Conteúdo fornecido pelo Usuário, como imagens, fotos, textos e/ou vídeos, para fornecer
          recursos sociais dentro dos jogos e aplicativos, permitindo que o Usuário interaja e jogue
          com outros jogadores. Isso inclui armazenar suas comunicações, conteúdo e outras
          informações compartilhadas com a The Blue City e com esses jogadores, encontrar e ser
          encontrado por seus amigos (inclusive através de contatos importados por Terceiros) e
          compartilhar suas conquistas e experiências de jogo com eles.
        </p>
        <p>
          É importante destacar que, conforme mencionado nos Termos de Uso, podemos monitorar e
          moderar o conteúdo que o Usuário fornece em nossos Serviços para proteger a The Blue City,
          evitar Trapaças e garantir os direitos e a segurança de outros usuários.
        </p>
        <p>
          Eventos ao vivo. Coletamos e utilizamos suas informações, como suas ações e realizações no
          jogo, seu nome de usuário no jogo e seu avatar, equipe e outros componentes do seu perfil
          no jogo, para organizar e realizar Eventos ao vivo com base em nossos jogos. É importante
          notar que, ao participar desses eventos ao vivo, suas informações e atividades no jogo
          podem se tornar visíveis para outros participantes do evento e para o público em geral,
          como em rankings exibidos durante o evento e online. Isso permite uma interação social e
          competitiva entre os jogadores durante esses eventos.
        </p>
        <p>
          Conforme mencionado nos Termos de Uso, ao participar de Eventos organizados pela The Blue
          City, o Usuário concorda e autoriza que a empresa grave e utilize sua imagem, nome, voz,
          diálogo, informações biográficas e características pessoais. Essa autorização é gratuita e
          não está sujeita ao pagamento de royalties ou qualquer contrapartida, estando sujeita aos
          mesmos termos do Conteúdo produzido pelo Usuário.
        </p>
        <p>
          Rastreamento em Segundo Plano. Ao optar por ativar o rastreamento de atividade em segundo
          plano, coletamos dados pessoais, como sua localização e dados de atividade física, para
          fornecer funcionalidades específicas nos nossos Serviços, como recompensas vinculadas à
          sua atividade física, alertas de eventos próximos e personalização da experiência de jogo.
          O Usuário tem a opção de desativar o rastreamento de atividade em segundo plano a qualquer
          momento nas configurações do seu dispositivo ou do aplicativo.
        </p>
        <p>
          Importar listas de Amigos do Facebook. Caso o Usuário opte por permitir a permissão
          "Amigos Usuários do Facebook" em nossos jogos e aplicativos, importamos a lista de amigos
          que também jogam o jogo e que habilitaram essa opção. Nesse caso, sua imagem de perfil do
          Facebook e nome da conta serão visíveis para seus amigos no jogo. O Usuário pode cancelar
          a amizade com outros jogadores e revogar as permissões do Facebook para nossos jogos e
          aplicativos nas configurações do aplicativo ou nas configurações da sua conta do Facebook.
        </p>
        <p>
          Agendamento de Eventos no Calendário. Se o Usuário adicionar informações sobre eventos ao
          vivo da The Blue City ou seus parceiros dentro do jogo ao seu calendário móvel, com sua
          permissão, teremos acesso ao seu calendário para gravar esses eventos. Não acessamos ou
          coletamos outras informações do seu calendário e o Usuário pode desativar o acesso ao
          calendário a qualquer momento nas configurações do seu dispositivo.
        </p>
        <p>
          Conexão com Dispositivos Bluetooth. Ao conectar sua conta do jogo a dispositivos externos
          autorizados, solicitamos permissões de Bluetooth para conectar o dispositivo e ativar
          recursos associados ao jogo. O Usuário pode desativar o acesso Bluetooth nas configurações
          do seu dispositivo ou desconectar o dispositivo externo do jogo.
        </p>
        <p>
          Importação de Contatos do Dispositivo. Ao importar contatos do seu dispositivo para
          encontrar amigos que também usam nossos serviços, sincronizamos periodicamente as
          informações de seus contatos, incluindo números de telefone e e-mails. É sua
          responsabilidade garantir que a coleta e o compartilhamento dessas informações estejam em
          conformidade com as leis aplicáveis e que você tenha a permissão de seus contatos. Essas
          informações são usadas para ajudar o Usuário e seus contatos a se conectarem por meio dos
          nossos serviços e o Usuário pode desativar o acesso da The Blue Cityaos seus contatos a
          qualquer momento nas configurações do seu dispositivo.
        </p>
        <p>
          Compras. Se o Usuário decidir vincular sua conta da Amazon, Google Wallet ou similar para
          comprar produtos físicos dentro do jogo, coletaremos um token de autenticação fornecido
          pela Amazon ou empresa parceira. A Amazon e/ou a empresa parceira também compartilhará
          conosco seu endereço parcial de entrega e informações parciais de pagamento para facilitar
          a entrega do produto. o Usuário pode desvincular sua conta dessas empresas seguindo as
          instruções no aplicativo.
        </p>
        <p>
          Armazenamento de Conteúdo. Caso o Usuário faça upload de conteúdo, como fotos ou vídeos,
          para nossos serviços, acessaremos, com sua permissão, o armazenamento de mídia do seu
          dispositivo. O Usuário pode desativar o acesso ao armazenamento de mídia a qualquer
          momento nas configurações do seu dispositivo.
        </p>
        <p>
          Eventuais dados e documentos adicionais poderão ser coletados durante a sua relação com a
          The Blue City para cumprimento dos Serviços, sendo estes tratados em conformidade com as
          disposições desta Política.
        </p>
        <h1 className="text-4xl font-bold">2. Com quem compartilhamos seus dados?</h1>
        <p>
          Parceiros e Fornecedores. Compartilhamos os seus dados com nossos parceiros e fornecedores
          apenas para fins de viabilizar pagamentos e o processamento de dados, prestação de suporte
          técnico ao Usuário, bem como para efetivar e tornar nossos Serviços mais relevantes e
          eficientes às suas finalidades.
        </p>
        <p>
          Os prestadores de serviços e fornecedores que contratamos desempenham várias funções
          importantes para garantir o funcionamento dos nossos Serviços. Alguma dessas principais
          funções incluem: utilizar plataformas e ferramentas de software de outras empresas para
          executar, operar e manter nossos serviços, fazer a moderação do conteúdo gerado pelos
          usuários e identificar possíveis falhas nos nossos serviços, realizar campanhas de e-mail
          e enviar mensagens para dispositivos móveis, como notificações sobre eventos e
          atualizações; analisar o desempenho dos nossos jogos, aplicativos e estratégias de
          marketing, oferecer serviços de medição e exibir anúncios personalizados com base nos
          interesses dos usuários. No entanto, você pode optar por não receber esses serviços em
          sites específicos, organizar eventos ao vivo, concursos, sorteios e promoções, incluindo o
          registro de participantes, verificação de elegibilidade e entrega de prêmios, processar
          pagamentos de bilhetes para eventos ao vivo e outras compras feitas pelos usuários e
          fornecer suporte técnico e auxílio ao cliente para solucionar problemas e responder às
          perguntas dos usuários.
        </p>
        <p>
          Quando o Usuário usa nossos serviços, especialmente ao jogar nossos jogos e interagir com
          outros jogadores, algumas informações pessoais serão compartilhadas com esses jogadores.
          Essas informações incluem seu perfil no jogo, como seu nome de usuário, avatar, status
          online e equipe, suas ações e conquistas no jogo, suas localizações no mundo real
          associadas ao jogo e as mensagens e conteúdo que você compartilha com outros jogadores.
        </p>
        <p>
          Alguns jogos ou aplicativos podem ter recursos de compartilhamento que permitem divulgar
          dados pessoais e informações a terceiros fora dos nossos serviços, como por exemplo o
          upload de vídeos para o YouTube. É importante ressaltar que a The Blue City não controla
          nem é responsável pelas práticas desses recursos de compartilhamento com Terceiros.
        </p>
        <p>
          Quando o Usuário joga nossos jogos, usa recursos competitivos ou participa de nossos
          fóruns, algumas informações sobre o Usuário podem ser compartilhadas em páginas da web
          acessíveis ao público. Essas informações incluem seu nome de usuário, perfil no jogo ou no
          aplicativo, conquistas e mensagens públicas.
        </p>
        <p>
          É importante estar ciente de que, ao compartilhar essas informações, elas se tornarão
          disponíveis publicamente. Portanto, é sempre recomendável ter cuidado ao compartilhar
          informações pessoais em ambientes públicos.
        </p>
        <p>
          Para fins de análise de dados e estatísticas relevantes sobre o uso e alcance do site da
          The Blue City assim como para criação dos e-mails da newsletter, compartilhamos seus dados
          com alguns parceiros, com destaque para: DigitalOcean, Google Analytics, Yellow Panda e
          Amazon Web Services (AWS).
        </p>
        <p>
          Além deles, podemos ser legalmente obrigados a compartilhar seus dados com terceiros para
          cumprir exigências legais, regulatórias ou fiscais, envolvendo a divulgação de seus dados
          pessoais a terceiros, a um tribunal, reguladores ou agências governamentais, como a
          própria Autoridade Nacional de Proteção de Dados (ANPD).
        </p>
        <p>
          Dependendo da localização do cliente ou dos Serviços que serão prestados, a The Blue City
          pode transferir os dados coletados para outros parceiros localizados no Brasil e em outros
          países, como Portugal e Estados Unidos, e esses dados serão utilizados somente para os
          fins para o qual foram contratados.
        </p>
        <p>
          Procuramos contratar apenas parceiros que adotam todos os padrões necessários e adequados
          a LGPD ou legislação equivalente, como a General Data Protection Regulation (GDPR), de
          forma que os dados não poderão ser utilizados para outros fins e serão mantidos de acordo
          com padrões adequados de segurança.
        </p>
        <p>
          Se a The Blue City estiver envolvida em uma fusão, aquisição ou venda de todos ou de parte
          de seus ativos, os dados pessoais do cliente poderão ser compartilhados com o adquirente.
          Nesses casos você será devidamente notificado sobre qualquer alteração e transferência dos
          seus dados.
        </p>
        <h1 className="text-4xl font-bold">3. Dados de Crianças</h1>
        <p>
          Conforme mencionado nos Termos de Uso, as Crianças não podem usar os nossos serviços e não
          temos a intenção de coletar seus dados pessoais.
        </p>
        <p>
          Segundo a Lei 8.069 de 1.990 que instituiu o Estatuto da Criança e do Adolescente(ECA), é
          considerado criança quem tem até 12 anos incompletos, enquanto quem tem de 12 a 18 anos é
          considerado adolescente.
        </p>
        <h1 className="text-4xl font-bold">4. Por quanto tempo armazenamos os seus dados?</h1>
        <p>
          Nós manteremos seus dados pessoais somente pelo período que for necessário para o
          cumprimento das finalidades pelos quais os coletamos, pelo período que julgamos necessário
          de acordo com a necessidade de proteção legal da Aplicativo ou até que ocorra a
          solicitação de exclusão definitiva por sua parte.
        </p>
        <p>
          Caso o Usuário solicite a exclusão dos seus dados, cessaremos imediatamente a utilização
          deles para todo e qualquer fim comercial. Entretanto, continuaremos armazenando seus dados
          enquanto possuirmos obrigações legais, tributárias ou judiciais a cumprir em relação a
          eles.
        </p>
        <h1 className="text-4xl font-bold">5. Onde armazenamos os dados que você nos fornece?</h1>
        <p>
          Os dados coletados são armazenados e tratados no armazenamento interno dos computadores e
          notebooks da Aplicativo, assim como na Amazon Web Services (AWS), um serviço de
          armazenamento em nuvem da Amazon.
        </p>
        <p>
          Isso é feito para fins de melhorar o desempenho e proteger os dados de forma segura no
          caso de uma falha ou outro problema.{' '}
        </p>
        <h1 className="text-4xl font-bold">
          6. Qual o nosso compromisso com a transparência e com a segurança dos seus dados?
        </h1>
        Todos os dados que você nos fornece são tratados unicamente para atingir as finalidades
        listadas acima.
        <p>
          Seus dados são armazenados em nossos computadores e servidores seguros ou de nossos
          fornecedores contratados e são acessados e utilizados de acordo com nossas políticas e
          padrões de segurança somente por nós e nossos parceiros.
        </p>
        <p>
          Todo o tráfego entre nossos servidores ou entre o seu computador e nossos servidores é
          criptografado para Hypertext Transfer Protocol Secure (HTTPS) através do protocolo seguro
          Secure Sockets Layer (SSL)/Transport Layer Security (TLS) ou nível de criptografia
          semelhante.
        </p>
        <p>
          Tomamos medidas de boas práticas e certificações existentes no mercado para garantir que
          os dados que coletamos sejam processados de acordo com segurança onde quer que estejam
          localizados.
        </p>
        <p>
          O fator humano muitas vezes é o elo mais fraco na segurança da informação, de modo que
          nossos funcionários são treinados em conceitos de cibersegurança para diminuir ao máximo o
          risco de dados e recomendamos o mesmo tipo de treinamento para todos os nossos clientes e
          parceiros.
        </p>
        <p>
          É impossível garantir a segurança total dos dados armazenados e usos indevidos por parte
          do Usuário ou cliente, como softwares e hardwares inseguros, que podem comprometer a
          segurança do sistema.
        </p>
        <p>
          Caso você verifique uma falha de segurança ou acredite que exista qualquer indício de
          vulnerabilidade em relação aos seus dados, por favor entre em contato direto com nosso
          encarregado no e-mail “contato@thebluecity.app”.
        </p>
        <h1 className="text-4xl font-bold">
          7. Quais são os direitos do titular de dados pessoais?
        </h1>
        <p>
          Na posição de titular dos dados pessoais você tem os direitos listados abaixo e pode
          exercê-los mediante um pedido por escrito para o nosso encarregado no e-mail
          contato@thebluecity.app.
        </p>
        <p>
          Devido a nossa política de segurança e para evitar hacking social ou técnicas similares de
          obtenção de dados e outras informações restritas, os dados solicitados somente serão
          informados mediante a verificação de identidade do titular de dados pessoais a partir de
          meios seguros.
        </p>
        <p>
          a) Confirmação de existência de tratamento: esse direito permite que você saiba se estamos
          ou não tratando dos seus dados pessoais.
        </p>
        <p>
          b) Acesso aos dados: esse direito permite que você tenha acesso aos seus dados pessoais
          que tratamos.
        </p>
        <p>
          c) Correção de dados incompletos, inexatos ou desatualizados: esse direito permite que
          você solicite a correção dos seus dados pessoais tratados por nós em relação ao fato deles
          estarem incompletos, pouco exatos, desatualizados ou incorretos.
        </p>
        <p>
          d) Anonimização, bloqueio ou eliminação de dados desnecessários, excessivos ou tratados em
          desconformidade com o disposto a LGPD: esse direito permite que você solicite a
          anonimização, bloqueio ou eliminação dos seus dados caso o tratamento seja desnecessário
          ou esteja em desconformidade com a LGPD. Caso seja necessário manter seus dados em nossos
          servidores para fins de proteção da Aplicativo ou terceiros ou requisitos legais, isso
          será justificado em nossa resposta ao seu pedido.
        </p>
        <p>
          e) Portabilidade dos dados a outro fornecedor de serviço ou produto, mediante requisição
          expressa, de acordo com a regulamentação da autoridade nacional, observados os segredos
          comercial e industrial: esse direito permite que você receba seus dados para que os
          utilize com outro fornecedor de Serviços. Entretanto, a Aplicativo se reserva a não
          entregar dados que comprometam nossa propriedade intelectual ou segredos comerciais.
        </p>
        <p>
          f) Eliminação dos dados pessoais tratados com o consentimento do titular, exceto nas
          hipóteses previstas no art. 16 da LGPD: esse direito permite que você solicite a
          eliminação dos seus dados pessoais com exceção de casos de cumprimento de obrigação legal
          ou regulatória pelo Aplicativo, transferência já realizada a terceiro de acordo com os
          requisitos da LGPD e de dados que tenham sido anonimizados para uso exclusivo do
          Aplicativo.
        </p>
        <p>
          g) Informação das entidades públicas e privadas com as quais o controlador compartilhou
          dados: esse direito permite que você seja informado se nós precisamos compartilhar seus
          dados com um órgão público, como por exemplo a Autoridade Nacional de Proteção de Dados
          (ANPD).
        </p>
        <p>
          h) Informação sobre a possibilidade de não fornecer consentimento e sobre as consequências
          da negativa: esse direito permite que você saiba que é possível não fornecer o
          consentimento para o tratamento dos dados de forma completa ou específica em relação às
          finalidades mencionadas acima e quais os efeitos em relação a isso. No caso dos Serviços
          de personalização do site, a revogação do consentimento sobre uso de cookies apenas
          tornará a experiência menos personalizada e impedirá que ela seja aprimorada. Agora, é
          importante fazer a ressalva de que existem casos em que o tratamento dos dados pelo
          Aplicativo ou seus parceiros seja essencial para a prestação dos Serviços contratados, de
          forma que a necessidade é legítima e está acima de uma eventual negativa.
        </p>
        <p>
          i) Não fornecimento ou revogação do consentimento: esse direito permite que você não
          forneça ou revogue seu consentimento em relação ao tratamento de dados pessoais. É
          importante mencionar que a revogação do consentimento não afeta os tratamentos de dados
          realizados antes desta solicitação.
        </p>
        <h1 className="text-4xl font-bold">
          8. Como acessar e controlar as informações que você forneceu para nós?
        </h1>
        <p>
          A gestão dos seus dados é feita de forma segura pela The Blue City e como já mencionado
          acima, você pode solicitar informações, alterações ou exclusões em relação aos seus dados
          ou solucionar quaisquer dúvidas sobre a presente Política de Privacidade através do
          contato do nosso encarregado no e-mail “contato@thebluecity.app”.
        </p>
        <p>
          Após verificar a sua identidade através de um meio seguro, nós responderemos a sua
          solicitação em até 10 (dez) dias úteis.
        </p>
        <p>
          Você também tem o direito de entrar em contato diretamente com a Autoridade Nacional de
          Proteção de Dados (ANPD).{' '}
        </p>
        <h1 className="text-4xl font-bold">9. Atualizações desta Política de Privacidade</h1>
        <p>
          A The Blue City sempre está em constante evolução e aprimoramento, de forma que essa
          Política de Privacidade passa por alterações à medida que nossos Serviços evoluem.
        </p>
        <p>
          A versão mais atualizada da presente Política de Privacidade sempre pode ser encontrada no
          nosso site, mas sempre informaremos nossos clientes sobre eventuais modificações e
          alterações relevantes.
        </p>
        <p>
          Você pode sugerir quaisquer modificações e solucionar quaisquer dúvidas sobre a presente
          Política de Privacidade através do contato do nosso encarregado no e-mail
          “contato@thebluecity.app”.
        </p>
      </div>
    </PageMargin>
  );
};
