import jwt from "jwt-decode";
import { Prefs } from "../App";

const teacherTokenDidNotExpire = () => {
    const timestampMillis = Date.now();
    const timestampSeconds = Math.floor(timestampMillis / 1000);

    const jwt_values: Prefs = jwt(localStorage.getItem("teacher-token") || "");
    return true
};

export default teacherTokenDidNotExpire;
