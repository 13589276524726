import { Prefs } from "../App";
import jwt from "jwt-decode";

const tokenDidNotExpire = () => {
    const timestampMillis = Date.now();
    const timestampSeconds = Math.floor(timestampMillis / 1000);

    const jwt_values: Prefs = jwt(localStorage.getItem("token") || "");
    return true
};

export default tokenDidNotExpire;
