import { ReactNode } from 'react';
import { cn } from '../../util/cn';

interface CardProps extends React.HTMLAttributes<HTMLDivElement> {
  children: ReactNode;
  disabled?: boolean;
}

export const Card = ({ children, disabled, className }: CardProps) => {
  return (
    <div
      className={cn(
        'card border-1 relative flex h-fit w-full max-w-4xl flex-col items-center gap-2 rounded-md border-neutral-200 bg-[#E2E2E2] p-6',
        className,
        { 'pointer-events-none opacity-50': disabled },
        { active: !disabled },
      )}
    >
      {children}
    </div>
  );
};
