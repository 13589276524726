import React from 'react';

import { Outlet } from 'react-router-dom';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { LoadingProvider } from './hooks/use-loading';
import { PageContent } from './components/page-content/page-content';
import { PlayingProvider } from './context/playing';

export enum Page {
  LOGIN,
  REGISTER,
  CHOOSE_AVATAR,
  CHOOSE_CITY,
  JOURNAL,
  VIDEO_GALLERY,
  PROFILE,
  LOGIN_ADMIN,
  START,
}

export type Prefs = {
  email: string;
  type: string;
  city?: string;
  game?: string;
  avatar?: string;
  name?: string;
  avatarName?: string;
  exp?: number;
};

const queryClient = new QueryClient();

export default function App() {
  return (
    <React.Fragment>
      <QueryClientProvider client={queryClient}>
        <LoadingProvider>
          <ToastContainer stacked style={{ fontSize: '14px' }} toastStyle={{ padding: '20px' }} />
          <PlayingProvider>
            <PageContent>
              <Outlet />
            </PageContent>
          </PlayingProvider>
        </LoadingProvider>
        <ReactQueryDevtools initialIsOpen={false} />
      </QueryClientProvider>
    </React.Fragment>
  );
}
