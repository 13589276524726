import jwt from "jwt-decode";
import { Prefs } from "../App";

const getClient = (): string => {
    let client = "";

    const token = localStorage.getItem("token");
    if (token) {
        const jwt_values: Prefs = jwt(token);
        if (jwt_values && (jwt_values.type === "TEACHER" || jwt_values.type === "ADMIN")) {
            return client;
        }
    }

    if (window.location.pathname.startsWith("/basf")) client = "basf";
    if (window.location.pathname.startsWith("/aegea")) client = "aegea";

    return client;
};

export default getClient;
