/* eslint-disable import/no-anonymous-default-export */
import { useCookies } from 'react-cookie';
import Button from './Button';
type Props = {
  active: boolean;
  setActive: React.Dispatch<React.SetStateAction<boolean>>;
};

export default ({ active, setActive }: Props) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [_, setCookie] = useCookies(['accept-cookies']);
  const handleAccept = () => {
    setCookie('accept-cookies', 'true');
    setActive(false);
  };

  const handleReject = () => {
    setCookie('accept-cookies', 'false');
    setActive(false);
  };

  if (!active) return null;

  return (
    <div className="fixed inset-x-0 bottom-0 z-[999] mx-auto mb-10 flex h-fit w-[90%] flex-col items-center justify-center rounded-2xl bg-primary p-8 text-center text-2xl text-primaryForeground shadow-2xl">
      <p className="font-thin">
        Ao acessar o site da Missão Cidade Azul você pode concordar ou não com o uso de cookies. Nós
        respeitamos a sua privacidade e utilizamos essa ferramenta apenas para oferecer a você
        conteúdo personalizado e a melhor experiência de navegação possível.
      </p>
      <div className="mt-8 flex flex-row items-center justify-center gap-20">
        <Button
          className="h-12 text-2xl sm:h-14 sm:text-3xl md:h-16"
          onClick={handleAccept}
          value="Aceitar"
        />
        <Button
          className="h-12 text-2xl sm:h-14 sm:text-3xl md:h-16"
          onClick={handleReject}
          value="Rejeitar"
        />
      </div>
    </div>
  );
};
