import React from 'react';
import Masonry from 'react-masonry-css';

type PaginatedVideoListProps = {
  renderItems: () => JSX.Element[];
  itemsPerPage?: number;
  currentPage?: number;
  setCurrentPage: React.Dispatch<React.SetStateAction<number>>;
};

const PaginatedVideoList: React.FC<PaginatedVideoListProps> = ({
  renderItems,
  itemsPerPage = 3,
  currentPage = 0,
  setCurrentPage,
}) => {
  const startIndex = React.useMemo(() => currentPage * itemsPerPage, [currentPage, itemsPerPage]);
  const endIndex = React.useMemo(() => startIndex + itemsPerPage, [startIndex, itemsPerPage]);
  const items = React.useMemo(() => renderItems(), [renderItems]);
  const paginatedItems = React.useMemo(() => {
    return items?.slice(startIndex, endIndex);
  }, [items, endIndex, startIndex]);

  const handleNext = () => {
    if (endIndex < items.length) {
      setCurrentPage((prevPage) => {
        if (prevPage >= items.length) return prevPage;
        return prevPage + 1;
      });
    }
  };

  const handlePrevious = () => {
    if (startIndex > 0) {
      setCurrentPage((prevPage) => {
        if (prevPage === 0) return 0;
        return prevPage - 1;
      });
    }
  };

  const breakpointColumnsObj = React.useMemo(() => {
    return {
      default: 3,
      1024: 2,
      768: 1,
    };
  }, []);

  return (
    <div className="flex w-full flex-col justify-between">
      {/* <div className="grid h-full w-full grid-cols-1 place-items-center gap-8 md:grid-cols-2 md:place-items-start lg:grid-cols-3"> */}
      <Masonry
        breakpointCols={breakpointColumnsObj}
        className="flex w-full gap-4"
        columnClassName="flex flex-col gap-4"
      >
        {paginatedItems}
      </Masonry>
      {/* </div> */}
      {items.length > itemsPerPage && (
        <div className="mt-16 flex w-full items-center justify-center">
          <div className="mt-4 flex w-full items-center justify-center gap-24">
            <button
              className="w-fit disabled:cursor-not-allowed"
              onClick={handlePrevious}
              disabled={startIndex === 0}
            >
              <img
                className="h-14 w-14"
                src="/img/icons/previous-button.png"
                alt="Previous Button"
              />
            </button>
            <button
              className="w-fit disabled:cursor-not-allowed"
              onClick={handleNext}
              disabled={endIndex >= items.length}
            >
              <img className="h-14 w-14" src="/img/icons/next-button.png" alt="Next Button" />
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default PaginatedVideoList;
