// import jwt from 'jwt-decode';
// import teacherTokenDidNotExpire from '../../util/teacherTokenDidNotExpire';
// import tokenDidNotExpire from '../../util/tokenDidNotExpire';
// import { useEffect } from 'react';
// import { useLocation } from 'react-router-dom';
// import { Prefs } from '../../App';

import { Paragraph } from './Paragraph';

const NotFound = () => {
  //   const location = useLocation();
  //   useEffect(() => {
  //     const token = localStorage.getItem('token');
  //     if (token) {
  //       const jwt_values: Prefs = jwt(token);

  //       if (!tokenDidNotExpire()) {
  //         localStorage.clear();
  //       }

  //       if (jwt_values.type === 'USER') {
  //         if (location.pathname.startsWith('/aegea')) {
  //           window.location.href = '/aegea';
  //         }
  //         if (location.pathname.startsWith('/basf')) {
  //           window.location.href = '/basf';
  //         }

  //         window.location.href = '/';
  //       } else if (jwt_values.type === 'ADMIN') {
  //         window.location.href = '/superadmin-dashboard';
  //       }
  //     }

  //     const teacherToken = localStorage.getItem('teacher-token');
  //     if (teacherToken) {
  //       if (!teacherTokenDidNotExpire()) {
  //         if (localStorage.getItem('teacher-token')) {
  //           localStorage.removeItem('teacher-token');
  //         }
  //       }
  //       window.location.href = '/painelprofessor';
  //     }
  //   }, [location.pathname]);

  return <Paragraph className="text-center font-semibold">Página não encontrada.</Paragraph>;
};

export default NotFound;
