import * as React from 'react';
import { toast, ToastContainer } from 'react-toastify';
import getHref from '../../util/getHref';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useNavigate } from 'react-router-dom';
import { register } from '../../service';
import { InputForm } from '../Input-form';
import { PageLayout } from '../page-layout';
import { usePlaying } from '../../context/playing';
import { EmphasisTitle } from '../common/EmphasisTitle';
import { Paragraph } from '../common/Paragraph';
import Button from '../common/Button';
import { SocialLogin } from '../common/social-login';

interface FormValues {
  email: string;
  password: string;
  confirmPassword: string;
}

const validationSchema = Yup.object({
  email: Yup.string().required('Campo obrigatório').email('Email inválido'),
  password: Yup.string()
    .required('Campo obrigatório')
    .min(8, 'A senha deve ter no mínimo 8 caracteres'),
  confirmPassword: Yup.string()
    .required('Campo obrigatório')
    .oneOf([Yup.ref('password')], 'As senhas não coincidem'),
});

const Register = () => {
  const [registerSuccess, setRegisterSuccess] = React.useState<boolean>(false);
  const [registerError, setRegisterError] = React.useState<boolean>(false);
  const [registerErrorMessage, setRegisterErrorMessage] = React.useState<string>();
  const { origin } = usePlaying();
  const [loading, setIsLoading] = React.useState<boolean>(false);

  const navigate = useNavigate();

  const handleCreateAccount = async (values: FormValues) => {
    try {
      setIsLoading(true);
      await register(values.email, values.password, origin);
      setRegisterSuccess(true);
    } catch (error: any) {
      setRegisterError(() => {
        setRegisterErrorMessage('Olá, ' + error?.response?.data?.message);
        return true;
      });
      console.error({ error });
    } finally {
      setIsLoading(false);
    }
  };

  React.useEffect(() => {
    setTimeout(() => {
      setRegisterError(() => {
        setRegisterErrorMessage(undefined);
        return false;
      });
    }, 4000);
  }, [registerError]);

  const formik = useFormik({
    initialValues: {
      email: '',
      password: '',
      confirmPassword: '',
    },
    onSubmit: handleCreateAccount,
    validationSchema: validationSchema,
  });

  return (
    <PageLayout className="mx-0 px-10 py-0 font-[Raleway] md:py-8">
      <div className="mx-0 flex h-full w-full items-center justify-center">
        <div className="flex w-full flex-col items-center justify-center sm:w-[45rem]">
          <img
            className="h-72 w-72 object-contain"
            src={
              origin.includes('neoenergia')
                ? '/img/rockinrio/logo-rock-neo.png'
                : '/svg/cidadeazullogo.svg'
            }
            alt="logo"
          />
          <EmphasisTitle className="mb-8 mt-2 text-secondary">Crie sua conta:</EmphasisTitle>
          {registerSuccess ? (
            <div className="w-full sm:w-4/6">
              <Paragraph className="mb-8 text-center font-semibold text-[#DE3838]">
                Olá! Um email de confirmação de conta foi enviado ao seu email.
                <br /> Confira na caixa de entrada ou spam.
              </Paragraph>
              <Button className="w-full" onClick={() => navigate(getHref('login'))} value="Login" />
            </div>
          ) : (
            <>
              <div className="w-full items-center justify-center">
                <form
                  onSubmit={formik.handleSubmit}
                  className="flex w-full items-center justify-center"
                >
                  <div className="flex w-full flex-col items-center justify-center gap-6">
                    <InputForm
                      className="px-8 text-start"
                      name="email"
                      label="Email"
                      placeholder="Email"
                      formik={formik}
                      value={formik.values.email}
                      error={formik.errors.email}
                    />
                    <InputForm
                      className="px-8 text-start"
                      name="password"
                      label="Senha"
                      placeholder="Senha"
                      formik={formik}
                      value={formik.values.password}
                      error={formik.errors.password}
                      type="password"
                    />
                    <InputForm
                      className="px-8 text-start"
                      name="confirmPassword"
                      label="confirmPassword"
                      placeholder="Confirme senha"
                      formik={formik}
                      value={formik.values.confirmPassword}
                      error={formik.errors.confirmPassword}
                      type="password"
                    />
                    {registerError && (
                      <Paragraph className="text-center font-semibold text-[#DE3838]">
                        {registerErrorMessage}
                      </Paragraph>
                    )}
                    <Button
                      className="w-full"
                      type="submit"
                      value={!loading ? 'Criar' : 'Carregando...'}
                      inactive={loading}
                    />
                  </div>
                </form>
              </div>
              <SocialLogin setLoginError={setRegisterError} hasAccount hasStudent />
            </>
          )}
        </div>
      </div>
      <ToastContainer stacked style={{ fontSize: '14px' }} toastStyle={{ padding: '20px' }} />
    </PageLayout>
  );
};

export default Register;
