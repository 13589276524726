import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { GAME_URL } from '../../config';
import { getProgress } from '../../service';
import { usePlaying } from '../../context/playing';

const deparaGameUrl: Record<string, string> = {
  'water-reuse': `${GAME_URL}/MapaAgua-Reuso`,
  'sanitation-history': `${GAME_URL}/MundoSaneamento-Historia`,
  'recyclable-waste': `${GAME_URL}/MapaReuso-Organico`,
  'test-rockinrio': `https://game-dev.missaocidadeazul.app/test-rockinrio`,
};

const Game = () => {
  const navigate = useNavigate();
  const { gameId } = useParams();
  const token = getToken();
  const url = createUrl(token, gameId);
  const { origin, setIsPlaying } = usePlaying();

  React.useLayoutEffect(() => {
    if (deparaGameUrl[gameId || 'not-a-game'] === undefined) {
      navigate('/');
    }
  }, [gameId, navigate]);

  React.useEffect(() => {
    const vlibras = document.getElementById('vlibras');
    if (vlibras) {
      vlibras.style.display = 'none';
    }
    setIsPlaying(true);
    getProgress().catch(() => {
      localStorage.clear();
      navigate('/login');
    });
  }, [navigate]);

  React.useEffect(() => {
    if (!localStorage.getItem('avatar') || !localStorage.getItem('avatarName')) {
      navigate('/choose-avatar');
      return;
    }
  }, [navigate]);

  const isGamingNeoEnergia = origin.includes('neoenergia');

  return (
    <div
      className={`flex ${isGamingNeoEnergia ? 'h-[100vh]' : 'h-[calc(100vh-4rem)]'} flex-row bg-black`}
    >
      <iframe
        allow="camera"
        id="game"
        title="Game"
        src={url}
        style={{
          width: '100%',
          height: '100%',
        }}
      />
    </div>
  );
};

function getToken() {
  return window.localStorage.getItem('token');
}

function createUrl(token: string | null, gameId: string = 'water-reuse'): string {
  console.log(`${deparaGameUrl[gameId]}/index.html?token=${token}&nowTime=${Date.now()}`);
  return `${deparaGameUrl[gameId]}/index.html?token=${token}&nowTime=${Date.now()}`;
}

export default Game;
