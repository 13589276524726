import React, { useMemo, useRef, useState } from 'react';
import { useOnClickOutside } from '../../hooks/use-on-click-outside';
import Button from '../common/Button';
import { Input } from '../input';

type Option = {
  value: string;
  label: string;
  parent?: string;
};

type DropdownMenuProps =
  | {
      type: 'list';
      label: string;
      value: string;
      options: Option[];
      onChange: (value: string) => void;
      parentState?: string;
      disabled?: boolean;
    }
  | {
      type: 'date';
      label: string;
      value: { start?: string; end?: string };
      options?: Option[];
      onChange: (value: { start?: string; end?: string }) => void;
      parentState?: string;
      disabled?: boolean;
    };

interface DropdownMenuItemProps {
  id: string;
  key: string | number;
  onClick: () => void;
  isParentDisabled?: boolean;
  option: string;
  isSelected: boolean;
  disabled?: boolean;
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const DropdownMenuItem = ({
  key,
  onClick,
  option,
  isSelected,
  disabled,
  isParentDisabled,
  id,
}: DropdownMenuItemProps) => {
  return (
    <div
      id={id}
      className={`${disabled || isParentDisabled ? 'cursor-not-allowed bg-[#94c2e5] text-zinc-200' : 'cursor-pointer hover:bg-tertiary'} w-80 min-w-fit border-b bg-secondary p-8 text-3xl font-bold leading-relaxed transition-all duration-300 first-of-type:rounded-t-md last-of-type:rounded-b-md last-of-type:border-b-0`}
      key={key}
      onClick={() => {
        if (!disabled) onClick();
      }}
    >
      {isSelected && (
        <div className="absolute right-8 top-[calc(50%-0.5rem)] h-4 w-4 rounded-full bg-blue-50"></div>
      )}
      {option}
    </div>
  );
};

export const DropdownMenu = (props: DropdownMenuProps) => {
  const [open, setOpen] = useState<boolean>(false);
  const { type, label, options, parentState, disabled } = props;
  const ref = useRef<HTMLDivElement>(null);
  useOnClickOutside(ref, () => setOpen(false));

  const renderOptions = useMemo(() => {
    if (type === 'date') {
      const { value, onChange } = props;
      return (
        <div
          ref={ref}
          className="absolute top-20 z-50 flex w-80 flex-col gap-4 rounded-md bg-secondary px-3 py-4 font-[Raleway] text-blue-50"
        >
          <p className="ml-2 text-2xl font-bold text-white">Data inicial</p>
          <Input
            className="h-18"
            name="start"
            placeholder="Data inicial"
            type="date"
            value={value.start}
            onChange={(e) => {
              const errorText = document.getElementById('errorDataMessage');
              errorText && (errorText.innerHTML = '');
              onChange({ ...value, start: e.target.value, end: undefined });
            }}
          />
          <p className="ml-2 text-2xl font-bold text-white">Data final</p>
          <Input
            className="h-18"
            name="end"
            placeholder="Data final"
            type="date"
            value={value.end}
            onChange={(e) => {
              const errorText = document.getElementById('errorDataMessage');
              if (!errorText) return;
              if (!value?.start) {
                return (errorText.innerHTML = 'A data inicial é obrigatória');
              }
              if (e.target.value < value?.start) {
                return (errorText.innerHTML = 'A data inicial deve ser menor que a data final');
              }
              errorText.innerHTML = '';
              onChange({ ...value, end: e.target.value });
              setOpen(false);
            }}
          />
          <p className="text-center text-xl text-white" id="errorDataMessage"></p>
        </div>
      );
    }

    const { value, onChange } = props;
    return (
      <div
        ref={ref}
        className="absolute z-50 mt-4 w-full gap-4 rounded-md bg-blue-400 text-blue-50"
      >
        <DropdownMenuItem
          id="default"
          isSelected={value === ''}
          key="default"
          option="Todos"
          onClick={() => {
            onChange('');
            setOpen(false);
          }}
          open={open}
          setOpen={setOpen}
        />
        {options.map((option, index) => (
          <DropdownMenuItem
            id={option.value}
            disabled={(parentState && option.parent !== parentState) || false}
            isParentDisabled={
              (option.parent &&
                document.getElementById(option.parent)?.classList.contains('opacity-50')) ||
              false
            }
            isSelected={value === option.value}
            key={index}
            option={option.label}
            onClick={() => {
              onChange(option.value);
              setOpen(false);
            }}
            open={open}
            setOpen={setOpen}
          />
        ))}
      </div>
    );
  }, [options, parentState, type, open, setOpen, props]);

  return (
    <div className="relative">
      <Button
        className="min-w-80 text-xl md:min-w-fit lg:text-2xl"
        onClick={() => setOpen(!open)}
        value={label}
        inactive={disabled}
      />
      {open && renderOptions}
    </div>
  );
};
