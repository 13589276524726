import React from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { PageLayout } from '../../../components/page-layout';
import { useNavigate, useParams } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import {
  deleteUserByTeacher,
  deleteVideoLink,
  getTeacherListClass,
  getTeacherProgressUsersByClass,
  registerUser,
  resetUserPasswordByTeacher,
  saveTeacherStudentVideoLink,
  updateClassGameToNextWeek,
  updateTeacherStudentProgress,
} from '../../../service';

import Button from '../../../components/common/Button';
import PageMargin from '../../../components/page-margin/page-margin';
import { EmphasisTitle } from '../../../components/common/EmphasisTitle';
import ModalComponent, { IModalRef } from '../../../components/common/Modal';
import { InputForm } from '../../../components/Input-form';
import { useLoading } from '../../../hooks/use-loading';
import { Paragraph } from '../../../components/common/Paragraph';
import { StudentCardProgress } from './components/student-progress-card/student-progress-card';
import PaginatedStudentList from '../../../components/common/PaginatedStudentList';
import DeleteConfirm from '../../../components/common/DeleteConfirm';
import { validateVideoLink } from '../../../util/validate-video-link';

interface FormValues {
  name: string;
  bronzeTokens: number;
  silverTokens: number;
  goldTokens: number;
}

const validationSchema = Yup.object({
  name: Yup.string().required('Campo obrigatório'),
  bronzeTokens: Yup.number().min(0, 'A quantidade deve ser positiva').required('Campo obrigatório'),
  silverTokens: Yup.number().min(0, 'A quantidade deve ser positiva').required('Campo obrigatório'),
  goldTokens: Yup.number().min(0, 'A quantidade deve ser positiva').required('Campo obrigatório'),
});

export const TeacherClass = () => {
  const { id } = useParams();

  const [currentPage, setCurrentPage] = React.useState<number>(0);
  const [loading, setLoading] = React.useState<boolean>(false);
  const [registerStudentMessage, setRegisterStudentMessage] = React.useState<string>('');
  const [updateGameToNextWeekMessage, setUpdateGameToNextWeekMessage] = React.useState<string>('');
  const [editingStudent, setEditingStudent] = React.useState<any>();
  const { setIsLoading } = useLoading();

  const modalRef = React.useRef<IModalRef>(null);

  const students = useQuery({
    queryKey: ['students'],
    queryFn: () => getTeacherProgressUsersByClass(id),
    enabled: !!id,
  });

  const { data: classes } = useQuery({ queryKey: ['classes'], queryFn: getTeacherListClass });

  React.useEffect(() => {
    if (students.isLoading) {
      return setIsLoading(true);
    }
    return setIsLoading(false);
  }, [students.isLoading, setIsLoading]);

  const group = React.useMemo(() => {
    if (!classes?.data) {
      return null;
    }
    return classes?.data.find((c: any) => c.code === id);
  }, [classes?.data, id]);

  const navigate = useNavigate();

  const handleCreateStudent = async (values: FormValues) => {
    try {
      setLoading(true);
      const result = await registerUser(values.name, Number(group?.id));
      if (result.data) {
        setRegisterStudentMessage('Aluno criado com sucesso!');
        formik.resetForm();
        setIsLoading(true);
        await students.refetch();
      }
    } catch (error: any) {
      const errorMessage = error?.response?.data?.error;
      const errors: Record<string, string> = {
        'User already exists': 'Aluno já cadastrado',
        'Teacher city not found':
          'Complete o perfil do professor selecionando a cidade antes de cadastrar alunos.',
      };
      setRegisterStudentMessage(
        String((errors[errorMessage] as String) || 'Falha ao cadastrar aluno, tente novamente'),
      );
    } finally {
      setIsLoading(false);
      setLoading(false);
    }
  };

  const formik = useFormik({
    initialValues: {
      name: '',
      bronzeTokens: 0,
      silverTokens: 0,
      goldTokens: 0,
    },
    onSubmit: handleCreateStudent,
    validationSchema: validationSchema,
  });

  const handleSaveProgress = React.useCallback(async () => {
    try {
      setIsLoading(true);
      const result = await updateTeacherStudentProgress(
        editingStudent.studentId,
        editingStudent.worldId,
        editingStudent.gameId,
        editingStudent.tokens,
        editingStudent.totalGameScore,
      );
      console.log(result);
      if (result) {
        setUpdateGameToNextWeekMessage('Progresso salvo com sucesso!');
        setEditingStudent(null);
        await students.refetch();
      }
    } catch (error: any) {
      setUpdateGameToNextWeekMessage('Falha ao salvar progresso, tente novamente');
    } finally {
      setIsLoading(false);
    }
  }, [editingStudent, students, setIsLoading]);

  const handleResetUserPassword = React.useCallback(
    async (studentId: string, classCode: string) => {
      const response = await resetUserPasswordByTeacher(studentId, classCode);
      if (response?.status === 200) {
        await students.refetch();
        return true;
      }
    },
    [students],
  );

  const handleDeleteUser = React.useCallback(
    async (studentId: string) => {
      const response = await deleteUserByTeacher(studentId);
      if (response?.status === 200) {
        await students.refetch();
        return true;
      }
    },
    [students],
  );

  const handleSaveVideo = React.useCallback(
    async (
      videoLink: string,
      city: string,
      worldName: string,
      missionName: string,
      gameName: string,
      virtualPoints: number,
      userId: number,
      classCode: string,
    ) => {
      try {
        const validatedUrl = validateVideoLink(videoLink);
        if (validatedUrl !== 'youtube' && validatedUrl !== 'tiktok') {
          setUpdateGameToNextWeekMessage(validatedUrl);
          window.scrollTo(0, 0);
          return false;
        }
        setIsLoading(true);
        const result = await saveTeacherStudentVideoLink(
          videoLink,
          city,
          worldName,
          missionName,
          gameName,
          virtualPoints,
          userId,
          classCode,
        );
        if (result.data) {
          setUpdateGameToNextWeekMessage('Vídeo salvo com sucesso!');
          return true;
        }
        return false;
      } catch (error: any) {
        console.log(error);
        setUpdateGameToNextWeekMessage('Falha ao salvar vídeo, tente novamente');
        return false;
      } finally {
        await students.refetch();
        window.scrollTo(0, 0);
        setIsLoading(false);
      }
    },
    [students, setIsLoading],
  );

  const handleDeleteVideo = React.useCallback(
    async (videoId: string) => {
      try {
        setIsLoading(true);
        await deleteVideoLink(videoId, '');
        setUpdateGameToNextWeekMessage('Vídeo removido com sucesso!');
      } catch (error: any) {
        console.log(error);
        setUpdateGameToNextWeekMessage('Falha ao remover vídeo, tente novamente');
      } finally {
        students.refetch();
        setIsLoading(false);
        window.scrollTo(0, 0);
      }
    },
    [setIsLoading, students],
  );

  const renderStudents = React.useMemo((): JSX.Element[] => {
    if (!students?.data || !Object.entries(students?.data).length) {
      return [
        <div className="flex flex-col items-center justify-center">
          <h1 className="mt-16 text-center text-4xl leading-relaxed">Nenhum aluno encontrado!</h1>
        </div>,
      ];
    }
    return Object.entries(students?.data).map(([_, student]: [string, any], idx) => (
      <StudentCardProgress
        classType={group?.type || 'Online'}
        key={student.studentId || idx}
        student={student}
        students={students}
        classCode={id}
        editingStudent={editingStudent}
        handleEdit={setEditingStudent}
        handleSaveProgress={handleSaveProgress}
        handleResetPassword={handleResetUserPassword}
        handleDelete={handleDeleteUser}
        handleSaveVideo={handleSaveVideo}
        handleDeleteVideo={handleDeleteVideo}
      />
    ));
  }, [
    group,
    id,
    students,
    handleResetUserPassword,
    handleDeleteUser,
    editingStudent,
    handleSaveProgress,
    handleSaveVideo,
    handleDeleteVideo,
  ]);

  React.useEffect(() => {
    if (registerStudentMessage) {
      setTimeout(() => {
        setRegisterStudentMessage('');
      }, 5000);
    }
  }, [registerStudentMessage]);

  const handleGoToNextWeek = React.useCallback(
    async (game: string) => {
      try {
        setLoading(true);
        const result = await updateClassGameToNextWeek(Number(group?.id), game);
        console.log(result);
        setUpdateGameToNextWeekMessage(
          `${result.length} aluno(s) foram atualizados para a próxima semana do jogo!`,
        );
        return true;
      } catch (error: any) {
        setUpdateGameToNextWeekMessage(error?.response?.data?.message);
      } finally {
        setLoading(false);
      }
    },
    [group],
  );

  React.useEffect(() => {
    if (updateGameToNextWeekMessage) {
      setTimeout(() => {
        setUpdateGameToNextWeekMessage('');
      }, 4000);
    }
  }, [updateGameToNextWeekMessage]);

  return (
    <PageLayout pageTitle="Painel do Professor" marginFromTitle>
      <PageMargin>
        <EmphasisTitle className="font-semibold sm:leading-relaxed">
          {group?.type === 'Offline'
            ? 'Professora ou professor, bem vinda(o) ao Painel do Professor - Modo Offline. Aqui você atualiza o status de jogo de seus alunos filtrados por código de turma.'
            : 'Professora ou professor, bem-vinda(o) ao Painel do Professor - Modo Online. Aqui você acompanha o status de jogo de seus alunos filtrados por Código de Turma.'}
        </EmphasisTitle>
        <div className="flex h-10 flex-row items-center rounded-3xl bg-[#C4E4F5] px-12 py-10">
          <div className="w-full whitespace-normal break-words text-2xl sm:text-4xl">
            <p className="inline-block w-fit pr-2 font-extrabold">Filtro por código de turma:</p>
            <span className="inline-block cursor-pointer pr-2 text-start text-2xl font-semibold sm:text-4xl">
              {id}
            </span>
          </div>
        </div>
        <div className="flex flex-row items-center justify-between">
          {group ? (
            <h1 className="w-fit text-4xl font-bold">
              Turma: {group?.year}{' '}
              <span className="font-normal">
                ({group?.code}) - <b> Turma {group?.type}</b>
              </span>
            </h1>
          ) : (
            <div />
          )}
          <div className="relative flex flex-row gap-4">
            <img
              className="absolute -left-24 -top-3 h-20 w-20 object-contain"
              src={'/img/icons/schoolicon.png'}
              alt="logo"
            />
            <Button
              value={
                group?.type === 'Offline'
                  ? 'Cadastrar aluno para jogo offline'
                  : 'Cadastrar aluno para login simplificado'
              }
              onClick={() => {
                formik.resetForm();
                modalRef?.current?.handleModal();
              }}
            />
            <Button value="Voltar" onClick={() => navigate('/painelprofessor')} />
          </div>
        </div>
        <div className="mt-8 flex w-full flex-col items-center gap-4">
          <h1 className="my-4 w-fit text-center text-4xl font-bold">
            Ir para a próxima semana no jogo:
          </h1>
          <div className="flex w-full flex-row items-center justify-center gap-4">
            <DeleteConfirm
              onClick={async () => await handleGoToNextWeek('Reúso de Água')}
              buttonProps="w-full"
              buttonName="Reúso de Água"
              messageTitle="Ir para a próxima semana"
              messageText="Tem certeza que deseja ir para a próxima semana no jogo: Reúso de Água?"
            />
            <DeleteConfirm
              onClick={async () => await handleGoToNextWeek('A história do Saneamento')}
              buttonProps="w-full"
              buttonName="A História do Saneamento"
              messageTitle="Ir para a próxima semana"
              messageText="Tem certeza que deseja ir para a próxima semana no jogo: A História do Saneamento?"
            />
            <DeleteConfirm
              onClick={async () => await handleGoToNextWeek('Resíduos Orgânicos')}
              buttonProps="w-full"
              buttonName="Resíduos Orgânicos"
              messageTitle="Ir para a próxima semana"
              messageText="Tem certeza que deseja ir para a próxima semana no jogo: Resíduos Orgânicos?"
            />
            {/* <DeleteConfirm
              onClick={async () => await handleGoToNextWeek('Economia de Água')}
              buttonProps="w-full"
              buttonName="Economia de Água"
              messageTitle="Ir para a próxima semana"
              messageText="Tem certeza que deseja ir para a próxima semana no jogo: Economia de Água?"
            />
            <DeleteConfirm
              onClick={async () => await handleGoToNextWeek('Saneamento e Saúde')}
              buttonProps="w-full"
              buttonName="Saneamento e Saúde"
              messageTitle="Ir para a próxima semana"
              messageText="Tem certeza que deseja ir para a próxima semana no jogo: Saneamento e Saúde?"
            />
            <DeleteConfirm
              onClick={async () => await handleGoToNextWeek('Resíduos Recicláveis')}
              buttonProps="w-full"
              buttonName="Resíduos Recicláveis"
              messageTitle="Ir para a próxima semana"
              messageText="Tem certeza que deseja ir para a próxima semana no jogo: Resíduos Recicláveis?"
            /> */}
          </div>
          {updateGameToNextWeekMessage && (
            <Paragraph className="mt-8 text-left font-semibold text-[#DE3838]">
              {updateGameToNextWeekMessage}
            </Paragraph>
          )}
        </div>
        <div className="flex flex-col gap-8">
          <PaginatedStudentList
            renderItems={() => renderStudents}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
          />
        </div>
        <ModalComponent ref={modalRef} width="50%" height="fit-content">
          <form
            onSubmit={formik.handleSubmit}
            className="my-36 flex w-full flex-col items-center justify-center gap-8"
          >
            <EmphasisTitle className=" text-center text-secondary">
              {group?.type === 'Offline'
                ? 'Cadastrar aluno para jogo offline:'
                : 'Cadastrar aluno para login simplificado:'}
            </EmphasisTitle>
            <Paragraph className="text text-center font-semibold">
              Olá professora(o), cadastre seu aluno com o nome completo:
            </Paragraph>
            <div className="mx-8 flex w-8/12 flex-col items-center justify-center gap-6 px-8">
              <InputForm
                className="px-8 text-start"
                name="classCode"
                label="Código de Turma"
                placeholder="Código de Turma"
                value={group?.code}
                disabled
              />
              <InputForm
                className="px-8 text-start"
                name="name"
                label="Nome Completo"
                placeholder="Nome completo do aluno"
                formik={formik}
                value={formik.values.name}
                error={formik.errors.name}
              />
              <Button
                className="w-full"
                type="submit"
                value={!loading ? 'Cadastrar' : 'Carregando...'}
                inactive={loading}
              />
            </div>

            {registerStudentMessage && (
              <Paragraph className="mt-2 text-left font-semibold text-[#DE3838]">
                {registerStudentMessage}
              </Paragraph>
            )}
          </form>
        </ModalComponent>
      </PageMargin>
    </PageLayout>
  );
};
