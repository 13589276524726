import { Paragraph } from '../../common/Paragraph';

export const TeacherInfo = () => {
  return (
    <div className="align-center absolute hidden h-full w-3/12 max-w-[50rem] flex-col gap-14 bg-[#BCE5FF] p-16 lg:flex">
      <Paragraph className="font-semibold">
        Professora ou professor, bem-vinda(o) ao Painel do Professor da plataforma de jogos
        ambientais Missão Cidade Azul.
      </Paragraph>
      <Paragraph className="font-semibold">
        Aqui você vai encontrar o material de apoio ao jogo e também como criar códigos de turma
        para suas turmas.
      </Paragraph>
      <Paragraph className="font-semibold">
        Se você já tem conta, entre com sua conta. Do contrário, clique em Criar sua conta.
      </Paragraph>
    </div>
  );
};
