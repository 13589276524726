import { ReactNode, createContext, useContext, useState } from 'react';
import { PacmanLoader } from 'react-spinners';

interface LoadingProviderProps {
  children: ReactNode;
}

interface LoadingContextData {
  isLoading: boolean;
  setIsLoading: (isLoading: boolean) => void;
}

const LoadingContext = createContext<LoadingContextData>({} as LoadingContextData);

const LoadingComponent = () => {
  return (
    <div className="fixed inset-0 z-[900] flex items-center justify-center bg-neutral-950 opacity-75">
      <PacmanLoader className="z-[901]" color="#3B82F6" size={50} />
    </div>
  );
};

export const LoadingProvider = ({ children }: LoadingProviderProps) => {
  const [isLoading, setIsLoading] = useState(false);

  return (
    <LoadingContext.Provider value={{ isLoading, setIsLoading }}>
      {isLoading && <LoadingComponent />}
      {children}
    </LoadingContext.Provider>
  );
};

export const useLoading = () => useContext(LoadingContext);
