import React from 'react';
import { LogoImage } from '../../common/LogoImage';

type IPartnerLogosProps = {
  handleLogoClick: React.MouseEventHandler<HTMLImageElement>;
};

export const PartnerLogos: React.FC<IPartnerLogosProps> = ({ handleLogoClick }) => {
  return (
    <div
      className={`my-14 grid grid-cols-5 place-items-start gap-6 md:my-16 md:gap-24 lg:gap-32 2xl:gap-48`}
    >
      <LogoImage src="/img/LogoMinc.png" alt="" />
      <LogoImage
        src="/img/institutoaegea.png"
        alt="https://institutoaegea.com.br/"
        onClick={handleLogoClick}
      />
      <LogoImage
        src="/img/logobasf.png"
        alt="https://www.basf.com/br/pt.html"
        onClick={handleLogoClick}
      />
      <LogoImage
        src="/img/logovalgroup.png"
        alt="https://www.valgroupco.com/"
        onClick={handleLogoClick}
      />
      <LogoImage
        src="/img/ministeriocultura.png"
        alt="https://www.gov.br/cultura/pt-br"
        onClick={handleLogoClick}
      />
    </div>
  );
};
