import React, { useCallback, useState } from 'react';
import { PageLayout } from '../../components/page-layout';
import PageMargin from '../../components/page-margin/page-margin';
import Button from '../../components/common/Button';
import { getPerformance } from '../../service';
import { useLoading } from '../../hooks/use-loading';
import { FilterComponent, Filters } from '../../components/filter-component';
import * as XLSX from 'xlsx';
import { toast } from 'react-toastify';
import PaginatedList from '../../components/common/PaginatedList';
import { useNavigate } from 'react-router-dom';
import { formatDateFromString } from '../../util/formatDate';
import { EmphasisTitle } from '../../components/common/EmphasisTitle';

interface ICardProps {
  id?: string;
  color: string;
  title: string;
  value: string | number | undefined;
  onSelect?: () => void;
}

type TPerformance = {
  playerPerformance: PlayerPerformance;
  teacherPerformance: TeacherPerformance;
};

const PerformancePanel: React.FC = () => {
  const navigate = useNavigate();
  const [performance, setPerformance] = React.useState<TPerformance>();
  const [selectedItem, setSelectedItem] = useState<string>();
  const [showedList, setShowedList] = useState<string>();
  const { setIsLoading } = useLoading();
  const [filters, setFilters] = useState<Filters>({
    company: '',
    city: '',
    world: '',
    mission: '',
    rating: '',
    class: '',
    game: '',
    period: {
      start: '',
      end: '',
    },
  });

  React.useEffect(() => {
    // if ((filters.period && !filters.period.start?.length) || !filters.period?.end?.length) return;
    setIsLoading(true);
    getPerformance({
      period: {
        start: filters.period?.start,
        end: filters.period?.end,
      },
      company: filters.company,
      city: filters.city,
      world: filters.world,
      game: filters.game,
    })
      .then((response) => {
        if (!response) return;
        setPerformance({
          playerPerformance: response.playerPerformance,
          teacherPerformance: response.teacherPerformance,
        });
      })
      .catch((_err) => setPerformance(undefined))
      .finally(() => setIsLoading(false));
  }, [setIsLoading, filters]);

  const FilterCard = ({ color, title, value }: ICardProps) => {
    return (
      <div
        className={`flex h-48 w-full flex-col items-center justify-center gap-4 rounded-2xl ${color}`}
      >
        <h2 className="px-8 text-center text-3xl font-extrabold">{title}</h2>
        <p className="px-8 text-center text-4xl">{value}</p>
      </div>
    );
  };

  const ResultCard = ({ id, color, title, value, onSelect }: ICardProps) => {
    return (
      <div
        id={id}
        className={`flex h-48 w-full flex-col items-center justify-center gap-4 rounded-2xl ${color}`}
      >
        <h2 className="px-8 text-center text-3xl">{title}</h2>
        <p className="px-8 text-center text-4xl font-extrabold">{value || 0}</p>
        <div className="absolute bottom-3 right-6">
          <input
            className="h-10 w-10"
            type="checkbox"
            onChange={onSelect}
            checked={id === selectedItem}
          />
        </div>
      </div>
    );
  };

  const handleDownloadExcel = (jsonData: string[] | undefined, fileName: string) => {
    if (!jsonData || !jsonData.length) {
      toast.error('Nenhum dado encontrado');
      return;
    }
    const dataWithHeader = [...jsonData.map((item) => [item])];

    const worksheet = XLSX.utils.aoa_to_sheet(dataWithHeader);

    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');

    XLSX.writeFile(workbook, `${fileName}.xlsx`);
  };

  const handleSelectItem = (value: string) => {
    setShowedList(undefined);
    setSelectedItem((prevItem) => {
      if (prevItem === value) {
        return undefined;
      }
      return value;
    });
  };

  const getCardsList = (cardId: string | undefined) => {
    if (!cardId) return [];
    const list = document.getElementById(cardId);
    if (list) {
      const listItems: string[] = [];
      list.childNodes.forEach((item) => {
        if (item instanceof HTMLDivElement) {
          listItems.push(item.id);
        }
      });
      return listItems;
    }
    return [];
  };

  const handleDownloadSelectedList = (listName: string, type: string) => {
    const listItems = getCardsList(listName);
    if (!selectedItem) return toast.error('Selecione um item');
    if (listItems.includes(selectedItem)) {
      const jsonData =
        type === 'player'
          ? performance?.playerPerformance[selectedItem as keyof PlayerPerformance]
          : performance?.teacherPerformance[selectedItem as keyof TeacherPerformance];

      if (!jsonData) return toast.error('Nenhum dado encontrado');

      if (selectedItem !== 'totalTeachersWithClass') {
        //@ts-ignore
        return handleDownloadExcel(jsonData, selectedItem);
      }
      //@ts-ignore
      const teacherEmails = jsonData.map((item: TeacherReturn) => item.teacher_email || '');
      return handleDownloadExcel(teacherEmails, selectedItem);
    }
  };

  const handleShowList = (listName: string) => {
    const listItems = getCardsList(listName);
    if (!selectedItem) return toast.error('Selecione um item');
    if (listItems.includes(selectedItem)) {
      setShowedList((prevList) => {
        if (prevList === selectedItem) {
          return undefined;
        }
        return selectedItem;
      });
    }
  };

  const renderPlayerItems = useCallback(() => {
    const items = performance?.playerPerformance[selectedItem as keyof PlayerPerformance];
    return items
      ? items.map((item, index) => {
          const userRow = item.split(';');
          return (
            <div key={index}>
              <p className="py-4 text-3xl leading-relaxed">
                <strong>Nome do jogador:</strong>{' '}
                {userRow[0] && userRow[0] !== 'undefined' ? userRow[0] : 'N/A'}
                <br />
                <strong>Email:</strong> {userRow[1] || ''}
              </p>
            </div>
          );
        })
      : [];
  }, [performance, selectedItem]);

  const renderTeacherItems = useCallback(() => {
    const items = performance?.teacherPerformance[selectedItem as keyof TeacherPerformance];
    return items
      ? items.map((item, index) => {
          if (typeof item === 'string') {
            return (
              <div key={index}>
                <p className="py-4 text-3xl leading-relaxed">
                  <strong>Email do professor:</strong> {item || ''}
                </p>
              </div>
            );
          }
          return (
            <div key={index}>
              <p className="py-4 text-3xl leading-relaxed">
                <strong>Nome do professor:</strong> {item.name || ''}
                <br />
                <strong>Email do professor:</strong> {item.teacher_email || ''}
                <br />
                <strong>Escola:</strong> {item.school || ''}
                <strong>Código de Turma:</strong> {item.code || ''}
                <br />
                <strong>Quantidade de alunos na turma de Turma: {item.userCount}</strong>{' '}
                {/* {item.userEmails} */}
              </p>
            </div>
          );
        })
      : [];
  }, [performance, selectedItem]);

  return (
    <PageLayout pageTitle="Relatório de Performance">
      <PageMargin className="mt-16 flex-1">
        <Button
          value="Voltar"
          onClick={() => navigate('/superadmin-dashboard')}
          className="absolute -top-24 hidden w-52 lg:inline"
        />
        <div className="flex flex-col items-center justify-center lg:hidden">
          <h1 className="mt-16 text-center text-4xl leading-relaxed">
            Esta página está disponível apenas para Desktop
          </h1>
        </div>
        <div className="mt-16 hidden lg:flex lg:flex-col">
          <EmphasisTitle className="font-semibold sm:leading-relaxed">
            Olá, bem vinda(o) ao Relatório de Performance. Aqui você encontra os resultados dos
            jogos e cria relatórios com base nos dados coletados.
          </EmphasisTitle>
        </div>
        <div className="h-full w-full">
          <iframe
            width="100%"
            height="2050px"
            src="https://lookerstudio.google.com/embed/reporting/40e90821-91e7-49e4-8773-d92ae0fabffa/page/gjWAE"
          ></iframe>
        </div>
      </PageMargin>
    </PageLayout>
  );
};

export default PerformancePanel;
