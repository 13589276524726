import React from 'react';

type ILogoImageProps = {
  src: string;
  alt: string;
  onClick?: React.MouseEventHandler<HTMLImageElement>;
  height?: number;
  width?: number;
};

export const LogoImage: React.FC<ILogoImageProps> = ({ src, alt, onClick }) => {
  return <img className={`max-h-48 w-auto object-contain`} src={src} alt={alt} onClick={onClick} />;
};
