import * as React from 'react';
import jwt from 'jwt-decode';
import { useNavigate } from 'react-router-dom';
import getHref from '../../../util/getHref';
import tokenDidNotExpire from '../../../util/tokenDidNotExpire';

import { loginTeacher } from '../../../service';
import { PageLayout } from '../../page-layout';
import { usePlaying } from '../../../context/playing';
import { EmphasisTitle } from '../../common/EmphasisTitle';
import { InputForm } from '../../Input-form';
import { SocialLogin } from '../../common/social-login';
import Button from '../../common/Button';
import { TeacherInfo } from './TeacherInfo';
import { Paragraph } from '../../common/Paragraph';

enum LoginErrorType {
  USER,
  DEFAULT,
}

export const LoginTeacher: React.FC = () => {
  const [loading, setIsLoading] = React.useState<boolean>(false);
  const [email, setEmail] = React.useState<string>('');
  const [password, setPassword] = React.useState<string>('');
  const [loginError, setLoginError] = React.useState<boolean>(false);
  const [errorType, setErrorType] = React.useState<LoginErrorType>(LoginErrorType.DEFAULT);
  const navigate = useNavigate();
  const { origin } = usePlaying();

  if (localStorage.getItem('token')) {
    localStorage.clear();
  }

  const handleLoginButtonPressed = async () => {
    try {
      setIsLoading(true);
      localStorage.clear();

      const { data } = await loginTeacher(email, password);
      const token = data.token;
      const token_decoded: any = jwt(token);

      localStorage.setItem('teacher', 'true');
      localStorage.setItem('teacher-token', token);
      localStorage.setItem('email', email);
      window.dispatchEvent(new Event('login'));
      localStorage.setItem('avatarName', token_decoded.name);

      return navigate('/painelprofessor');
    } catch (error) {
      setLoginError(true);
    } finally {
      setIsLoading(false);
    }
  };

  React.useEffect(() => {
    setTimeout(() => {
      setLoginError(() => {
        setErrorType(LoginErrorType.DEFAULT);
        return false;
      });
    }, 4000);
  }, [loginError]);

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    handleLoginButtonPressed();
  };

  return (
    <>
      <TeacherInfo />
      <PageLayout className="mx-0 px-10 py-0 font-[Raleway] md:py-8">
        <div className="mx-0 flex h-full w-full items-center justify-center">
          <div className="flex w-full flex-col items-center justify-center sm:w-[45rem]">
            <img
              src={
                origin.includes('neoenergia')
                  ? '/img/rockinrio/logo-rock-neo.png'
                  : '/svg/cidadeazullogo.svg'
              }
              alt="logo"
              className="h-72 w-72 object-contain"
            />
            <EmphasisTitle className="mb-8 mt-4 text-secondary">
              Entre com sua conta de professor(a):
            </EmphasisTitle>
            <form
              onSubmit={handleSubmit}
              className="flex w-full flex-col items-center justify-center gap-6"
            >
              <InputForm
                className="px-8 text-start"
                name="email"
                label="Email"
                placeholder="Email"
                value={email}
                onChange={(e) => setEmail(e.currentTarget.value)}
              />
              <InputForm
                className="px-8 text-start"
                label="Senha"
                name="senha"
                placeholder="Senha"
                type="password"
                onChange={(e) => setPassword(e.currentTarget.value)}
              />

              {loginError && (
                <Paragraph className="text-center font-semibold text-[#DE3838]">
                  {errorType === LoginErrorType.USER && 'Olá! Este email não está cadastrado!'}
                  Este email é inválido ou a senha não confere. Tente novamente!
                </Paragraph>
              )}
              <Button
                type="submit"
                className="w-full"
                value={!loading ? 'Entrar' : 'Carregando...'}
                inactive={loading}
              />
            </form>
            <a
              href={getHref('recover-password?role=teacher')}
              className="mt-6 text-2xl font-bold text-secondary md:text-3xl"
            >
              Esqueceu sua senha? Clique Aqui.
            </a>
            <SocialLogin
              setLoginError={setLoginError}
              hasAccount={false}
              hasTiktok={false}
              authType="TEACHER"
              showTerms={false}
            />
          </div>
        </div>
      </PageLayout>
    </>
  );
};
