import React from 'react';
import Button from '../../common/Button';
import CookiePopup from '../../common/CookiePopup';
import { HomeBanner } from './HomeBanner';
import { PartnerLogos } from './PartnerLogos';
import getHref from '../../../util/getHref';
import jwt from 'jwt-decode';
import tokenDidNotExpire from '../../../util/tokenDidNotExpire';
import { useNavigate } from 'react-router-dom';
import { TikTokEmbed, YouTubeEmbed } from 'react-social-media-embed';
import { Prefs } from '../../../App';
import { useCookies } from 'react-cookie';
import { usePlaying } from '../../../context/playing';
import PageMargin from '../../page-margin/page-margin';
import ModalComponent, { IModalRef } from '../../common/Modal';
import { Paragraph } from '../../common/Paragraph';
import { EmphasisTitle } from '../../common/EmphasisTitle';

export const Home: React.FC = () => {
  const navigate = useNavigate();
  const [cookiePopupActive, setCookiePopupActive] = React.useState<boolean>(false);
  const [backFromMovieMode, setBackFromMovieMode] = React.useState<boolean>(false);
  const [cookies] = useCookies(['accept-cookies']);
  const { origin, setIsWatching } = usePlaying();
  const videoModalRef = React.useRef<IModalRef>(null);

  const goToBanners = () => {
    setIsWatching(false);
    const homeBanner = document.querySelector('#water-reuse-game-card');
    homeBanner?.scrollIntoView({ behavior: 'smooth', block: 'center' });
  };

  const handleGameClick = () => {
    if (localStorage.getItem('token') && tokenDidNotExpire()) {
      return navigate(getHref('game'));
    }
    return navigate(getHref('login'));
  };

  const handleClientClick = (client: string) => {
    window.location.href = `/${client}`;
  };

  const handleLogoClick: React.MouseEventHandler<HTMLImageElement> = (e) => {
    window.open(e.currentTarget.alt, '_blank');
  };

  React.useEffect(() => {
    if (backFromMovieMode) {
      const homeBanner = document.querySelector('#water-reuse-game-card');
      homeBanner?.scrollIntoView({ behavior: 'smooth', block: 'center' });
      setBackFromMovieMode(false);
    }
  }, [backFromMovieMode]);

  React.useEffect(() => {
    const token = localStorage.getItem('token');

    if (token) {
      const jwt_values: Prefs = jwt(token);

      if (jwt_values.type === 'TEACHER') {
        return navigate(getHref('painelprofessor'));
      } else if (jwt_values.type === 'ADMIN') {
        return navigate(getHref('superadmin-dashboard'));
      }
    }

    if ((cookies['accept-cookies'] || 'false') === 'false') {
      setTimeout(() => setCookiePopupActive(true), 1000);
    }
  }, [navigate, cookies]);

  if (origin.includes('neoenergia')) {
    return (
      <div className="relative flex flex-1">
        <div className="mt-60 flex h-full w-full flex-col items-center">
          <img
            src="/img/rockinrio/logo-rock-neo.png"
            className="w-96 object-contain"
            alt="logo-neoenergia"
          />
          <img
            src="/svg/rockinrio/neoenergia-logo.svg"
            className="mb-12 w-96 object-contain"
            alt="logo-neoenergia"
          />
          <Button
            className="mx-auto w-48 bg-green-600 text-lg md:w-72 md:text-2xl lg:w-96 lg:text-4xl"
            value="Jogar"
            onClick={() => navigate('/game/test-rockinrio')}
          />
        </div>
      </div>
    );
  }
  return (
    <div className="flex-1">
      <div>
        <div className="relative flex w-full sm:h-[35rem] md:h-[30rem] lg:h-auto xl:h-[calc(100vh-18rem)]">
          <video
            className="h-auto w-full bg-black object-cover xl:object-fill 2xl:object-cover"
            src="https://d2mkrcqum113x3.cloudfront.net/Clip_Home720p.mp4"
            autoPlay
            loop
            muted
            playsInline
            webkit-playsInline
            x5-playsInline
          />
          <div className="absolute bottom-4 flex w-full flex-col justify-between md:bottom-4 xl:bottom-10">
            <div className="flex w-full flex-row items-center justify-center gap-16 md:gap-36">
              <Button
                className="h-12 text-2xl sm:h-14 sm:text-3xl md:h-16"
                value="Assistir"
                onClick={() => videoModalRef.current?.handleModal()}
              />
              <Button
                className="h-12 text-2xl sm:h-14 sm:text-3xl md:h-16"
                value="Escolha seu jogo"
                onClick={goToBanners}
              />
            </div>
          </div>
        </div>
        <div className="mt-4 flex w-full flex-row justify-end pr-8">
          <a
            className="text-xl font-light text-black md:text-2xl"
            href={`/terms-and-conditions`}
            target="_blank"
            rel="noreferrer"
          >
            Termos e Condições
          </a>
          <p className="ml-2 mr-2 text-xl font-light text-black md:text-2xl">e</p>
          <a
            className="text-xl font-light text-black md:text-2xl"
            href={`/privacy-policy`}
            target="_blank"
            rel="noreferrer"
          >
            Política de Privacidade
          </a>
        </div>
        <PageMargin className="mx-8">
          <div className="mt-14 flex flex-col gap-14">
            <EmphasisTitle>Bem-vindas(os) à Missão Cidade Azul!</EmphasisTitle>
            <Paragraph>
              Venha para uma jornada onde cada ação transforma não apenas o mundo virtual, mas
              também o nosso planeta. Aqui, você explorará mundos, encontrará mentores e será
              convocado a realizar ações no mundo real. Mostre suas conquistas e compartilhe suas
              experiências na plataforma. Junte-se à Missão Cidade Azul e seja protagonista em um
              jogo onde suas ações podem levar a um futuro mais sustentável e inclusivo.
            </Paragraph>
          </div>

          <HomeBanner handleClientClick={handleClientClick} display />
          <div className="flex flex-col gap-8">
            <EmphasisTitle className="mb-4 text-subtitle">
              Quer se tornar um influenciador ambiental?
            </EmphasisTitle>
            <div className="flex w-11/12 flex-col gap-8">
              <div className="grid grid-cols-1 gap-4 sm:grid-cols-2">
                <img
                  className="col-span-4 w-full sm:col-span-1"
                  src="img/about-games/nft-player.jpg"
                  alt="nft"
                />
                <div className="col-span-4 grid sm:col-span-1">
                  <Paragraph className="hidden sm:block">
                    Os melhores jogadores ganharão certificados NFT que valem kits <b>Tik Tokers</b>{' '}
                    e uma mentoria para tornar-se influenciador ambiental. E poderão ser os próximos
                    influenciadores da plataforma.
                  </Paragraph>
                  <div className="mt-4 w-full sm:mt-2">
                    <img
                      className="w-full border border-gray-500 sm:w-9/12 lg:w-full"
                      src="img/about-games/kit-tiktok.jpg"
                      alt="kit"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="flex flex-col gap-4">
              <Paragraph className="block sm:hidden">
                Os melhores jogadores ganharão certificados NFT que valem kits <b>Tik Tokers</b> e
                uma mentoria para tornar-se influenciador ambiental. E poderão ser os próximos
                influenciadores da plataforma.
              </Paragraph>
              <p
                className="cursor-pointer text-xl font-light sm:-mt-4 md:text-2xl"
                onClick={() => navigate('/cultural-competition')}
              >
                Regras do Concurso Cultural Influenciador Ambiental Missão Cidade Azul
              </p>
              <a
                className="cursor-pointer text-2xl font-bold leading-relaxed text-semantic md:text-3xl md:leading-relaxed"
                href="https://d2mkrcqum113x3.cloudfront.net/Kit+Figurinhas.zip"
                target="blank"
              >
                Você pode usar as nossas figurinhas para fazer seus vídeos! Clique aqui e baixe as
                figurinhas.
              </a>
            </div>
          </div>
          <div className="flex flex-col gap-16">
            <EmphasisTitle className="text-subtitle">
              Conheça nossos parceiros influencers. Você pode se tornar um deles!
            </EmphasisTitle>
            <div className="grid grid-cols-1 gap-8 sm:gap-2 md:grid-cols-3">
              <div>
                <TikTokEmbed url="https://www.tiktok.com/@moleculando/video/7371197913071914246?_r=1&_t=8mWB8Ol02ty" />
              </div>
              <div>
                <TikTokEmbed url="https://www.tiktok.com/@simoneporfiria/video/7371146257915563269" />
              </div>
              <div>
                <TikTokEmbed url="https://www.tiktok.com/@quimicacombiel/video/7370851095561948421?_r=1&_t=8mWEY30iEw2" />
              </div>
            </div>
          </div>
          <div className="flex flex-col">
            <EmphasisTitle className="text-subtitle">Apoio, Patrocínio e Realização</EmphasisTitle>
            <PartnerLogos handleLogoClick={handleLogoClick} />
          </div>
        </PageMargin>
      </div>

      <CookiePopup active={cookiePopupActive} setActive={setCookiePopupActive} />
      <ModalComponent ref={videoModalRef}>
        <>
          <div className="flex h-full w-full flex-col items-center justify-center p-2">
            <YouTubeEmbed
              url="https://www.youtube.com/embed/9khZBBbG49M"
              width="98%"
              height="98%"
              youTubeProps={{
                opts: {
                  playerVars: {
                    autoplay: 1,
                    hl: 'pt-br',
                    cc_lang_pref: 'pt-br',
                    controls: 1,
                    cc_load_policy: 1,
                  },
                },
              }}
            />
            <div className="mt-2 flex w-full flex-row items-center justify-center gap-36 bg-transparent">
              <Button
                value="Voltar"
                onClick={() => videoModalRef.current && videoModalRef.current.handleModal()}
              />
              <Button value="Jogar" onClick={handleGameClick} />
            </div>
          </div>
        </>
      </ModalComponent>
    </div>
  );
};

export default Home;
