import React from 'react';
import { Filters } from '..';
import { ratings } from '../options';
import { formatDateFromString } from '../../../util/formatDate';

interface FilterDisplayProps {
  filters: Filters;
  removeFilter: (key: string) => void;
  fixedClass?: boolean;
}

const keyNames: Record<string, string> = {
  class: 'Turma',
  company: 'Empresa',
  city: 'Cidade',
  world: 'Mundo',
  mission: 'Missão',
  game: 'Jogo',
  rating: 'Nota',
  period: 'Período',
};

export const FilterDisplay = ({ filters, removeFilter, fixedClass }: FilterDisplayProps) => {
  const selectedFilters = React.useMemo(
    () =>
      Object.entries(filters).reduce<{ key: string; label: string; value: string }[]>(
        (acc, [key, value]) => {
          if (value) {
            acc.push({ key: key, label: keyNames[key as keyof Filters], value: value });
          }
          return acc;
        },
        [],
      ),
    [filters],
  );

  const formatLabel = (key: string, label: string, value: string) => {
    if (!label) return null;

    const isRating = key === 'rating';

    if (key === 'period') {
      const { period } = filters;
      if (!period || !period.start || !period.end) return null;

      return `${formatDateFromString(period.start)} a ${formatDateFromString(period.end)}`;
    }

    return isRating
      ? ratings.find((rating) => rating.value === value)?.label
      : filters[key as keyof typeof filters];
  };

  return (
    <div className="flex h-10 flex-row items-center rounded-3xl bg-[#C4E4F5] px-12 py-10">
      <div className="w-full whitespace-normal break-words text-2xl sm:text-3xl">
        {filters && (
          <p className="inline-block w-fit pr-2 font-extrabold">
            Filtro por {selectedFilters.map(({ label }) => label).join(', ')}:
          </p>
        )}

        {selectedFilters.map(({ key, label, value }, index) => {
          const formattedLabel = formatLabel(key, label, value);
          if (!formattedLabel) return null;

          const disabled = fixedClass && key === 'class';
          const str = `${formattedLabel}${index === selectedFilters.length - 1 ? '' : ', '}`;

          return (
            <span
              key={key}
              className={`${disabled && 'cursor-not-allowed'} inline-block cursor-pointer pr-2 text-start font-semibold`}
              onClick={() => !disabled && removeFilter(key)}
              title={`Remover filtro de ${label}`}
            >
              {str}
            </span>
          );
        })}
      </div>
    </div>
  );
};
