import AppLogo from './AppLogo';
import NavBar from './NavBar/NavBar';
import React from 'react';
import Settings from './NavBar/Settings';
import getClient from '../../util/getClient';
import getHref from '../../util/getHref';
import verifyAdmin from '../../util/verifyAdmin';
import verifyLogged from '../../util/verifyLogged';
import verifyTeacher from '../../util/verifyTeacher';
import { useLocation, useNavigate } from 'react-router-dom';
import { useMenuContext } from '../common/context/Menu.Context';
import { MenuItem } from '../common/MenuItem';
import { AboutUsMenu } from './NavBar/AboutUsMenu';
import { usePlaying } from '../../context/playing';

export const Header: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const client = getClient();
  const admin = verifyAdmin();
  const teacher = verifyTeacher();
  const logged = verifyLogged();
  const { isPlaying, origin } = usePlaying();
  const isGamingNeoEnergia = isPlaying && origin.includes('neoenergia');
  const { setMovieMode } = useMenuContext();

  const hidePlayerItems = React.useMemo(() => {
    return (
      location.pathname === getHref('superadmin-dashboard') ||
      location.pathname === getHref('performance-panel') ||
      location.pathname.includes('painelprofessor')
    );
  }, [location.pathname]);

  const handleChangeCity = () => {
    return navigate(getHref('choose-city'));
  };

  const handleProfileClick = () => {
    if (!logged) {
      return handleLogin();
    }
    return navigate('/profile');
  };

  const handleLogout = () => {
    localStorage.clear();
    return navigate(getHref(''));
  };

  const handleLogin = () => {
    if (admin) {
      return navigate('/login-admin');
    }
    if (teacher) {
      return navigate('/login-professor');
    }

    return navigate(getHref('login'));
  };

  const goToBanners = () => {
    setMovieMode(false);

    requestAnimationFrame(() => {
      const homeBanner = document.querySelector('#water-reuse-game-card');
      homeBanner?.scrollIntoView({ behavior: 'smooth', block: 'center' });
    });
  };

  const handleGalleryClick = () => {
    return navigate(getHref('video-gallery'));
  };

  const handlePublicGalleryClick = () => {
    return navigate(getHref('public-video-gallery'));
  };

  const handlePlayClick = () => {
    if (!logged) {
      if (client.length === 0) return goToBanners();
      return navigate(getHref('login'));
    }
    return navigate(getHref('game'));
  };

  const responsiveBurgerMenu = () => {
    return (
      <div className="group relative flex flex-row pr-4 xl:hidden">
        <img
          className="w-12 cursor-pointer object-contain lg:w-16"
          src="/img/icons/burger.png"
          alt="Menu"
        />
        <div className="lg:top-19 pointer-events-none absolute right-0 top-16 z-[999] w-[15rem] bg-primary pb-4 pr-6 opacity-0 transition-opacity duration-300 group-hover:pointer-events-auto group-hover:opacity-100 sm:w-[15rem] lg:w-[20rem]">
          <MenuItem text="Jogar" onClick={handlePlayClick} visible={true} />
          <MenuItem
            text="Galeria do Jogador"
            textClassName="font-semibold"
            onClick={handleGalleryClick}
            visible={true}
            active={location.pathname.includes('/video-gallery')}
          />
          <MenuItem
            text="Galeria Geral"
            textClassName="font-semibold"
            onClick={handlePublicGalleryClick}
            visible={true}
            active={location.pathname.includes('/public-video-gallery')}
          />
          <MenuItem
            text="WhatsApp"
            textClassName="font-semibold"
            onClick={() => window.open('https://wa.me/5511949582320', '_blank')}
            visible={true}
            className="flex md:hidden"
          />
        </div>
      </div>
    );
  };

  return (
    <header
      className={`align-center ${isGamingNeoEnergia ? 'hidden' : 'fixed'} z-50 box-border flex h-20 w-full flex-row justify-between bg-primary text-primaryForeground`}
    >
      <AppLogo setMovieMode={setMovieMode} hidePlayerItems={hidePlayerItems} />
      <>
        {window.location.pathname !== '/choose-avatar' && (
          <>
            <NavBar hidePlayerItems={hidePlayerItems} />
            <div className="flex flex-row items-center">
              <div className="inline sm:hidden">
                <AboutUsMenu />
              </div>
              <Settings
                handleProfileClick={handleProfileClick}
                handleChangeCity={handleChangeCity}
                handleLogout={handleLogout}
                handleLogin={handleLogin}
              />
              {responsiveBurgerMenu()}
              <img
                className={`mr-4 hidden h-10 w-10 cursor-pointer lg:h-12 lg:w-12 xl:m-8 xl:block`}
                src="/svg/icons/whatsapp.svg"
                alt=""
                onClick={() => window.open('https://wa.me/5511949582320', '_blank')}
              />
            </div>
          </>
        )}
      </>
    </header>
  );
};
