import React from 'react';
import { useNavigate } from 'react-router-dom';
import PageMargin from '../../components/page-margin/page-margin';
import { Paragraph } from '../../components/common/Paragraph';
import { EmphasisTitle } from '../../components/common/EmphasisTitle';

export const StepByStepTeacher: React.FC = () => {
  const navigate = useNavigate();
  return (
    <PageMargin>
      <div className="flex flex-1 flex-col gap-10 p-10 font-[Raleway]">
        <h1 className="text-4xl font-bold">Guia do Professor</h1>
        <Paragraph>
          O professor pode utilizar o jogo como apoio para suas aulas ou como atividades
          extracurriculares e eletivas.
        </Paragraph>
        <Paragraph>Neste guia vamos falar sobre:</Paragraph>
        <ul className="ml-8 list-disc">
          <li
            className="mb-3 cursor-pointer text-2xl leading-relaxed md:text-3xl md:leading-relaxed"
            onClick={() => navigate('/teacher-guide')}
          >
            Como utilizar o conteúdo dos jogos
          </li>
          <li className="cursor-pointer text-2xl leading-relaxed md:text-3xl md:leading-relaxed">
            Como utilizar o Painel do Professor
          </li>
        </ul>
        <EmphasisTitle className="text-2xl sm:text-2xl md:text-3xl">
          Como utilizar o Painel do Professor
        </EmphasisTitle>
        <Paragraph>
          O Painel do Professor é uma área acessada apenas pelos professores. Através do Painel do
          Professor, o professor pode realizar as seguintes atividades:
        </Paragraph>
        <ul className="ml-8 list-disc">
          <li className="mb-3 text-2xl leading-relaxed md:text-3xl md:leading-relaxed">
            Criar Códigos de Turma
          </li>
          <li className="mb-3 text-2xl leading-relaxed md:text-3xl md:leading-relaxed">
            Baixar o Material de Apoio
          </li>
          <li className="text-2xl leading-relaxed md:text-3xl md:leading-relaxed">
            Acompanhar o desempenho dos alunos
          </li>
        </ul>
        <EmphasisTitle className="text-2xl sm:text-2xl md:text-3xl">
          Como acessar o Painel do Professor{' '}
        </EmphasisTitle>
        <div className="flex flex-row gap-8">
          <img
            className="w-3/6 border object-contain sm:w-1/6"
            src="img/teacher-guide/teacher1.jpg"
            alt="step1"
          />
          <Paragraph className="w-full sm:w-2/6">
            O acesso ao professor está na página inicial da plataforma, no canto superior direito.
          </Paragraph>
        </div>
        <div className="flex flex-col gap-8 sm:flex-row">
          <img
            className="w-full border object-contain sm:w-3/6"
            src="img/teacher-guide/teacher2.jpg"
            alt="step2"
          />
          <Paragraph className="w-full sm:w-2/6">
            Para entrar, o professor precisa criar um cadastro, que pode ser por email e senha ou
            por conta do Google.
          </Paragraph>
        </div>
        <div className="flex flex-row gap-8">
          <img
            className="h-auto w-3/6 border object-contain sm:w-2/6 md:border"
            src="img/teacher-guide/teacher3.jpg"
            alt="step3"
          />
          <Paragraph className="w-3/6 sm:w-2/6">
            No caso de que o cadastro seja feito por email, o professor receberá um email de
            confirmação de cadastro.
          </Paragraph>
        </div>
        <div className="flex flex-col items-start gap-8 sm:flex-row">
          <img
            className="w-full border object-contain sm:w-3/6"
            src="img/teacher-guide/teacher4.jpg"
            alt="step4"
          />
          <div className="flex flex-col gap-8">
            <Paragraph>
              Depois de logado, o professor verá uma tela com as seguintes informações:
            </Paragraph>
            <Paragraph>
              <b>Dados Pessoais: </b>onde ele pode alterar os dados pessoais inseridos no momento de
              criação da conta.
            </Paragraph>
            <Paragraph>
              <b>Crie Códigos para suas turmas: </b>o professor pode criar códigos para cada uma de
              suas turmas, inserindo o nome ou o ano de sua turma e clicando no botão Criar. O
              professor precisa fornecer aos seus alunos o código de turma, para que eles insiram na
              tela de Criação ou Alteração de Perfil. Assim, será possível usar esse filtro tanto no
              Painel do Professor quanto na Galeria Geral.
              <b
                className="cursor-pointer text-secondary"
                onClick={() => navigate('/step-by-step')}
              >
                Veja onde o aluno deve inserir o Código de Turma em Passo a Passo.
              </b>
            </Paragraph>
            <Paragraph>
              <b>Acesse o Material de Apoio de cada jogo: </b>O Material de Apoio de cada jogo é
              disponibilizado para download.
            </Paragraph>
          </div>
        </div>
        <EmphasisTitle className="text-2xl sm:text-2xl md:text-3xl">
          Como utilizar o Código de Turma
        </EmphasisTitle>
        <div className="flex flex-col items-start gap-8 sm:flex-row">
          <img
            className="w-full border object-contain sm:w-3/6"
            src="img/teacher-guide/teacher5.jpg"
            alt="step5"
          />
          <div className="flex flex-col gap-8">
            <Paragraph>
              Com o código de turma o professor pode filtrar os alunos por turma.
            </Paragraph>
            <Paragraph>
              Quando o professor cria um Código de Turma, aparece um botão ao lado do código chamado
              ver turma. Quando o professor clica nesse botão, ele é direcionado para uma tela onde
              pode acompanhar todos os alunos daquela turma. O professor poderá também acompanhar o
              desempenho de jogo de cada aluno.
            </Paragraph>
          </div>
        </div>
        <EmphasisTitle className="text-2xl sm:text-2xl md:text-3xl">
          O Painel do Professor apresenta as seguintes informações de cada aluno:
        </EmphasisTitle>
        <ul className="ml-8 list-disc">
          <li className="mb-3 text-2xl leading-relaxed md:text-3xl md:leading-relaxed">
            Nome do aluno
          </li>
          <li className="mb-3 text-2xl leading-relaxed md:text-3xl md:leading-relaxed">Mundo</li>
          <li className="mb-3 text-2xl leading-relaxed md:text-3xl md:leading-relaxed">
            Progressão do jogo virtual
          </li>
          <li className="mb-3 text-2xl leading-relaxed md:text-3xl md:leading-relaxed">
            Pontuação Virtual
          </li>
          <li className="mb-3 text-2xl leading-relaxed md:text-3xl md:leading-relaxed">Missão</li>
          <li className="mb-3 text-2xl leading-relaxed md:text-3xl md:leading-relaxed">
            Avaliação do vídeo
          </li>
          <li className="mb-3 text-2xl leading-relaxed md:text-3xl md:leading-relaxed">
            Pontuação do vídeo
          </li>
          <li className="text-2xl leading-relaxed md:text-3xl md:leading-relaxed">
            Pontuação total
          </li>
        </ul>
        <EmphasisTitle className="text-2xl sm:text-2xl md:text-3xl">
          Dependendo do status do seu jogo, o jogador verá diferentes informações:
        </EmphasisTitle>
        <ul className="ml-8 list-disc">
          <li className="mb-6 text-2xl leading-relaxed md:text-3xl md:leading-relaxed">
            <b>Status 1: </b>o jogador ainda não jogou a primeira semana, ou seja e não acumulou
            tokens.
          </li>
          <li className="mb-6 text-2xl leading-relaxed md:text-3xl md:leading-relaxed">
            <b>Status 2: </b>o jogador terminou a primeira semana e acumulou 2 tokens de bronze.
            <br />
            Obs: se o jogador tiver jogado metade da primeira semana e acumulado um token, aparecerá
            apenas um token de bronze.
          </li>
          <li className="mb-6 text-2xl leading-relaxed md:text-3xl md:leading-relaxed">
            <b>Status 3: </b>o jogador terminou a segunda semana e acumulou 2 tokens de prata.
            <br /> Obs: se o jogador tiver jogado metade da primeira semana e acumulado um token,
            aparecerá apenas um token de prata
          </li>
          <li className="mb-6 text-2xl leading-relaxed md:text-3xl md:leading-relaxed">
            <b>Status 4: </b>o jogador terminou a terceira semana e acumulou 2 tokens de ouro.
            <br /> Neste momento, o campo para envio do vídeo da missão aparece ao jogador na
            Galeria do Jogador. O professor precisa pedir ao aluno que envie o vídeo.
            <br /> Obs: se o jogador tiver jogado metade da primeira semana e acumulado um token,
            aparecerá apenas um token de ouro, e neste caso, não é apresentado o campo de envio de
            vídeo
          </li>
          <li className="mb-6 text-2xl leading-relaxed md:text-3xl md:leading-relaxed">
            <b>Status 5: </b>após o envio do vídeo, o jogador precisa aguardar a aprovação ou
            reprovação pela plataforma. O professor precisa esperar a avaliação da plataforma.
          </li>
          <li className="mb-6 text-2xl leading-relaxed md:text-3xl md:leading-relaxed">
            <b>Status 6: </b>após o envio do vídeo, existe a possibilidade do jogador apagar o vídeo
            para poder enviar outro. Se ele fizer isso, o campo para envio de vídeo é aberto
            novamente, junto com o aviso de que o jogador apagou o vídeo. O professor precisa pedir
            ao aluno que envie um novo vídeo.
          </li>
          <li className="mb-6 text-2xl leading-relaxed md:text-3xl md:leading-relaxed">
            <b>Status 7: </b>após a aprovação do vídeo pela plataforma, o status altera para
            aprovado.
            <br /> Neste momento, a pontuação do vídeo é apresentada, assim como a pontuação total
            (soma da pontuação virtual + a pontuação do vídeo). Os bullets de conteúdo, duração e
            originalidade também aparecem com as avaliações feitas pela plataforma para o vídeo.
            Agora é preciso aguardar um novo jogo!
          </li>
          <li className="mb-6 text-2xl leading-relaxed md:text-3xl md:leading-relaxed">
            <b>Status 8: </b>o vídeo pode ser reprovado pela plataforma. Neste caso, o status altera
            para reprovado. O jogador é convidado a apagar o vídeo e enviar um novo, cumprindo os
            critérios. O professor precisa pedir ao aluno que refaça e reenvie o vídeo.
          </li>
          <li className="text-2xl leading-relaxed md:text-3xl md:leading-relaxed">
            <b>Status 9: </b>existe a possibilidade do jogador apagar o vídeo de sua rede social, e
            aí a url não existe mais. Neste caso, o status do vídeo muda, para avisar o ocorrido ao
            jogador e é pedido que ele apague o vídeo e envie um novo. O professor precisa pedir ao
            aluno que publique novamente o vídeo na rede social e depois na plataforma.
          </li>
        </ul>
        <Paragraph>
          <b>Filtros: </b>O Painel do Professor possui filtros que podem ser usados para filtrar os
          vídeos por Mundo, Jogo e Missão (vídeo).
        </Paragraph>
        <EmphasisTitle className="text-2xl sm:text-2xl md:text-3xl">
          Material de Apoio
        </EmphasisTitle>
        <Paragraph>
          O professor pode baixar o Material de Apoio de cada jogo, que é disponibilizado sempre que
          há o lançamento de um novo jogo.
        </Paragraph>
        <div className="flex flex-col gap-8 sm:flex-row">
          <img
            className="w-full border object-contain sm:w-4/6"
            src="img/teacher-guide/teacher6.jpg"
            alt="step6"
          />
          <Paragraph>
            O material de apoio tem informações, imagens e fontes que se relacionam com a temática
            do jogo.
          </Paragraph>
        </div>
        <div className="flex flex-col gap-8 sm:flex-row">
          <img
            className="w-full border object-contain sm:w-3/6"
            src="img/teacher-guide/teacher7.jpg"
            alt="step7"
          />
          <div>
            <Paragraph className="mb-4">
              O Material de Apoio também faz a indicação de quais componentes curriculares e
              habilidades da BNCC se relacionam com o conteúdo do jogo.
            </Paragraph>
          </div>
        </div>
        <EmphasisTitle className="text-2xl sm:text-2xl md:text-3xl">Alterar Senha</EmphasisTitle>
        <div className="flex flex-col gap-8 sm:flex-row">
          <img
            className="w-full border object-contain sm:w-3/6"
            src="img/teacher-guide/teacher8.jpg"
            alt="step8"
          />
          <Paragraph>
            Caso o professor esquecer a senha de acesso ou sentir a necessidade de alterá-la, é só
            clicar em Esqueceu sua senha.
          </Paragraph>
        </div>
        <div className="flex flex-row gap-8">
          <img
            className="w-2/6 border object-contain"
            src="img/teacher-guide/teacher9.jpg"
            alt="step9"
          />
          <Paragraph>Neste caso, o professor receberá um email de alteração de senha.</Paragraph>
        </div>
      </div>
    </PageMargin>
  );
};
