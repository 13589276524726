import React, { useState } from 'react';
import Button from './Button';
import { EmphasisTitle } from './EmphasisTitle';
import { Paragraph } from './Paragraph';

const DeleteConfirm = ({
  onClick,
  messageTitle,
  messageText,
  buttonName,
  buttonProps,
  icon,
  disabled = false,
}: any) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [message, setMessage] = useState('');

  const toggleModal = () => {
    setIsDeleting(false);
    setMessage('');
    setIsModalOpen(!isModalOpen);
  };

  const closeModalOnClickOutside = (e: any) => {
    if (e.target.id === 'deleteModal') {
      toggleModal();
    }
  };

  const onClickButton = async () => {
    setIsDeleting(true);
    const response = await onClick();
    if (response) {
      setMessage('Operação executada com sucesso!');
    } else {
      setMessage('Erro ao executar a operação');
    }
    setIsDeleting(false);
    setTimeout(() => {
      setMessage('');
      toggleModal();
    }, 2000);
  };

  return (
    <>
      <div className="w-full">
        <Button
          onClick={toggleModal}
          className={buttonProps}
          value={buttonName}
          inactive={disabled}
        />
      </div>

      {isModalOpen && (
        <div
          id="deleteModal"
          onClick={closeModalOnClickOutside}
          className="fixed left-0 right-0 top-0 z-50 flex h-full items-center justify-center overflow-y-auto overflow-x-hidden bg-black bg-opacity-50"
        >
          <div className="relative w-full max-w-5xl bg-white p-8">
            <div className="relative rounded-lg p-8 text-center">
              <button
                type="button"
                className="absolute right-3 top-3 inline-flex items-center rounded-lg p-2 text-lg text-gray-400 hover:bg-gray-200 hover:text-gray-900 dark:hover:bg-gray-600 dark:hover:text-white"
                onClick={toggleModal}
              >
                <svg
                  aria-hidden="true"
                  className="h-6 w-6"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                    clipRule="evenodd"
                  ></path>
                </svg>
                <span className="sr-only">Close modal</span>
              </button>
              {icon ? icon : null}
              <EmphasisTitle className="text-secondary">{messageTitle}</EmphasisTitle>
              <Paragraph className="my-8 font-semibold">{messageText}</Paragraph>
              <div className="flex items-center justify-center space-x-6">
                <Button
                  value="Não, fechar"
                  className="bg-zinc-400"
                  onClick={toggleModal}
                  inactive={isDeleting}
                />
                <Button
                  value="Sim, prosseguir"
                  className="bg-[#009C9D]"
                  onClick={onClickButton}
                  inactive={isDeleting}
                />
              </div>
              {isDeleting && (
                <div className="mt-4">
                  <Paragraph className="text-center font-semibold text-[#DE3838]">
                    Aguarde...
                  </Paragraph>
                </div>
              )}
              {message && (
                <div className="mt-6">
                  <Paragraph className="text-center font-semibold text-[#DE3838]">
                    {message}
                  </Paragraph>
                </div>
              )}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default DeleteConfirm;
