import * as React from 'react';
import Button from '../../common/Button';
import PageTitle from '../../common/PageTitle';
import TextInput from '../../common/TextInput';
import { useNavigate } from 'react-router-dom';
import { getTeacherProfile } from '../../../service';
import { usePlaying } from '../../../context/playing';

const TeacherUpdate = () => {
  const [name, setName] = React.useState<string>('');
  // const [email, setEmail] = React.useState<string>("");
  const [city, setCity] = React.useState<string>('');
  const [school, setSchool] = React.useState<string>('');
  const [password, setPassword] = React.useState<string | null>(null);
  const [passwordRepeat, setPasswordRepeat] = React.useState<string>('');
  const [passwordError, setPasswordError] = React.useState<boolean>(false);
  const [updateError, setUpdateError] = React.useState<boolean>(false);
  const navigate = useNavigate();
  const { origin } = usePlaying();

  React.useEffect(() => {
    setUpdateError(false);
    if (!passwordRepeat) return setPasswordError(false);
    if (password !== passwordRepeat) return setPasswordError(true);
    setPasswordError(false);
  }, [password, passwordRepeat]);

  React.useEffect(() => {
    loadTeacherProfile();
  }, []);

  const handleUpdateAccount: React.MouseEventHandler<HTMLInputElement> = async (
    _event: React.MouseEvent<HTMLInputElement>,
  ) => {
    try {
      // const jwt_token = await updateTeacher(name, city, school, password);

      setUpdateError(false);
      navigate('/painelprofessor');
    } catch (error) {
      console.error({ error });
      setUpdateError(true);
    }
  };

  const loadTeacherProfile = () => {
    getTeacherProfile().then((profile: any) => {
      setName(profile.data.name);
      // setEmail(profile.data.email);
      setSchool(profile.data.school);
      setCity(profile.data.city);
    });
  };

  return (
    <div className="Content-central-container">
      <div
        className="Content-central extra-margin-top portrait"
        style={{ fontSize: '2rem', fontWeight: '500' }}
      >
        <img
          src={
            origin.includes('neoenergia')
              ? '/img/rockinrio/logo-rock-neo.png'
              : '/svg/cidadeazullogo.svg'
          }
          id="login-logo"
          alt=""
          style={{ objectFit: 'contain' }}
        />
        <div
          className="portrait-login-register-title"
          style={{ marginBottom: '2rem', fontSize: '2.5rem', fontWeight: '800', color: '#398bdf' }}
        >
          Professor(a), altere sua conta:
        </div>
        <TextInput
          placeholder="Nome"
          onChange={(e) => setName(e.currentTarget.value)}
          value={name}
        />
        {/* <TextInput placeholder="Email" onChange={(e) => setEmail(e.currentTarget.value)} value={email} /> */}
        <TextInput
          placeholder="Cidade"
          onChange={(e) => setCity(e.currentTarget.value)}
          value={city}
        />
        <TextInput
          placeholder="Escola"
          onChange={(e) => setSchool(e.currentTarget.value)}
          value={school}
        />
        <TextInput
          id="updatePassword"
          type="password"
          placeholder="Senha"
          onChange={(e) => setPassword(e.currentTarget.value)}
        ></TextInput>
        <TextInput
          id="confirmUpdatePassword"
          type="password"
          placeholder="Confirmar senha"
          onChange={(e) => setPasswordRepeat(e.currentTarget.value)}
        ></TextInput>
        <div className={`error-note ${passwordError ? 'active' : ''}`}>Senhas não coincidem</div>
        <div className={`error-note ${updateError ? 'active' : ''}`}>
          Erro no cadastro, tente novamente
        </div>
        <Button
          value="Atualizar"
          onClick={handleUpdateAccount}
          style={{ margin: '2rem', width: '80%', height: '5rem', borderRadius: '2.5rem' }}
        ></Button>
      </div>
      <PageTitle value="Painel do Professor" />
    </div>
  );
};

export default TeacherUpdate;
