import React from 'react';
import { MenuItem } from '../../common/MenuItem';
import { useNavigate } from 'react-router-dom';

export const AboutUsMenu: React.FC = () => {
  const navigate = useNavigate();

  return (
    <div className="group relative flex cursor-pointer flex-row items-center justify-center gap-6 text-4xl">
      <div className="flex flex-row pr-2 font-medium text-primaryForeground xl:pr-20">
        <p className="hidden text-xl font-semibold sm:inline lg:text-3xl">Sobre Nós</p>
        <object
          className="mr-4 h-10 w-10 sm:hidden sm:h-12 sm:w-12"
          data="svg/icons/question.svg"
          type="image/svg+xml"
          aria-label="about-us"
        />
      </div>
      <div className="pointer-events-none absolute right-0 top-[100%] w-[17rem] opacity-0  transition-opacity duration-300 group-hover:pointer-events-auto group-hover:opacity-100 sm:w-[17rem] lg:w-[22rem]">
        <div className="flex h-auto flex-col overflow-y-auto bg-primary pb-4 pt-8">
          <MenuItem
            text="Quem Somos"
            onClick={() => navigate('about-us')}
            icon={
              <object
                className="mr-4 h-10 w-10"
                data="svg/icons/about-us.svg"
                type="image/svg+xml"
                aria-label="about-us"
              />
            }
            visible
          />
          <MenuItem
            text="Sobre os Jogos"
            onClick={() => navigate('about-games')}
            icon={
              <object
                className="mr-4 h-10 w-10"
                data="svg/icons/joystick.svg"
                type="image/svg+xml"
                aria-label="game"
              />
            }
            visible
          />
          <MenuItem
            text="Pontuação"
            onClick={() => navigate('punctuation')}
            icon={
              <object
                className="mr-4 h-10 w-10"
                data="svg/icons/points.svg"
                type="image/svg+xml"
                aria-label="points"
              />
            }
            visible
          />
          <MenuItem
            text="Passo a Passo"
            onClick={() => navigate('step-by-step')}
            icon={
              <object
                className="mr-4 h-10 w-10"
                data="svg/icons/step-by-step.svg"
                type="image/svg+xml"
                aria-label="step-by-step"
              />
            }
            visible
          />
          <MenuItem
            text="Guia do Professor"
            onClick={() => navigate('teacher-guide')}
            icon={
              <object
                className="mr-4 h-10 w-10"
                data="svg/icons/guide.svg"
                type="image/svg+xml"
                aria-label="guide"
              />
            }
            visible
          />
        </div>
      </div>
    </div>
  );
};
