import React, { useState } from 'react';
import { PageLayout } from '../../components/page-layout';
import { Filters, FilterComponent } from '../../components/filter-component';
import Masonry from 'react-masonry-css';

import { getUnpublishedVideos } from '../../service';
import { useQuery } from '@tanstack/react-query';
import { UnpublishedVideo } from './components/unpublished-card';
import { applyFilters } from '../../components/filter-component/apply-filters';
import PageMargin from '../../components/page-margin/page-margin';
import { useNavigate } from 'react-router-dom';
import Button from '../../components/common/Button';
import { EmphasisTitle } from '../../components/common/EmphasisTitle';
import useBreakpoint from '../../hooks/use-breakpoint';
import PaginatedVideoList from '../../components/common/PaginatedVideoList';

export const AdminPanel = () => {
  const navigate = useNavigate();
  const [filters, setFilters] = useState<Filters>({});
  const [currentPage, setCurrentPage] = React.useState<number>(0);
  const [itemsPerPage, setItemsPerPage] = React.useState<number>(0);
  const breakpoint = useBreakpoint();

  const unpublishedVideos = useQuery({
    queryKey: ['unpublished-videos'],
    queryFn: getUnpublishedVideos,
    refetchOnWindowFocus: false,
  });

  React.useEffect(() => {
    if (breakpoint === '2xl' || breakpoint === 'xl' || breakpoint === 'lg') {
      return setItemsPerPage(() => {
        setCurrentPage(0);
        return 9;
      });
    }
    if (breakpoint === 'md') {
      return setItemsPerPage(() => {
        setCurrentPage(0);
        return 6;
      });
    }
    return setItemsPerPage(() => {
      setCurrentPage(0);
      return 3;
    });
  }, [breakpoint]);

  React.useEffect(() => {
    setCurrentPage(0);
  }, [filters]);

  const refetch = React.useCallback(() => {
    unpublishedVideos.refetch();
  }, [unpublishedVideos]);

  const renderVideos = React.useMemo((): JSX.Element[] => {
    if (!unpublishedVideos.data) return [];
    return unpublishedVideos.data
      .filter((video) => applyFilters(video, filters))
      .map((video) => (
        <UnpublishedVideo
          key={video.id}
          published={video.status === 'P'}
          video={video}
          refetch={refetch}
        />
      ));
  }, [unpublishedVideos, filters, refetch]);

  return (
    <PageLayout pageTitle="Painel Adm" marginFromTitle>
      <PageMargin className="min-h-[calc(100vh-21rem)]">
        <div className="flex flex-col items-center justify-center lg:hidden">
          <h1 className="mt-16 text-center text-4xl leading-relaxed">
            Esta página está disponível apenas para Desktop
          </h1>
        </div>
        <div className="hidden h-full flex-col gap-20 lg:flex lg:flex-1">
          <EmphasisTitle className="font-semibold sm:leading-relaxed">
            Bem-vinda(o) ao Painel Adm. Aqui você classifica os vídeos de todas as jogadoras ou
            jogadores. Utilize os filtros abaixo para filtrar os vídeos por cidade, por mundo e por
            missão.
          </EmphasisTitle>
          <FilterComponent
            filters={filters}
            setFilters={setFilters}
            enableCompanyFilter
            enableCityFilter
          />
          {!renderVideos.length ? (
            <div className="flex h-full w-full items-center justify-center">
              <h1 className="text-4xl font-semibold">Não há videos publicados neste momento!</h1>
            </div>
          ) : (
            <PaginatedVideoList
              renderItems={() => renderVideos}
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
              itemsPerPage={itemsPerPage}
            />
          )}
          <div className="flex w-full justify-end">
            <Button
              value="Relatório de performance"
              onClick={() => navigate('/performance-panel')}
            />
          </div>
        </div>
      </PageMargin>
    </PageLayout>
  );
};
