import { cn } from '../../util/cn';

interface InputProps extends React.InputHTMLAttributes<HTMLInputElement> {}

export const Input = ({ className, ...rest }: InputProps) => {
  return (
    <input
      {...rest}
      className={cn(
        'border-input focus-visible:ring-ring flex h-20 w-full rounded-xl border border-gray-400 bg-transparent px-3 py-1 text-center text-3xl leading-relaxed shadow-sm transition-colors file:border-0 file:bg-transparent file:text-sm file:font-medium placeholder:text-gray-500 focus-visible:outline-none focus-visible:ring-1 disabled:cursor-not-allowed disabled:opacity-50',
        className,
      )}
    />
  );
};
