import * as React from 'react';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import getHref from '../../util/getHref';
import { useNavigate } from 'react-router-dom';
import { updateTeacherPassword, updateUserPassword } from '../../service';
import { PageLayout } from '../page-layout';
import { usePlaying } from '../../context/playing';
import { EmphasisTitle } from '../common/EmphasisTitle';
import { InputForm } from '../Input-form';
import Button from '../common/Button';
import { Paragraph } from '../common/Paragraph';

const validationSchema = Yup.object({
  password: Yup.string()
    .required('Campo obrigatório')
    .min(8, 'A senha deve ter no mínimo 8 caracteres'),
  confirmPassword: Yup.string()
    .required('Campo obrigatório')
    .oneOf([Yup.ref('password')], 'As senhas não coincidem'),
});

interface FormValues {
  password: string;
  confirmPassword: string;
}

const UpdatePassword = () => {
  const [errorMessage, setErrorMessage] = React.useState<string>('');
  const [loading, setIsLoading] = React.useState<boolean>(false);
  const navigate = useNavigate();
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const { origin } = usePlaying();
  const [messageSuccess, setMessageSuccess] = React.useState<string>('');

  const handleRecoverPasswordPressed = async (values: FormValues) => {
    try {
      setIsLoading(true);
      if (urlParams.get('role') === 'teacher') {
        await updateTeacherPassword(
          urlParams.get('email') ?? '',
          urlParams.get('code') ?? '',
          values.password,
        );
        setMessageSuccess('Senha atualizada com sucesso! Redirecionando para a página de login...');
        setTimeout(() => {
          navigate(getHref('login-professor'));
        }, 3000);
      } else {
        await updateUserPassword(
          urlParams.get('email') ?? '',
          urlParams.get('code') ?? '',
          values.password,
        );
        setMessageSuccess('Senha atualizada com sucesso! Redirecionando para a página de login...');
        setTimeout(() => {
          navigate(getHref('login'));
        }, 3000);
      }
    } catch (error: any) {
      setErrorMessage('Olá, ' + error?.response?.data?.message);
    } finally {
      setIsLoading(false);
    }
  };

  const formik = useFormik({
    initialValues: {
      password: '',
      confirmPassword: '',
    },
    onSubmit: handleRecoverPasswordPressed,
    validationSchema,
  });

  React.useEffect(() => {
    setTimeout(() => {
      setErrorMessage('');
    }, 4000);
  }, [errorMessage]);

  return (
    <PageLayout className="mx-0 px-10 py-0 font-[Raleway] md:py-8">
      <div className="mx-0 flex h-full w-full items-center justify-center">
        <div className="flex w-full flex-col items-center justify-center sm:w-[45rem]">
          <img
            src={
              origin.includes('neoenergia')
                ? '/img/rockinrio/logo-rock-neo.png'
                : '/svg/cidadeazullogo.svg'
            }
            alt="logo"
            className="h-72 w-72 object-contain"
          />
          <EmphasisTitle className="mt-6 text-secondary">Redefinição de senha:</EmphasisTitle>
          <form
            onSubmit={formik.handleSubmit}
            className="mt-6 flex w-full flex-col items-center justify-center"
          >
            <div className="flex w-full flex-col items-center justify-center gap-6">
              <InputForm
                className="px-8 text-start"
                type="password"
                label="password"
                name="password"
                placeholder="Nova senha"
                formik={formik}
                value={formik.values.password}
                error={formik.errors.password}
              />
              <InputForm
                className="px-8 text-start"
                type="password"
                label="confirmPassword"
                name="confirmPassword"
                placeholder="Confirme a senha"
                formik={formik}
                value={formik.values.confirmPassword}
                error={formik.errors.confirmPassword}
              />
              {errorMessage && (
                <Paragraph className="text-center font-semibold text-[#DE3838]">
                  {errorMessage}
                </Paragraph>
              )}
              <Button
                type="submit"
                className="w-full"
                inactive={loading}
                value={!loading ? 'Redefinir senha' : 'Carregando...'}
              />
              {messageSuccess && (
                <Paragraph className="text-center font-semibold text-[#DE3838]">
                  {messageSuccess}
                </Paragraph>
              )}
            </div>
          </form>
        </div>
      </div>
    </PageLayout>
  );
};

export default UpdatePassword;
