import React from 'react';
import PageMargin from '../page-margin/page-margin';

export const TermsAndConditions: React.FC = () => {
  return (
    <PageMargin className="flex-1">
      <div className="my-20 flex flex-col gap-12 text-3xl font-medium leading-loose">
        <h1 className="text-4xl font-bold">
          Termos de Uso - The Blue City - Atualizada em 20 de maio de 2023
        </h1>
        <p>
          Os Termos abaixo descrevem os direitos e responsabilidades aplicáveis ao compartilhamento
          de dados de qualquer pessoa física ou jurídica (“Usuário”) que deseja utilizar um dos
          nossos Serviços.
        </p>

        <p>
          Ao utilizar os Serviços da <b>The Blue City</b>, o Usuário está ciente e concorda com
          estes Termos.
        </p>

        <h1 className="text-4xl font-bold underline">Atenção:</h1>
        <h1 className="text-4xl font-bold">
          CASO NÃO CONCORDE COM OS TERMOS AQUI DISPOSTOS, O USUÁRIO NÃO DEVERÁ UTILIZAR OS SERVIÇOS
          DA THE BLUE CITY.
        </h1>
        <h1 className="text-4xl font-bold">
          1. Uso dos Serviços, Abertura de Contas e Contas de Crianças
        </h1>

        <p>
          1.1. Se você quiser usar certos serviços, será necessário criar uma Conta de Usuário
          conosco. Além disso, você precisará ter um dispositivo móvel com conexão à internet. Os
          recursos de ajuda nos jogos e aplicativos da <b>The Blue City</b> fornecem uma lista dos
          dispositivos suportados. No entanto, não oferecemos suporte a dispositivos que foram
          modificados por meio de jailbreak ou root, ou seja, alterações não autorizadas nas
          configurações de acesso.
        </p>
        <p>
          1.2. Para criar uma Conta, você pode utilizar uma conta existente do Google, Facebook ou
          qualquer outra conta de terceiros para a qual nós ofereçamos suporte, conforme selecionado
          por você no aplicativo durante o processo de criação.
        </p>
        <p>
          1.3. É importante que você não compartilhe sua senha da Conta com terceiros e nos informe
          imediatamente caso haja uso ou acesso não autorizado de sua conta. A segurança das contas
          é uma questão séria para a <b>The Blue City</b>, porém, você é responsável por todas as
          atividades que ocorrerem em sua conta, mesmo que você não tenha conhecimento delas.
        </p>
        <p>
          1.4. <b>Crianças não podem usar os nossos Serviços</b>. Segundo a Lei n°. 8.069, de 1.990,
          que instituiu o{' '}
          <a
            href="https://www.planalto.gov.br/ccivil_03/leis/l8069.htm"
            target="_blank"
            rel="noreferrer"
            className="text-blue-500 underline"
          >
            Estatuto da Criança e do Adolescente (ECA)
          </a>
          , é considerado criança quem tem até 12 anos incompletos, enquanto quem tem de 12 a 18
          anos é considerado adolescente. Nos nossos jogos e aplicativos, você pode encontrar
          informações sobre a idade exigida para cada um deles.
        </p>
        <p>
          1.5. Todas as compras feitas por meio dos Serviços são permitidas apenas para titulares de
          contas que tenham a idade adequada para consentir com um contrato em seu país de
          residência. Caso sejam mais jovens, os titulares de contas devem obter o consentimento de
          um pai ou responsável para usar os serviços.
        </p>
        <h1 className="text-4xl font-bold underline">Atenção:</h1>
        <p>
          A <b>The Blue City</b> não permite o uso de nossos Serviços por crianças e não assume
          nenhuma responsabilidade por qualquer atividade realizada por uma criança, seja com ou sem
          permissão de um pai ou responsável, dentro dos limites permitidos pela legislação
          aplicável. Se você é pai ou responsável e autorizou que a criança sob sua responsabilidade
          crie uma conta em um dos serviços, você concorda, por meio deste, com os termos
          relacionados ao uso dos serviços pela criança sob sua responsabilidade.
        </p>
        <h1 className="text-4xl font-bold underline">Atenção:</h1>
        <p>
          Se o Usuário de uma Conta violar estes Termos e Políticas, poderemos tomar medidas
          cautelares em relação a ele, como a suspensão ou encerramento da Conta.
        </p>

        <h1 className="text-4xl font-bold">2. Licença de Uso e Outras Informações Essenciais</h1>

        <p>
          2.1 O site, seu conteúdo e todas as informações e materiais relacionados aos Jogos,
          Aplicativos e aos Serviços prestados são de propriedade exclusiva da <b>The Blue City</b>.
          Sujeito ao cumprimento destes Termos, a The Blue City concede ao Usuário uma licença
          limitada, não exclusiva, não transferível, não sublicenciável, para descarregar (download)
          e instalar a cópia dos Jogos, Aplicativos ou Serviços em um dispositivo móvel e para
          executar essa cópia exclusivamente para seu fim pessoal e não comercial. Não é permitido
          copiar, modificar, distribuir, exibir, transmitir, reproduzir, publicar, licenciar, criar
          trabalhos derivados, transferir ou vender qualquer informação, software, produtos ou
          Serviços obtidos sem autorização prévia por escrito da <b>The Blue City</b>.
        </p>
        <p>
          2.2. A <b>The Blue City não é responsável pelo conteúdo gerado pelo Usuário</b> ou por
          terceiros e nossos sites e serviços poderão conter links para sites de terceiros, de modo
          que a <b>The Blue City</b> não tem controle sobre esses sites e não pode garantir sua
          disponibilidade, precisão ou segurança. O Usuário está ciente e concorda que a existência
          desses links não constitui endosso ou patrocínio de sites de terceiros e reconhece estar
          sujeito aos termos de uso e políticas de privacidade estabelecidas por eles nos seus
          respectivos sites, aplicativos ou plataformas. Além disso, a <b>The Blue City</b> se
          reserva o direito de remover qualquer conteúdo gerado pelo Usuário que viole estes Termos
          de Uso.
        </p>

        <p>
          2.3. A<b>The Blue City</b>não se responsabiliza por quaisquer danos diretos, indiretos,
          incidentais, especiais, consequenciais ou punitivos resultantes do uso ou incapacidade de
          usar nossos Serviços.
        </p>

        <p>
          2.4. A<b>The Blue City</b>também não se responsabiliza por quaisquer anúncios publicados
          pelos Usuários ou terceiros no Aplicativo e não assume qualquer responsabilidade pelos
          negócios realizados pelo Usuário com as marcas que se comunicam com ele por meio do
          Aplicativo, já que os Serviços prestados pela empresa têm como finalidade única e
          exclusivamente a divulgação, sem qualquer relação com os negócios praticados pelo Usuário
          ou pelas marcas e empresas anunciantes.
        </p>

        <p>
          2.5. O Usuário concorda que a <b>The Blue City</b> não pode ser responsabilizada por
          falhas, erros, interrupções, mau funcionamento, atrasos ou lentidão que possam surgir nos
          Serviços prestados, já que são Serviços de tecnologia e dependem de atividades praticadas
          por terceiros, da mesma maneira ela também não pode ser responsabilizada em caso de
          inoperância, falhas ou lentidão decorrente do uso de seus sistemas em razão de manutenções
          programadas ou decorrente de situações emergenciais.
        </p>

        <p>
          2.6. O Usuário concorda e entende que a <b>The Blue City</b> não pode ser responsabilizada
          por quaisquer reclamações decorrentes dos produtos e/ou Serviços eventualmente
          comercializados pelas marcas e empresas contratantes e sabe que deve contatar diretamente
          a respectiva Marca ou Empresa Anunciante para resolver qualquer litígio.
        </p>

        <p>
          2.7. A <b>The Blue City</b> poderá modificar a qualquer momento, sem aviso prévio, os
          presentes Termos, o que será feito por meio da publicação de uma versão atualizada destes,
          explicitando a data da última modificação.
        </p>

        <p>
          2.8. A versão atualizada dos Termos passará a vigorar de acordo com os prazos previstos na
          legislação em vigor. Caso o Usuário não aceite as modificações inseridas, ele deverá se
          abster de utilizar os Serviços da <b>The Blue City</b> e considerar rescindido o vínculo
          contratual, cancelando o seu cadastro.
        </p>

        <p>
          2.9. Após este prazo, caso o Usuário continue utilizando os Serviços prestados pela{' '}
          <b>The Blue City</b>, será considerado que o Usuário concordou com os novos Termos e o
          presente documento continuará vinculando ambas as Partes. Os Termos modificados serão
          aplicáveis a todo consentimento que se efetue posteriormente à comunicação ao Usuário.
        </p>

        <h1 className="text-4xl font-bold">2. Licença de Uso e Outras Informações Essenciais</h1>

        <p>
          3.1. A <b>The Blue City</b> somente realizará o tratamento de dados do Usuário de acordo
          com os Termos de Uso e Política de Privacidade aqui mencionados e obterá seu consentimento
          conforme seja necessário para a prestação dos Serviços oferecida, mas o Usuário entende
          que esses terceiros são responsáveis por obter o seu consentimento relacionado ao
          tratamento de dados realizados por eles e que a <b>The Blue City</b>, como entidade
          independente, não possui qualquer tipo de controle ou direcionamento e não pode ser
          responsabilizada quanto ao tratamento realizado por eles no âmbito de suas operações ou a
          segurança desses dados no ambiente dessas instituições, especialmente considerando o fato
          de que eles estarão sujeitos às políticas de privacidade, segurança e outras políticas
          relevantes desses terceiros.
        </p>

        <p>
          3.2. A <b>The Blue City</b> realiza o tratamento de dados do Usuário de acordo com as
          cláusulas e condições dispostas na sua Política de Privacidade e outras políticas
          relevantes, com as quais o Usuário consente e que passam a fazer parte integrante dos
          presentes Termos. Tais políticas poderão ser alteradas a qualquer tempo, a fim de
          adequação às regras de negócio ou às novas leis ou normas supervenientes.
        </p>

        <p>
          3.3. O Usuário reconhece que a <b>The Blue City</b> é uma instituição independente que não
          possui influência na coleta do consentimento e revogação do consentimento previamente
          obtido pelo(s) terceiro(s) em relação ao compartilhamento de dados indiretos que tenham
          sido obtidos por terceiros e enviados para a <b>The Blue City</b> para a efetiva prestação
          dos seus Serviços, de modo que isenta a <b>The Blue City</b> de toda e qualquer
          responsabilidade relacionada a isso, com destaque especial para a obtenção e revogação do
          consentimento perante essas instituições.
        </p>

        <p>
          3.4. O Usuário reconhece que, nos casos em que ele solicita, autoriza e consente com o
          compartilhamento e tratamento de seus dados com um ou mais terceiros, toda e qualquer
          interpretação e decisão tomada com base nesses dados é feita de forma única e exclusiva
          pelo terceiro, ficando a <b>The Blue City</b> isenta de qualquer responsabilidade por
          essas interpretações e decisões.
        </p>

        <p>
          3.5. O Usuário reconhece e expressamente concorda que a <b>The Blue City</b> não responde
          por quaisquer problemas técnicos ou operacionais que impliquem a impossibilidade de acesso
          aos seus dados por e para terceiros.
        </p>

        <p>
          3.6. O Usuário reconhece e expressamente concorda com o fato de que quando usa o
          Aplicativo, pode compartilhar informações sobre a sua utilização, como seu nome, login,
          perfil, fotos, lista de amigos, mensagens, o que faz no jogo e outras informações
          relacionadas a sua conta.
        </p>

        <p>
          3.7. É importante compartilhar essas informações apenas com amigos que você sabe que
          querem recebê-las e que têm idade apropriada para ver o que você compartilha e ter a
          ciência de que se você decidir compartilhar suas informações com Serviços de outras
          empresas e/ou Terceiros, lembre-se de que estará sujeito às regras e à política de
          privacidade desses terceiros.
        </p>
        <h1 className="text-4xl font-bold">4. Das Proibições e Obrigações dos Usuários</h1>
        <h1 className="text-4xl font-bold">4.1. É proibido ao Usuário, entre outros atos:</h1>
        <p>
          a) Violar qualquer legislação ou regulamentação, local ou internacional, que seja
          integrada ao ordenamento jurídico brasileiro, ou ainda, que, por qualquer razão legal,
          deva ser aplicado no Brasil;
        </p>
        <p>b) Praticar atos contrários à moral e aos bons costumes;</p>
        <p>c) Assumir a personalidade ou identidade de outra pessoa, física ou jurídica;</p>
        <p>
          d) Carregar, transmitir, divulgar, exibir, enviar, ou de qualquer outra forma tornar
          disponível qualquer conteúdo que seja ilegal, incluindo, mas sem se limitar a, conteúdo
          que seja ofensivo à honra e à privacidade de terceiros, pornográfico, obsceno, difamatório
          ou calunioso, vulgar, preconceituoso, racista, discriminatório, que faça apologia ao crime
          ou de qualquer forma censurável, ou que possa gerar qualquer responsabilidade civil ou
          criminal de acordo com a Lei;
        </p>
        <p>
          e) Carregar, transmitir, divulgar, exibir, enviar, ou de qualquer forma tornar disponível
          qualquer conteúdo que viole quaisquer direitos de terceiro, incluindo direitos de
          Propriedade Intelectual;
        </p>
        <p>
          f) Carregar, transmitir, divulgar, exibir, enviar, ou de qualquer forma tornar disponível
          qualquer tipo de anúncio, propaganda ou material promocional não solicitado ou não
          autorizado pela <b>The Blue City</b>, tais como mensagens não solicitadas ou mensagens
          enviadas em massa (conhecidos como “junk mail” ou “spam”);
        </p>
        <p>
          g) Carregar, transmitir, divulgar, exibir, enviar, ou de qualquer forma tornar disponível
          qualquer conteúdo que contenha vírus ou qualquer outro código, arquivo ou programa de
          computador com o propósito de interromper, destruir ou limitar a funcionalidade de
          qualquer software, hardware ou equipamento;
        </p>
        <p>h) Ameaçar, coagir ou constranger demais Usuários;</p>
        <p>i) Violar direitos de sigilo e privacidade de terceiros;</p>
        <p>
          j) Praticar quaisquer atos que direta ou indiretamente, no todo ou em parte, possam causar
          prejuízo a <b>The Blue City</b>, a outros Usuários ou a qualquer terceiro;
        </p>
        <p>
          k) Utilizar os Serviços para negócios considerados ilícitos de acordo com as normas
          aplicáveis, com intenção de praticar fraudes, ou que venham a causar prejuízos a The Blue
          City, recebedores, instituições ou terceiros. Se houver suspeita de fraude, a The Blue
          City poderá deixar de prestar os Serviços; e
        </p>
        <p>
          l) <b>Trapacear</b>. Na <b>The Blue City</b>, trapaças são estritamente proibidas, de
          forma que estamos sempre trabalhando para aprimorar nossas medidas de combate a todo e
          qualquer tipo de trapaça. Entendemos como trapaça qualquer ação que tente ou de fato
          altere ou interfira com o funcionamento normal ou as regras dos nossos Serviços em seu
          nome ou nome de outros usuários; isso inclui comportamentos como: acessar os Serviços sem
          autorização, inclusive por meio de programas de Terceiros não autorizados; jogar usando
          várias contas para o mesmo Serviço; compartilhar contas; utilizar técnicas para falsificar
          ou alterar a localização do dispositivo, como o uso de GPS falso; e/ou vender ou trocar
          contas.
        </p>

        <h1 className="text-4xl font-bold underline">Atenção:</h1>

        <p>
          Ao concordar com nossos Termos, você aceita que podemos utilizar todos os meios legais
          para detectar e responder a trapaças, fraudes ou qualquer outro comportamento proibido.
          Isso pode incluir a verificação de seu dispositivo quanto a vulnerabilidades, acesso não
          autorizado e/ou programas de computador não autorizados.{' '}
        </p>
        <p>
          Se detectarmos ou tivermos razões razoáveis para suspeitar de trapaça em dispositivos, os
          Aplicativos podem não funcionar corretamente. Além disso, não fornecemos suporte a
          usuários que tentam trapacear e reservamos o direito de encerrar e suspender sem aviso
          contas de Usuários que realizem qualquer tipo de trapaça sem direito a qualquer tipo de
          apelação.
        </p>

        <h1 className="text-4xl font-bold">4.2. São obrigações dos Usuários:</h1>

        <p>
          a) Para usar os Serviços, o Usuário precisa ter equipamentos (smartphone, tablet ou outros
          dispositivos similares) com acesso à internet e em condições compatíveis para seu uso;
        </p>
        <p>
          b) É responsabilidade exclusiva do Usuário obter, manter e custear tal acesso e os
          equipamentos necessários (incluindo tributos, tarifas ou encargos cobrados pelos
          fornecedores de Serviços). A <b>The Blue City</b> não é responsável por não conseguir
          prestar os Serviços devido à incompatibilidade dos equipamentos ou à ausência ou falha no
          acesso à internet;
        </p>
        <p>
          c) O Usuário deve isentar a <b>The Blue City</b> de qualquer reclamação ou litígio
          decorrente dos Serviços, incluindo suas próprias atividades e questões relacionadas aos
          negócios eventualmente realizados após comunicação do Aplicativo;
        </p>
        <p>
          d) <b>O Usuário deve respeitar todos os direitos de propriedade intelectual</b>. A{' '}
          <b>The Blue City</b>
          está em conformidade com a Lei de Direitos Autorais do Milênio Digital (Digital Millennium
          Copyright Act - DMCA) e outras leis de propriedade intelectual aplicáveis e se reserva o
          direito de remover todo e qualquer conteúdo que infrinja direitos da sua propriedade
          intelectual, assim como encerrar as contas de Usuários que incorrerem em infrações
          recorrentes. Detentores de propriedade intelectual e seus representantes que acreditem que
          seus direitos de propriedade intelectual foram violados em algum Jogo, Aplicativo ou
          Serviços proporcionado(s) pela <b>The Blue City</b> e queiram remover o Conteúdo em
          violação de direitos de propriedade intelectual podem entrar em contato com a{' '}
          <b>The Blue City</b> por meio do e-mail contato@thebluecity.app.
        </p>

        <h1 className="text-4xl font-bold underline">Atenção:</h1>

        <p>
          É importante observar que denunciar uma violação de propriedade intelectual é um assunto
          sério e que o envio de denúncias falsas pode resultar em ações judiciais para o
          ressarcimento de danos causados de forma indevida e na suspensão de sua conta ou acesso
          aos Serviços da <b>The Blue City</b>.
        </p>

        <p>
          e) Ao utilizar nossos Serviços, é importante que você se comunique de maneira segura e
          adequada com outros jogadores e pessoas no mundo real. Você concorda em não perturbar,
          ameaçar ou violar de qualquer forma os direitos de terceiros. Não tente invadir
          propriedades ou locais sem permissão, e evite qualquer atividade que possa causar lesões,
          danos à propriedade, perturbações ou responsabilidades de qualquer tipo. Em caso de
          disputa com terceiros relacionada ao uso dos Serviços, você isenta a <b>The Blue City</b>{' '}
          e seus colaboradores de qualquer pedido, demanda ou dano, direto ou indireto, conhecido ou
          desconhecido, esperado ou não, relacionado a essas disputas.
        </p>

        <h1 className="text-4xl font-bold">
          5. Sobre a Segurança Física e Segurança Cibernética dos Usuários
        </h1>

        <p>
          5.1. Para garantir a segurança dos Serviços oferecidos pela <b>The Blue City</b>,
          recomendamos que você siga as precauções básicas de segurança e segurança cibernética
          listadas abaixo:
        </p>

        <p>
          a) Proteja suas credenciais de login: Nunca compartilhe suas informações de login com
          terceiros. Utilize senhas fortes e não reutilize senhas antigas. Ative a autenticação de
          dois fatores sempre que possível;
        </p>
        <p>
          b) Mantenha seus dispositivos atualizados: Instale as atualizações de segurança e os
          patches do sistema operacional em seus dispositivos, como computadores, smartphones e
          tablets, para garantir que estejam protegidos contra vulnerabilidades conhecidas;
        </p>
        <p>
          c) Utilize software antivírus: Instale e mantenha atualizado o software antivírus em seus
          dispositivos, para ajudar a prevenir e detectar ameaças de segurança cibernética;
        </p>
        <p>
          d) Verifique a autenticidade dos sites: Sempre verifique se o site que você está acessando
          é autêntico antes de inserir informações confidenciais. Verifique se a URL começa com
          "https" e se há um cadeado fechado na barra de endereços do navegador;
        </p>
        <p>
          e) Ao utilizar os Serviços é essencial que você preste atenção ao seu ambiente e jogue de
          forma segura. Você concorda que está assumindo riscos ao utilizar os Serviços e se
          compromete a não usá-los para violar leis, regulamentos, políticas de Eventos ou as
          instruções contidas nestes Termos, assim como sabe que não deve encorajar ou permitir que
          outras pessoas façam isso.
        </p>
        <p>
          f) Ao utilizar os Serviços você concorda em não compartilhar conteúdo ilegal, inadequado,
          comercial ou que viole a privacidade e os direitos de imagem de outras pessoas. Não envie
          informações imprecisas, enganosas ou impróprias, incluindo submissões, edições ou
          solicitações de remoção de dados; e
        </p>
        <p>
          g) Os aplicativos fornecidos pela <b>The Blue City</b> não são dispositivos médicos nem
          oferecem aconselhamento médico ou de saúde.
        </p>

        <p>
          5.2. Ao seguir estas precauções básicas de segurança e segurança cibernética, você pode
          ajudar a proteger a sua integridade física e seus dados pessoais e financeiros ao usar os
          Serviços oferecidos pela <b>The Blue City</b>.
        </p>

        <p>
          5.3. Ao concordar com estes termos, você reconhece que leu e compreendeu as precauções de
          segurança e segurança cibernética acima listadas e concorda em seguir as diretrizes
          descritas acima para garantir a segurança dos Serviços oferecidos pela{' '}
          <b>The Blue City</b>.
        </p>

        <h1 className="text-4xl font-bold">6. Direitos de Propriedade Sobre o Conteúdo</h1>

        <p>
          6.1. Para os fins destes Termos, "Conteúdo" significa o texto, programas de computador,
          códigos de script, gráficos, fotos, sons, música, vídeos, combinações audiovisuais,
          comunicações, ferramentas interativas, obras autorais de qualquer tipo, ou de qualquer
          forma exibidas por meio dos Serviços, incluindo conteúdo que seja produzido pelo Usuário,
          o que inclui todo e qualquer Conteúdo que um Usuário de um Serviço forneça para execução
          através dos Serviços.
        </p>

        <h1 className="text-4xl font-bold underline">Atenção:</h1>
        <p>
          É possível realizar o upload de Conteúdo para alguns dos Jogos, Aplicativos e Serviços
          oferecidos pela <b>The Blue City</b>. É importante respeitar as diretrizes estabelecidas
          nestes Termos, nos serviços de Terceiros e a legislação em vigor ao enviar o Conteúdo para
          a <b>The Blue City</b>. Certifique-se de não incluir nenhum material protegido por
          direitos autorais pertencente a terceiros, a menos que você tenha permissão expressa
          dessas partes ou esteja legalmente autorizado a fazê-lo. Ressalta-se que o Usuário é
          legalmente responsável pelo Conteúdo que envia e que, para auxiliar na identificação de
          violações e abusos, como spam, malware e conteúdo ilegal, a <b>The Blue City</b> pode
          utilizar sistemas automatizados que analisam o Conteúdo enviado.
        </p>

        <p>
          6.2. Sujeito ao cumprimento destes Termos, a <b>The Blue City</b> concede ao Usuário uma
          licença pessoal, não comercial, não exclusiva, não transferível, não sublicenciável,
          revogável, limitada, para descarregar (download), visualizar, exibir e usar o Conteúdo
          exclusivamente para o seu uso permitido no âmbito dos Serviços.
        </p>

        <p>
          6.3. A <b>The Blue City</b> não reivindica nenhum direito de propriedade sobre o conteúdo
          que você cria e o Usuário mantém todos os direitos sobre o seu próprio conteúdo. No
          entanto, a <b>The Blue City</b> e seus parceiros têm todos os direitos e propriedade dos
          Serviços e do conteúdo fornecido, incluindo os direitos de propriedade intelectual. Os
          Serviços e o Conteúdo estão protegidos por leis de direitos autorais, marcas registradas e
          outras leis tanto no Brasil como em outros países. É importante respeitar esses direitos,
          então não remova, altere ou oculte nenhum aviso de direitos autorais, marca registrada ou
          outro aviso de propriedade que esteja presente nos Serviços ou no Conteúdo.
        </p>

        <p>
          6.4. Ao disponibilizar qualquer Conteúdo de Usuário por meio dos Serviços, o Usuário
          concede à <b>The Blue City</b> uma licença não exclusiva, transferível, sublicenciável,
          mundial, gratuita e perpétua (ou, caso não permitido pela lei local aplicável, uma licença
          para toda a duração, incluindo para qualquer extensão de proteção legal, de todos os
          direitos relevantes de acordo com a legislação aplicável), para usar, copiar, modificar,
          criar obras derivadas baseadas neste, exibir publicamente, executar publicamente,
          comercializar, promover e distribuir seu Conteúdo de Usuário em conexão com a operação e
          fornecimento dos Serviços e do Conteúdo a você e a Terceiros.
        </p>

        <p>
          6.5. Ao concordar com estes Termos, você está permitindo que a <b>The Blue City</b>{' '}
          aproveite os seguintes direitos de forma livre: o direito de reproduzir o seu Conteúdo de
          Usuário de qualquer maneira e formato; o direito de transmitir ou executar o seu Conteúdo
          de Usuário para o público, seja de forma remunerada ou gratuita, em qualquer lugar e por
          qualquer meio ou processos conhecidos ou ainda não conhecidos no presente momento,
          incluindo a Internet, exibições pagas, exibições em cinemas ou televisão, DVDs, streaming
          e publicações impressas; o direito de usar o seu Conteúdo de Usuário para fins de
          demonstração, promoção e publicidade em todos os Serviços da <b>The Blue City</b>; o
          direito de criar ou solicitar a criação de novos produtos ou Serviços a partir do seu
          Conteúdo de Usuário, ou de qualquer produto que incorpore ou explore o seu Conteúdo de
          Usuário, seja para reprodução como está ou para modificações feitas pela{' '}
          <b>The Blue City</b> ou por Terceiros escolhidos por ela.
        </p>

        <p>
          6.6. O Usuário reconhece e declara que é o único responsável por todo o Conteúdo que
          compartilha. Isso significa que o Usuário declara e garante que é o dono desse Conteúdo ou
          que possui todos os direitos necessários para nos permitir usá-lo de acordo com estes
          Termos.
        </p>

        <p>
          6.7. O Usuário garante que o seu Conteúdo, bem como o seu uso e fornecimento através dos
          Serviços, não viola os direitos de propriedade intelectual de terceiros, direitos de
          publicidade ou privacidade e não infringe nenhuma lei ou regulamento aplicável.
        </p>

        <p>
          6.8. Em relação aos seus direitos autorais, o Usuário concorda que não irá exercer seus
          direitos morais de autor, como o direito de ser reconhecido como o autor do conteúdo,
          contra a <b>The Blue City</b> ou qualquer terceiro designado pela <b>The Blue City</b>,
          dentro dos limites permitidos pela lei aplicável.
        </p>

        <h1 className="text-4xl font-bold">7. Promoções, Ativações e Similares</h1>

        <p>
          7.1. A <b>The Blue City</b>, seus parceiros e Terceiros podem realizar sorteios,
          concursos, rifas, jogos e outras promoções semelhantes relacionados aos Serviços e por
          meio dele. Além desses Termos, as Promoções terão termos específicos que serão informados
          aos Usuários quando ocorrerem.
        </p>

        <p>
          7.2. Ao participar de uma Promoção, o Usuário deve concordar com e obedecer aos Termos da
          Promoção, que podem variar de acordo com cada uma. A <b>The Blue City</b> recomenda que
          você leia os Termos da Promoção antes de aceitá-los. Nossa Política de Privacidade,
          juntamente com estes Termos e quaisquer Termos da Promoção se aplicam a qualquer
          informação que você fornecer em relação a essas Promoções.
        </p>

        <h1 className="text-4xl font-bold">
          8. Eventos: Conduta, Direitos de Imagem e Outras Informações Importantes
        </h1>

        <p>
          8.1. Será considerado como <b>"Evento"</b> qualquer evento/atividade e/ou Promoção
          organizado hospedado ou gerenciado diretamente pela <b>The Blue City</b>. Ao se registrar
          ou comprar ingressos para um Evento (incluindo a compra opcional de experiências de jogo
          adicionais e/ou produtos físicos), o Usuário deve fornecer informações verdadeiras e
          corretas. Se o Usuário estiver registrando ou comprando ingressos em nome de outras
          pessoas, ele deve possuir os direitos necessários para isso e,desde já, concorda em
          fornecer essas informações aos terceiros.
        </p>

        <p>
          8.2. De acordo com a legislação nacional e as exceções mencionadas nestes Termos, os
          ingressos não são reembolsáveis nem transferíveis. Ao participar de um Evento, você pode
          precisar fornecer uma prova razoável de identidade para garantir que seu nome corresponda
          ao registro prévio.
        </p>

        <p>
          8.3. A revenda de ingressos é proibida e pode resultar no cancelamento sem reembolso.
          Ingressos adquiridos de fontes não autorizadas podem ser inválidos, perdidos, roubados ou
          falsificados, e não serão honrados. Se o Usuário perder e/ou danificar o ingresso por
          qualquer motivo, incluindo furto e roubo, ele não será substituído.
        </p>

        <p>
          8.4. O uso comercial dos ingressos é proibido, a menos que haja autorização por escrito da{' '}
          <b>The Blue City</b>. Os ingressos não podem ser trocados por dinheiro ou crédito e o
          Usuário deve obedecer a todas as restrições e limitações impostas aos ingressos, estando
          ciente de que qualquer violação pode resultar no cancelamento sem aviso ou reembolso.
        </p>

        <p>
          8.5. A participação em um Evento significa que você concorda que a <b>The Blue City</b>{' '}
          pode usar os dados coletados sobre você de acordo com a Política de Privacidade, incluindo
          para entrar em contato com você, fornecer atualizações sobre o Evento, enviar materiais
          solicitados (como pulseiras QR), informar sobre eventos naturais ou emergências, ou
          apresentar tabelas de classificação e competições relacionadas ao Evento e experiências de
          jogos.
        </p>

        <p>
          8.6. Ao participar de Eventos organizados pela <b>The Blue City</b>, você concorda e
          autoriza que a empresa grave e utilize sua imagem, nome, voz, diálogo, informações
          biográficas e características pessoais. Essa autorização é gratuita e não está sujeita ao
          pagamento de royalties ou qualquer contrapartida, estando sujeita aos mesmos termos do
          Conteúdo produzido pelo Usuário.
        </p>

        <p>
          8.7. A <b>The Blue City</b> também pode publicar os resultados de competições,
          estatísticas de experiências de jogos e imagens dos participantes em materiais
          promocionais, publicitários e em mídias sociais, de acordo com os termos estabelecidos.
        </p>

        <p>
          8.8. Durante a sua participação em um Evento organizado pela <b>The Blue City</b>, é
          essencial que o Usuário concorde com e cumpra todas as leis, regulamentos e políticas
          estabelecidas pela <b>The Blue City</b> e por seus parceiros autorizados envolvidos na
          criação e organização do Evento. Isso inclui seguir as políticas de saúde, segurança e
          todas as instruções da equipe do evento e dos funcionários da <b>The Blue City</b> e/ou
          parceiros envolvidos.
        </p>

        <p>
          8.9 É estritamente proibido o uso de drogas ilícitas, substâncias controladas,
          contrabando, armas e itens ilegais em qualquer Evento. Ao entrar no Evento, o Usuário
          concorda em passar por medidas razoáveis de segurança e revista. É importante ressaltar
          que, na medida permitida pela legislação vigente, você isenta a <b>The Blue City</b> e
          seus parceiros autorizados na organização do Evento de qualquer responsabilidade por
          danos, perdas ou despesas relacionadas a essas medidas de segurança e revista.
        </p>

        <p>
          8.10. Caso o Usuário decida não consentir com essas medidas de precaução, poderá ter a sua
          entrada negada ou ser removido do Evento, sem direito a reembolso ou qualquer outra forma
          de compensação.
        </p>

        <p>
          8.11. A <b>The Blue City</b> e seus parceiros autorizados têm o direito de recusar a
          entrada ou remover uma pessoa de um Evento, sem reembolso ou compensação, se ela não
          cumprir os Termos, se envolver em comportamento desordeiro ou intencionalmente inadequado,
          assediar, ameaçar, abusar ou agir de forma inapropriada em relação aos colaboradores da{' '}
          <b>The Blue City</b>, a equipe do Evento ou outros participantes e/ou se a{' '}
          <b>The Blue City</b> ou seus parceiros acreditarem que sua presença terá um impacto
          negativo no Evento, nos participantes, espectadores e/ou equipe.
        </p>

        <p>
          8.12. Qualquer menor de idade que pretende participar de um Evento deve estar acompanhado
          por um pai ou responsável sob pena de ter sua entrada negada.
        </p>

        <p>
          8.13. Ao comprar ingressos ou participar de um Evento, o Usuário reconhece e concorda que
          assume voluntariamente todos os riscos e danos que possam ocorrer antes, durante ou após o
          Evento. Isso inclui lesões decorrentes de danos, perdas ou roubo de propriedade.
        </p>

        <p>
          8.14. O usuário entende que os Eventos e certas atividades neles podem apresentar riscos
          inerentes e desconhecidos, como colisões com pessoas ou objetos, obstáculos no caminho,
          riscos relacionados a equipamentos, condições climáticas, medidas de emergência
          inadequadas, comportamento inadequado de participantes ou espectadores e/ou riscos
          naturais.
        </p>

        <p>
          8.15. É importante que o Usuário tome precauções razoáveis antes e durante sua
          participação no Evento, como consultar um médico, usar roupas adequadas e trazer apenas o
          necessário. O Usuário também é responsável por inspecionar as instalações, equipamentos e
          áreas do Evento para garantir sua segurança e, caso perceba alguma condição inadequada ou
          risco imprevisto, deve informar imediatamente a equipe responsável e interromper sua
          participação no Evento.
        </p>

        <p>
          8.16. Na medida máxima permitida por lei, o Usuário concorda em renunciar e isentar a{' '}
          <b>The Blue City</b> e qualquer terceiro envolvido na criação ou organização do Evento de
          qualquer responsabilidade por quaisquer reclamações, ações, danos, perdas, despesas ou
          responsabilidades decorrentes da sua participação no Evento. Isso inclui qualquer
          negligência, riscos inerentes ou imprevistos, lesões ou danos a pessoas ou propriedades,
          bem como as ações de terceiros, outros participantes e espectadores do Evento.
        </p>

        <p>
          8.17. O Usuário reconhece e concorda que os aspectos do Evento, como programações,
          experiências de jogo, atividades, prêmios e conteúdo divulgados em anúncios e outras
          formas de comunicação não são garantidos e podem ser alterados ou cancelados sem aviso
          prévio ou compensação. A entrada no Evento não garante a disponibilidade desses aspectos
          durante o Evento.
        </p>

        <p>
          8.18 A <b>The Blue City</b> se reserva o direito de alterar a data, local e localização do
          Evento por qualquer motivo que julgue necessário, mas a <b>The Blue City</b> fará o
          possível para notificar o Usuário sobre alterações importantes com antecedência.
        </p>

        <p>
          8.19. No caso de cancelamento, suspensão ou reagendamento do Evento, se o Usuário não
          puder comparecer, terá direito apenas ao reembolso do valor pago pelo ingresso, sem
          qualquer responsabilidade adicional ou compensação por parte da <b>The Blue City</b> ou
          terceiros. O Usuário reconhece e concorda que quaisquer custos relacionados a viagens ou
          acomodação são de sua única e exclusiva responsabilidade.
        </p>

        <h1 className="text-4xl font-bold">9. Disposições Gerais</h1>

        <p>
          9.1. <b>Canais de Atendimento</b>: O Usuário poderá entrar em contato com a{' '}
          <b>The Blue City</b> pelo e-mail: contato@thebluecity.app
        </p>

        <p>
          9.2. O Usuário reconhece e concorda que a <b>The Blue City</b> poderá comunicar-se com ele
          através de e-mail, telefone, SMS e/ou mensagens por pop-up/push sempre que o Usuário
          estiver conectado à internet para auxiliá-lo em processo de cadastramento, para lembrá-lo
          de finalizar o cadastramento e/ou para quaisquer outras informações relacionadas a
          prestação dos Serviços.
        </p>

        <p>
          9.3. <b>Isenção de Garantia e Limitação de Responsabilidade</b>. Sem garantia. Nenhuma
          garantia é dada sobre a qualidade, funcionalidade, disponibilidade ou desempenho dos
          Serviços ou de qualquer recurso ou conteúdo oferecido nos Jogos e Aplicativos ou por meio
          deles. Todos os serviços e conteúdos são fornecidos "na forma como ofertados" e "conforme
          disponíveis", com todos os defeitos que venham a apresentar. Podemos alterar, adicionar ou
          remover funcionalidades ou recursos em nossos Serviços e podemos suspender ou interromper
          nossos Serviços por completo. Não garantimos que o serviço e o conteúdo sejam
          ininterruptos, nem livres de erros ou atrasos. Além das limitações de responsabilidade
          deste contrato, isentamo-nos expressamente de qualquer garantia implícita de
          comercialização, garantia de adequação a uma finalidade específica e garantia de não
          violação. Não assumimos qualquer responsabilidade por qualquer incapacidade de comprar,
          acessar, baixar ou usar qualquer conteúdo, dados ou Serviço. Seu único e exclusivo recurso
          em caso de qualquer insatisfação, dano decorrente da utilização dos produtos, Serviços, ou
          em conexão com este contrato, e nossa responsabilidade máxima nos termos deste contrato,
          com respeito ao seu uso ou acesso aos Serviços oferecidos se limitam a seus danos diretos.
          Exceto como indicado na frase anterior, nós nos isentamos de qualquer responsabilidade por
          qualquer perda de dados, danos causados a seu Software ou Hardware e qualquer outra perda
          ou dano sofrido por você ou por qualquer Terceiro, seja direta, indireta, incidental,
          especial ou consequente e, de qualquer forma, decorrente do acesso ou do download de
          qualquer conteúdo para seu dispositivo móvel ou qualquer outro dispositivo de hardware, ou
          do uso ou acesso aos serviços. Exceto no caso em que esta disposição não seja aplicável em
          sua jurisdição, as limitações, exclusões e renúncias acima se aplicam até a máxima
          extensão permitida pela lei, mesmo que qualquer solução não cumpra seu propósito
          essencial.
        </p>

        <p>
          9.4 <b>Jurisdição e lei aplicável</b>: Todos os itens destes Termos são regidos pelas leis
          vigentes na República Federativa do Brasil. Para todos os assuntos referentes à
          interpretação, ao cumprimento ou qualquer outro questionamento relacionado a estes Termos,
          as Partes concordam em se submeter ao Foro da Comarca de São Paulo-SP, com exceção de
          reclamações apresentadas por Usuários que se enquadrem no conceito legal de consumidores,
          que poderão submeter tais reclamações ao foro de seu domicílio.
        </p>
      </div>
    </PageMargin>
  );
};
