interface Option {
  label: string;
  value: string;
  parent?: string;
}

export const companies: Option[] = [
  { label: 'BASF', value: 'BASF' },
  { label: 'AEGEA', value: 'AEGEA' },
];
export const cities: Option[] = [
  { label: 'Jacareí', value: 'Jacarei', parent: companies[0].value },
  { label: 'Guaratinguetá', value: 'Guaratingueta', parent: companies[0].value },
  { label: 'Cabo Frio', value: 'Cabo Frio', parent: companies[1].value },
  { label: 'Teresina', value: 'Teresina', parent: companies[1].value },
  { label: 'Piracicaba', value: 'Piracicaba', parent: companies[1].value },
  { label: 'Sinop', value: 'Sinop', parent: companies[1].value },
];
export const worlds: Option[] = [
  { label: 'O Mundo da Água', value: 'O Mundo da Água' },
  { label: 'O Mundo do Saneamento', value: 'O Mundo do Saneamento' },
  { label: 'O Mundo dos Resíduos', value: 'O Mundo dos Resíduos' },
];

export const games: Option[] = [
  { label: 'Reúso de Água', value: 'Reúso de Água', parent: worlds[0].value },
  { label: 'A história do Saneamento', value: 'A história do Saneamento', parent: worlds[1].value },
  { label: 'Resíduos Orgânicos', value: 'Resíduos Orgânicos', parent: worlds[2].value },
  { label: 'Economia de Água', value: 'Economia de Água', parent: worlds[0].value },
  // { label: 'Água de Chuva', value: 'Água de Chuva' },
  // { label: 'Tratamento e Inovações', value: 'Tratamento e Inovações' },
  { label: 'Saneamento e Saúde', value: 'Saneamento e Saúde', parent: worlds[1].value },
  { label: 'Resíduos Recicláveis', value: 'Resíduos Recicláveis', parent: worlds[2].value },
  // { label: 'Outros Resíduos', value: 'Outros Resíduos' }
];

export const missions: Option[] = [
  { label: 'Como reutilizar a água', value: 'Como reutilizar a água', parent: games[0].value },
  { label: 'Coleta de esgoto', value: 'Coleta de esgoto', parent: games[1].value },
  { label: 'Compostagem', value: 'Compostagem', parent: games[2].value },
  { label: 'A conta de água', value: 'A conta de água', parent: games[3].value },
  { label: 'Resíduos na privada', value: 'Resíduos na privada', parent: games[4].value },
  { label: 'Como separar resíduos', value: 'Como separar resíduos', parent: games[5].value },
];

export const ratings = [
  { label: 'Ficou ótimo!!!', value: 'great' },
  { label: 'Ficou bom ;)', value: 'good' },
  { label: 'Cumpriu :)', value: 'enough' },
];

type ProgressObject = {
  [world: string]: {
    [game: string]: {
      mission: string;
      video?: string;
    };
  };
};

export const progressObject: ProgressObject = {
  'O Mundo da Água': {
    'Reúso de Água': {
      mission: 'Como reutilizar a água',
      video: 'https://www.youtube.com/watch?v=hBPVUSViFDs',
    },
    'Economia de Água': {
      mission: 'A conta de água',
      video: 'https://www.youtube.com/watch?v=Yt_X4HCyvbU',
    },
  },
  'O Mundo do Saneamento': {
    'A história do Saneamento': {
      mission: 'Coleta de esgoto',
      video: 'https://www.youtube.com/watch?v=avBWxoaSong&ab_channel=TheBlueCity',
    },
    'Saneamento e Saúde': {
      mission: 'Resíduos na privada',
      video: 'https://www.youtube.com/watch?v=qL_S4KV-f78',
    },
  },
  'O Mundo dos Resíduos': {
    'Resíduos Orgânicos': {
      mission: 'Compostagem',
      video: 'https://www.youtube.com/watch?v=HxHzgyOjpiw',
    },
    'Resíduos Recicláveis': {
      mission: 'Como separar resíduos',
      video: 'https://www.youtube.com/watch?v=kqpWNVvuPgw',
    },
  },
};
