import React from 'react';
import { cn } from '../../util/cn';

interface MenuItemProps {
  text: string;
  onClick: () => void;
  icon?: React.ReactNode;
  visible: boolean;
  active?: boolean;
  textClassName?: string;
  className?: string;
}

export const MenuItem: React.FC<MenuItemProps> = ({
  text,
  onClick,
  icon,
  visible,
  active,
  textClassName,
  className,
}) => {
  if (!visible) {
    return null;
  }
  return (
    <div
      onClick={onClick}
      className={cn(
        'flex h-12 cursor-pointer flex-row items-center justify-end gap-4 lg:h-16 lg:font-light',
        className,
      )}
    >
      <p
        className={cn(
          `text-ellipsis font-[Raleway] text-xl font-normal lg:text-3xl  ${active ? 'text-tertiary' : 'text-primaryForeground'}`,
          textClassName,
        )}
      >
        {text}
      </p>
      {icon && icon}
    </div>
  );
};
