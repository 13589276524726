export const capitalizeAfterSpace = (str: string) => {
  if (!str) return '';
  return str
    .toLowerCase()
    .split(' ')
    .map((word, index) => 
      index === 0 || word.length > 3 
        ? word[0].toUpperCase() + word.slice(1)
        : word
    )
    .join(' ');
}