import { HTMLAttributes, MouseEventHandler } from "react";
import { cn } from "../../../../util/cn";


interface CityIconProps extends HTMLAttributes<HTMLDivElement> {
    city: string;
    onClick: MouseEventHandler<HTMLImageElement>;
    selected?: boolean;
}

export const CityIcon = ({ city, onClick, className, selected }: CityIconProps) => {
    return (
        <div
            className={cn(
                `transition-all duration-300 ${selected ? "brightness-150 z-20 scale-125 " : "hover:scale-125 hover:z-30 hover:brightness-100 brightness-90 "} flex items-center justify-center cursor-pointer absolute `,
                className
            )}

        >
            <img
                id={city}
                onClick={onClick}
                src={`/svg/${city}.svg`}
                alt={`Ícone da cidade de ${city}`}
                className={city === 'rockinrio' ? "w-[80px] sm:w-[96px] md:w-[112px] lg:w-[128px]" : "w-[48px] sm:w-[64px] md:w-[80px] lg:w-[96px]"}
            />
        </div>
    );
}