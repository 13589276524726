import React from 'react';
import { cn } from '../../util/cn';

interface IPageMarginProps {
  className?: string;
  children: React.ReactNode;
}

const PageMargin: React.FC<IPageMarginProps> = ({ children, className }) => {
  return (
    <div className="flex w-full justify-center">
      <div
        className={cn(
          'flex max-w-[120rem] flex-col gap-16 font-[Raleway] xl:mx-4 2xl:mx-0',
          className,
        )}
      >
        {children}
      </div>
    </div>
  );
};

export default PageMargin;
