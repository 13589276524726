import { isTeacher } from '../../../hooks/isTeacher';
import verifyAdmin from '../../../util/verifyAdmin';
import verifyLogged from '../../../util/verifyLogged';
import { useLocation, useNavigate } from 'react-router-dom';
import { MenuItem } from '../../common/MenuItem';
import getHref from '../../../util/getHref';

interface ISettingsProps {
  handleProfileClick: () => void;
  handleChangeCity: () => void;
  handleLogout: () => void;
  handleLogin: () => void;
}
const Settings: React.FC<ISettingsProps> = ({
  handleProfileClick,
  handleChangeCity,
  handleLogout,
  handleLogin,
}) => {
  const location = useLocation();
  const logged = verifyLogged();
  const admin = verifyAdmin();
  const navigate = useNavigate();
  const avatarName = localStorage.getItem('avatarName');

  return (
    <div className="align-center group relative flex cursor-pointer flex-row justify-center gap-6">
      <div className="mr-4 flex flex-row items-center gap-4 text-primaryForeground">
        <img className="h-10 w-10 lg:h-12 lg:w-12" src="/img/icons/people.png" alt="perfil" />
        {location.pathname !== getHref('superadmin-dashboard') &&
          location.pathname !== getHref('performance-panel') && (
            <p className="hidden font-[Raleway] text-xl font-semibold sm:inline lg:text-3xl">
              {avatarName || 'Acessar'}
            </p>
          )}
      </div>
      <div className="pointer-events-none absolute right-0 top-[100%] w-[14.5rem] opacity-0 transition-opacity duration-300 group-hover:pointer-events-auto group-hover:opacity-100 sm:w-[15rem] lg:w-[20rem]">
        <div className="flex h-auto flex-col overflow-y-auto bg-primary pb-4 pt-8">
          <MenuItem
            text="Alterar perfil"
            onClick={handleProfileClick}
            icon={
              <img className="mr-4 h-10 w-10" src="/img/icons/whiteprofile.png" alt="profile" />
            }
            visible={logged && !admin}
          />
          <MenuItem
            text="Alterar cidade"
            onClick={handleChangeCity}
            icon={<img className="mr-4 h-10 w-10" src="/img/icons/whitecity.png" alt="city" />}
            visible={logged && !admin}
          />
          <MenuItem
            text="Sair"
            onClick={handleLogout}
            icon={<img className="mr-4 h-10 w-10" src="/img/icons/whitelogout.png" alt="logout" />}
            visible={logged || isTeacher()}
          />
          <MenuItem
            text="Jogador"
            onClick={handleLogin}
            icon={<img className="mr-4 h-10 w-10" src="/img/icons/whitelogin.png" alt="login" />}
            visible={!logged && !isTeacher()}
          />
          <MenuItem
            text="Professor"
            onClick={() => navigate('login-professor')}
            icon={<img className="mr-4 h-10 w-10" src="/img/icons/whitelogin.png" alt="login" />}
            visible={!logged && !isTeacher()}
          />
        </div>
      </div>
    </div>
  );
};

export default Settings;
