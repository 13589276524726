export const allCities = [
  {
    value: 'guaratingueta',
    label: 'Guaratinguetá',
  },
  {
    value: 'teresina',
    label: 'Teresina',
  },
  {
    value: 'sinop',
    label: 'Sinop',
  },
  {
    value: 'piracicaba',
    label: 'Piracicaba',
  },
  {
    value: 'cabofrio',
    label: 'Cabo Frio',
  },
  {
    value: 'jacarei',
    label: 'Jacareí',
  },
];
