import React from 'react';
import { Footer } from '../footer';
import { Header } from '../Header/Header';
import { usePlaying } from '../../context/playing';

interface IPageContentProps {
  children: React.ReactNode;
}

export const PageContent: React.FC<IPageContentProps> = ({ children }) => {
  const { setOrigin, isPlaying, origin } = usePlaying();
  const isGamingNeoEnergia = isPlaying && origin.includes('neoenergia');

  React.useEffect(() => {
    const origin = window.location.origin.split('/')?.slice(0, 3);
    setOrigin((oldValue) => {
      if (oldValue !== origin[2]) {
        return origin[2];
      }
      return oldValue;
    });
  }, [setOrigin]);

  return (
    <>
      <Header />
      <div
        className={`flex min-h-screen flex-col bg-primaryForeground ${isGamingNeoEnergia ? 'pt-0' : 'pt-20'}`}
      >
        <div className="flex h-full flex-1 flex-col">
          {children}
          <Footer />
        </div>
      </div>
    </>
  );
};
