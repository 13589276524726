import React, { useEffect, useState } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';

import AvatarList from './ChooseAvatar/AvatarList';
import { createProfileAuth, getSettings } from '../../service';
import { InputForm } from '../Input-form';
import { toast } from 'react-toastify';
import { PageLayout } from '../page-layout';
import { usePlaying } from '../../context/playing';
import { EmphasisTitle } from '../common/EmphasisTitle';
import { Paragraph } from '../common/Paragraph';
import Button from '../common/Button';

interface IFormValues {
  email: string;
  name: string;
  name_avatar: string;
  teacher_class: string;
}

const Profile = () => {
  const [selectedAvatar, setSelectedAvatar] = useState<string>('');
  const [loading, setIsLoading] = React.useState<boolean>(false);
  const [error, setError] = React.useState<string>();
  const { origin } = usePlaying();
  const [userOrigin, setUserOrigin] = React.useState<string>('');

  const initialValues: IFormValues = {
    email: '',
    name: '',
    name_avatar: '',
    teacher_class: '',
  };

  const onSubmit = async (values: IFormValues) => {
    try {
      if (!selectedAvatar) {
        return setError('Selecione um avatar');
      }
      setIsLoading(true);
      await createProfileAuth(
        values.name,
        values.name_avatar,
        selectedAvatar,
        values.teacher_class,
      );
      localStorage.setItem('avatar', selectedAvatar);
      localStorage.setItem('avatarName', values.name_avatar);
      localStorage.setItem('email', values.email);
      toast.success('Perfil salvo com sucesso');
    } catch (error: any) {
      setError(error?.response?.data?.message);
    } finally {
      setIsLoading(false);
    }
  };

  const validationRoles = Yup.object({
    name: Yup.string()
      .required('Campo obrigatório')
      .min(3, 'O nome deve ter pelo menos 3 caracteres'),
    name_avatar: Yup.string()
      .required('Campo obrigatório')
      .min(3, 'O nome do avatar deve ter pelo menos 3 caracteres')
      .max(24, 'O nome do avatar deve ter no máximo 24 caracteres'),
    teacher_class: Yup.string().length(6, 'O código da turma deve possuir exatamente 6 caracteres'),
    // email: Yup.string().email('Email inválido'),
  });

  const formik = useFormik({
    initialValues,
    onSubmit,
    validationSchema: validationRoles,
  });

  useEffect(() => {
    getSettings().then((profile: any) => {
      setSelectedAvatar(profile?.data?.avatar);
      formik.setValues({
        email: profile?.data?.email,
        name: profile?.data?.name,
        name_avatar: profile?.data?.name_avatar,
        teacher_class: profile?.data?.class,
      });
      setUserOrigin(profile?.data?.origin);
    });
  }, []);

  return (
    <PageLayout className="mx-0 px-10 py-0 font-[Raleway] md:py-8">
      <div className="mx-0 flex h-full w-full items-center justify-center">
        <div className="flex w-full flex-col items-center justify-center sm:w-[60rem]">
          <img
            src={
              origin.includes('neoenergia')
                ? '/img/rockinrio/logo-rock-neo.png'
                : '/svg/cidadeazullogo.svg'
            }
            className="h-72 w-72 object-contain"
            alt="logo"
          />
          <EmphasisTitle className="mb-8 mt-2 text-secondary">Altere seu perfil:</EmphasisTitle>
          <div className="w-full items-center justify-center">
            <form className="form" onSubmit={formik.handleSubmit}>
              <div className="flex w-full flex-col items-center justify-center gap-6 md:px-44">
                {userOrigin !== 'teacher' && (
                  <InputForm
                    id="email"
                    className="px-8 text-start"
                    name="email"
                    label="Email"
                    formik={formik}
                    placeholder="Email"
                    value={formik.values.email}
                    error={formik.errors && formik.errors.email}
                    disabled
                  />
                )}
                <InputForm
                  className="px-8 text-start"
                  name="name"
                  label="Name"
                  formik={formik}
                  placeholder="Nome do Jogador"
                  value={formik.values.name}
                  error={formik.errors && formik.errors.name}
                />
                <InputForm
                  className="px-8 text-start"
                  name="name_avatar"
                  label="Nome do Avatar"
                  formik={formik}
                  placeholder="Nome do Avatar"
                  value={formik.values.name_avatar}
                  error={formik.errors && formik.errors.name_avatar}
                  disabled={userOrigin === 'teacher'}
                />
                <InputForm
                  className="px-8 text-start"
                  name="teacher_class"
                  label="Código da turma (escolas)"
                  formik={formik}
                  placeholder="Código da turma (escolas)"
                  value={formik.values.teacher_class}
                  error={formik.errors && formik.errors.teacher_class}
                  disabled={userOrigin === 'teacher'}
                />
              </div>
              {error && (
                <div className="mt-8">
                  <Paragraph className="mb-8 text-center font-semibold text-[#DE3838]">
                    {error === 'Selecione um avatar' ? (
                      error
                    ) : (
                      <span>
                        O código de turma que você inseriu está errado. <br />
                        Fale com seu professor e confira o código certo!
                      </span>
                    )}
                  </Paragraph>
                </div>
              )}
              <div className="relative mt-8 md:px-44 md:pb-72">
                <Paragraph className="text-start font-semibold">Escolha um avatar:</Paragraph>
                <div className="flex w-full flex-col md:absolute md:left-1/2 md:w-[85rem] md:-translate-x-1/2 md:transform">
                  <AvatarList
                    setSelectedAvatar={setSelectedAvatar}
                    avatar={selectedAvatar}
                    isCreating={true}
                  />
                </div>
              </div>
              <div className="w-full md:px-44">
                <Button
                  className="my-8 w-full"
                  type="submit"
                  value={!loading ? 'Salvar' : 'Carregando...'}
                  inactive={loading}
                />
              </div>
            </form>
          </div>
        </div>
      </div>
    </PageLayout>
  );
};

export default Profile;
