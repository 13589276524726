import * as React from 'react';
import { cn } from '../../util/cn';

type Props = React.HTMLAttributes<HTMLInputElement> & {
  value?: string;
  onClick?: React.MouseEventHandler<HTMLInputElement>;
  style?: React.CSSProperties;
  id?: string;
  inactive?: boolean;
  type?: 'button' | 'submit' | 'reset';
  active?: boolean;
};

const Button = ({
  value,
  onClick,
  style,
  id,
  inactive,
  active,
  type = 'button',
  className,
}: Props) => {
  return (
    <input
      className={cn(
        `h-16 w-fit min-w-48 rounded-[10px] bg-secondary px-12 text-3xl font-bold text-primaryForeground hover:scale-105 hover:transform hover:cursor-pointer disabled:cursor-not-allowed ${inactive ? 'transform-none cursor-not-allowed bg-blue-300 transition-none' : ''} ${active ? 'bg-primary' : ''}`,
        className,
      )}
      type={type}
      value={value}
      onClick={onClick}
      style={style}
      id={id}
      disabled={inactive}
    />
  );
};

export default Button;
