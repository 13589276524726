import { useState, useEffect } from 'react';

const useBreakpoint = () => {
  const [breakpoint, setBreakpoint] = useState('md');

  const updateBreakpoint = () => {
    if (window.innerWidth >= 1536) return setBreakpoint('2xl');
    if (window.innerWidth >= 1280) return setBreakpoint('xl');
    if (window.innerWidth >= 1024) return setBreakpoint('lg');
    if (window.innerWidth >= 768) return setBreakpoint('md');
    return setBreakpoint('sm');
  };

  useEffect(() => {
    updateBreakpoint();
    window.addEventListener('resize', updateBreakpoint);
    return () => window.removeEventListener('resize', updateBreakpoint);
  }, []);

  return breakpoint;
};

export default useBreakpoint;
