import * as React from 'react';
import AvatarList from './AvatarList';
import { useFormik } from 'formik';
import * as Yup from 'yup';

import getHref from '../../../util/getHref';
import { useNavigate } from 'react-router-dom';
import { createProfile, createProfileAuth, getSettings } from '../../../service';
import { InputForm } from '../../Input-form';
import { PageLayout } from '../../page-layout';
import { usePlaying } from '../../../context/playing';
import { EmphasisTitle } from '../../common/EmphasisTitle';
import { Paragraph } from '../../common/Paragraph';
import Button from '../../common/Button';

interface IFormValues {
  email: string;
  name: string;
  name_avatar: string;
  teacher_class: string;
}

const validationRoles = Yup.object({
  name: Yup.string()
    .required('Campo obrigatório')
    .min(3, 'O nome deve ter pelo menos 3 caracteres'),
  name_avatar: Yup.string()
    .required('Campo obrigatório')
    .min(3, 'O nome do avatar deve ter pelo menos 3 caracteres')
    .max(24, 'O nome do avatar deve ter no máximo 24 caracteres'),
  teacher_class: Yup.string().length(6, 'O código da turma deve possuir exatamente 6 caracteres'),
  email: Yup.string().email('Email inválido').notRequired(),
});

const ChooseAvatar = () => {
  const [selectedAvatar, setSelectedAvatar] = React.useState<string>('');
  const navigate = useNavigate();

  const [loading, setLoading] = React.useState<boolean>(false);
  const [error, setError] = React.useState<string>();
  const [code, setCode] = React.useState<string>('');
  const [disableAvatar, setDisableAvatar] = React.useState<boolean>(false);
  const { origin } = usePlaying();
  const [userOrigin, setUserOrigin] = React.useState<string>('');

  const searchParams = window.location.search;

  const handleChooseAvatar = async (values: IFormValues) => {
    try {
      if (userOrigin !== 'teacher' && !values.email) {
        formik.setErrors({ email: 'Email obrigatório' });
        return;
      }
      if (!selectedAvatar) {
        return setError('Selecione um avatar');
      }
      setLoading(true);
      let jwt_token = localStorage.getItem('token') || '';

      if (jwt_token && jwt_token !== '') {
        await createProfileAuth(
          values.name,
          values.name_avatar,
          selectedAvatar,
          values.teacher_class,
        );
      } else {
        let email = values.email;

        let jwt_token = await createProfile(
          email,
          code,
          values.name,
          values.name_avatar,
          selectedAvatar,
          values.teacher_class,
        );

        localStorage.setItem('token', jwt_token.data.token);
      }
      localStorage.setItem('avatar', selectedAvatar);
      localStorage.setItem('avatarName', values.name_avatar);

      navigate(getHref('choose-city'));
    } catch (error: any) {
      setError(error.response.data.message);
    } finally {
      setLoading(false);
    }
  };

  const formik = useFormik({
    initialValues: {
      email: '',
      name: '',
      name_avatar: '',
      teacher_class: '',
    },
    onSubmit: handleChooseAvatar,
    validationSchema: validationRoles,
    validateOnChange: false,
  });

  React.useEffect(() => {
    const urlParams = new URLSearchParams(searchParams);
    const email = urlParams.get('email') ?? undefined;
    const urlCode = urlParams.get('code') ?? '';
    if (email) {
      formik.setFieldValue('email', email);
    }
    setCode(urlCode);

    if (!urlCode) {
      getSettings()
        .then(({ data }) => {
          if (data) {
            formik.setFieldValue('email', data?.email);
            formik.setFieldValue('name', data?.name);
            formik.setFieldValue('name_avatar', data?.name_avatar);
            formik.setFieldValue('teacher_class', data?.class);
            setUserOrigin(data?.origin);

            if (data?.name_avatar) {
              setDisableAvatar(true);
            }
          }
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [searchParams]);

  React.useEffect(() => {
    setError(undefined);
  }, [selectedAvatar]);

  return (
    <PageLayout className="mx-0 px-10 py-0 font-[Raleway] md:py-8">
      <div className="mx-0 flex h-full w-full items-center justify-center">
        <div className="flex w-full flex-col items-center justify-center sm:w-[60rem]">
          <img
            src={
              origin.includes('neoenergia')
                ? '/img/rockinrio/logo-rock-neo.png'
                : '/svg/cidadeazullogo.svg'
            }
            className="h-72 w-72 object-contain"
            alt="logo"
          />
          <EmphasisTitle className="mb-8 mt-2 text-secondary">Crie seu Perfil:</EmphasisTitle>
          <div className="w-full items-center justify-center">
            <form className="form" onSubmit={formik.handleSubmit}>
              <div className="flex w-full flex-col items-center justify-center gap-6 md:px-44">
                {userOrigin !== 'teacher' && (
                  <InputForm
                    id="email"
                    className="px-8 text-start"
                    name="email"
                    label="Email"
                    formik={formik}
                    placeholder="Email"
                    disabled={!!formik.values.email || loading}
                    value={formik.values.email}
                    error={formik.errors && formik.errors.email}
                  />
                )}

                <InputForm
                  className="px-8 text-start"
                  name="name"
                  label="Name"
                  formik={formik}
                  placeholder="Nome do Jogador"
                  value={formik.values.name}
                  error={formik.errors && formik.errors.name}
                />
                <InputForm
                  className="px-8 text-start"
                  name="name_avatar"
                  label="Nome do Avatar"
                  formik={formik}
                  placeholder="Nome do Avatar"
                  value={formik.values.name_avatar}
                  disabled={disableAvatar || loading}
                  error={formik.errors && formik.errors.name_avatar}
                />
                <InputForm
                  className="px-8 text-start"
                  name="teacher_class"
                  label="Código da turma (escolas)"
                  formik={formik}
                  placeholder="Código da turma (escolas)"
                  value={formik.values.teacher_class}
                  error={formik.errors && formik.errors.teacher_class}
                  disabled={loading || userOrigin === 'teacher'}
                />
              </div>
              {error && (
                <div className="mt-8">
                  <Paragraph className="mb-8 text-center font-semibold text-[#DE3838]">
                    {error === 'Selecione um avatar' ? (
                      error
                    ) : (
                      <span>
                        O código de turma que você inseriu está errado. <br />
                        Fale com seu professor e confira o código certo!
                      </span>
                    )}
                  </Paragraph>
                </div>
              )}
              <div className="relative mt-8 md:px-44 md:pb-72">
                <Paragraph className="text-start font-semibold">Escolha um avatar:</Paragraph>
                <div className="flex w-full flex-col md:absolute md:left-1/2 md:w-[85rem] md:-translate-x-1/2 md:transform">
                  <AvatarList
                    setSelectedAvatar={setSelectedAvatar}
                    avatar={selectedAvatar}
                    isCreating={true}
                  />
                </div>
              </div>
              <div className="w-full md:px-44">
                <Button
                  className="my-8 w-full"
                  type="submit"
                  value={!loading ? 'Salvar' : 'Carregando...'}
                  inactive={loading}
                />
              </div>
            </form>
          </div>
        </div>
      </div>
    </PageLayout>
  );
};

export default ChooseAvatar;
