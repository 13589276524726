import * as React from 'react';

type Props = {
  src: string;
  id?: string;
  onClick?: React.MouseEventHandler<HTMLImageElement>;
  selected?: boolean;
};

const AvatarIcon = ({ src, id, onClick, selected = false }: Props) => {
  return (
    <img
      className={`${selected ? 'w-28 md:w-40' : 'w-24 md:w-32'} cursor-pointer transition-all ease-in-out hover:scale-105 hover:transform hover:cursor-pointer`}
      id={id}
      src={src}
      onClick={onClick}
      alt=""
    />
  );
};

export default AvatarIcon;
