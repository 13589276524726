import { createContext, useContext } from "react";

export type MenuContent = {
    movieMode: boolean;
    setMovieMode: (_value: boolean) => void;
};

export const MenuContext = createContext<MenuContent>({
    movieMode: false,
    setMovieMode: (_value: boolean) => { },
});

export const useMenuContext = () => useContext(MenuContext);
