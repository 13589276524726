import { useRef, useState } from 'react';
import { Card } from '../../../../components/card';
import { defaultEvaluationSRC } from '../../../../components/evaluations-list';
import { Input } from '../../../../components/input';
import { VideoRender } from '../../../../components/video-render';
import { publishVideo } from '../../../../service';
import { useMutation } from '@tanstack/react-query';
import { companiesByCity } from '../../../../util/verifyCityBelongsClient';
import { toast } from 'react-toastify';
import Button from '../../../../components/common/Button';
import { capitalizeWords } from '../../../../hooks/captalize-words';

interface UnpublishedVideoProps {
  published: boolean;
  video: VideoData;
  refetch: () => void;
}

export const UnpublishedVideo = ({ video, published, refetch }: UnpublishedVideoProps) => {
  const [duration, setDuration] = useState<number>(0);
  const [content, setContent] = useState<number>(0);
  const [originality, setOriginality] = useState<number>(0);
  const videoRenderParentRef = useRef<HTMLDivElement>(null);

  const approveVideo = async () => {
    if (content === 0 || duration === 0 || originality === 0)
      return Promise.reject('Preencha todos os campos');
    if (content > 1000 || duration > 1000 || originality > 2000)
      return Promise.reject('Pontuação máxima excedida');
    await publishVideo(video.id, video.email, content, originality, duration);
  };
  const { mutate } = useMutation({
    mutationFn: approveVideo,
    onSuccess: () => {
      refetch();
      toast.success('Vídeo aprovado com sucesso');
    },
    onError: (error: string) => {
      toast.error(error);
    },
  });

  const approvedContent = (video?.content ?? 0) >= 500;
  const approvedDuration = (video?.duration ?? 0) >= 500;
  const approvedOriginality = (video?.originality ?? 0) >= 1000;

  if (!video) return null;

  return (
    <Card className="items-start bg-[#E2E2E2]">
      <div className="mb-4 flex w-full justify-center" ref={videoRenderParentRef}>
        <VideoRender
          parentRef={videoRenderParentRef}
          video={video?.link_video}
          service={video?.service}
        />
      </div>
      <div className="flex flex-col gap-4">
        <div className="flex items-center gap-2">
          <p className="text-2xl">
            <b>Nome do Jogador:</b> {video?.userName ? capitalizeWords(video?.userName) : '-'}
          </p>
        </div>
        <div className="flex items-center gap-2">
          <p className="text-2xl font-extrabold">Avatar:</p>
          <p className="text-2xl">{video?.userAvatar}</p>
        </div>
        <div className="flex items-center gap-2">
          <p className="text-2xl font-extrabold">Empresa:</p>
          <p className="text-2xl">
            {capitalizeWords(companiesByCity[video?.city?.toLowerCase().trim()]) || '-'}
          </p>
        </div>
        <div className="flex items-center gap-2">
          <p className="text-2xl font-extrabold">Cidade:</p>
          <p className="text-2xl">{capitalizeWords(video.city) || '-'}</p>
        </div>
        <div className="flex items-center gap-2">
          <p className="text-2xl font-extrabold">Mundo:</p>
          <p className="text-2xl">{video?.world}</p>
        </div>
        <div className="flex items-center gap-2">
          <p className="text-2xl font-extrabold">Jogo:</p>
          <p className="text-2xl">{video?.game}</p>
        </div>
        <div className="flex items-center gap-2">
          <p className="text-2xl font-extrabold">Missão:</p>
          <p className="text-2xl">{video?.mission}</p>
        </div>
        <div className="flex gap-2">
          <p className="text-2xl">
            <b>Nome da Escola:</b>{' '}
            {video.schoolName ? capitalizeWords(video.schoolName) : 'Não informado'}
          </p>
        </div>
        <div className="flex gap-2">
          <p className="text-2xl font-extrabold">Código de Turma:</p>
          <p className="text-2xl">{video.class_code || 'Não informado'}</p>
        </div>
        <div className="flex w-full flex-col items-start gap-6">
          <p className="text-2xl font-extrabold">Pontuação:</p>
          <div className="flex w-full flex-row items-center justify-between gap-4 lg:flex-col xl:flex-row">
            <div
              className={`${published ? 'w-full' : 'w-full xl:w-1/2'} flex flex-row items-center justify-start gap-2`}
            >
              <img
                className="h-12"
                src={approvedContent ? '/img/evaluations/content.png' : defaultEvaluationSRC}
                alt="Avaliação"
              />
              <p className="text-2xl font-semibold leading-snug">Conteúdo: {video?.content}</p>
            </div>
            {!published && (
              <Input
                onChange={(e) => setContent(Number(e.target.value))}
                className="w-full text-ellipsis bg-white xl:w-1/2"
                placeholder="Inserir nota de 0 a 1000"
                title="Insira a pontuação de 0 a 1000"
              />
            )}
          </div>
          <div className="flex w-full flex-row items-center justify-between gap-4 lg:flex-col xl:flex-row">
            <div
              className={`${published ? 'w-full' : 'w-full xl:w-1/2'} flex flex-row items-center justify-start gap-2`}
            >
              <img
                className="h-12"
                src={approvedDuration ? '/img/evaluations/duration.png' : defaultEvaluationSRC}
                alt="Avaliação"
              />
              <p className="text-2xl font-semibold leading-snug">Duração: {video?.duration}</p>
            </div>
            {!published && (
              <Input
                onChange={(e) => setDuration(Number(e.target.value))}
                className="w-full text-ellipsis bg-white xl:w-1/2"
                placeholder="Inserir nota de 0 a 1000"
                title="Insira a pontuação de 0 a 1000"
              />
            )}
          </div>
          <div className="flex w-full flex-row items-center justify-between gap-4 lg:flex-col xl:flex-row">
            <div
              className={`${published ? 'w-full' : 'w-full xl:w-1/2'} flex flex-row items-center justify-start gap-2`}
            >
              <img
                className="h-12"
                src={
                  approvedOriginality ? '/img/evaluations/originality.png' : defaultEvaluationSRC
                }
                alt="Avaliação"
              />
              <p className="text-2xl font-semibold leading-snug">
                Originalidade: {video?.originality}
              </p>
            </div>
            {!published && (
              <Input
                onChange={(e) => setOriginality(Number(e.target.value))}
                className="w-full text-ellipsis bg-white xl:w-1/2"
                placeholder="Inserir nota de 0 a 2000"
                title="Insira a pontuação de 0 a 2000"
              />
            )}
          </div>
        </div>

        {published && (
          <div className="mt-6 flex flex-col gap-4">
            <div className="flex gap-2">
              <p className="text-2xl font-extrabold">Pontuação video:</p>
              <p className="text-2xl">{video.score}</p>
            </div>
            {/* <div className="flex gap-2">
              <p className="text-2xl font-extrabold">Pontuação total:</p>
              <p className="text-2xl">{video.total_score}</p>
            </div> */}
            <div className="flex gap-2">
              <p className="text-2xl font-extrabold">Galeria Geral:</p>
              <p className="text-2xl">
                {approvedContent && approvedDuration && (video.total_score || 0) >= 6700
                  ? 'Sim'
                  : 'Não'}
              </p>
            </div>
          </div>
        )}
      </div>
      <div className="flex w-full items-center justify-center">
        <Button
          onClick={() => {
            if (!published) mutate();
          }}
          className={`my-4 ${published && 'bg-[#118C2C]'} `}
          value={published ? 'Avaliado' : 'Avaliar'}
        />
      </div>
    </Card>
  );
};
