import { cn } from '../../util/cn';
import React, { HTMLAttributes } from 'react';
import { Paragraph } from '../common/Paragraph';

interface ISelectForm extends HTMLAttributes<HTMLSelectElement> {
  name: string;
  formik?: any;
  placeholder: string;
  disabled?: boolean;
  value?: string;
  error?: string;
  onChange?: React.ChangeEventHandler<HTMLSelectElement>;
  options: {
    value: string;
    label: string;
  }[];
}

export const SelectForm = ({
  name,
  formik,
  placeholder,
  disabled,
  value,
  error,
  className,
  onChange,
  options,
}: ISelectForm) => {
  return (
    <div className="relative flex w-full flex-col items-start">
      <select
        className={cn(
          'border-input focus-visible:ring-ring flex h-16 w-full rounded-xl border border-gray-400 bg-[#F3F3F3] px-3 py-1 text-center text-3xl leading-relaxed shadow-sm transition-colors file:border-0 file:bg-transparent file:text-sm file:font-medium placeholder:text-gray-500 focus-visible:outline-none focus-visible:ring-1 disabled:cursor-not-allowed disabled:opacity-50',
          className,
          error && 'mb-2 border-red-500',
          !value && 'text-gray-500',
        )}
        name={name}
        id={name}
        onChange={formik ? formik.handleChange : onChange}
        onBlur={formik && formik.handleBlur}
        value={value}
        disabled={disabled}
      >
        <option value="">{placeholder}</option>
        {options.map((option) => (
          <option key={option.value} value={option.value}>
            {option.label}
          </option>
        ))}
      </select>
      {error && <Paragraph className="-mb-4 text-red-500 md:text-2xl">{error}</Paragraph>}
    </div>
  );
};
