import jwt from "jwt-decode";

import { useEffect } from "react";
import getClient from "../../util/getClient";
import { useNavigate } from "react-router-dom";
import getHref from "../../util/getHref";
import { tikTokAuthenticationauth } from "../../service";

const Login = (jwt_token: any, navigate: any) => {
    localStorage.clear();
    const jwt_values: any = jwt(jwt_token);

    localStorage.setItem("token", jwt_token);

    if (jwt_values.avatar && jwt_values.avatar !== "") {
        localStorage.setItem("avatar", jwt_values.avatar);
        localStorage.setItem("email", jwt_values.email)
        localStorage.setItem("avatarName", jwt_values.avatarName || "");
        window.dispatchEvent(new Event('login'))
    } else {
        return navigate(getHref("choose-avatar"));
    }

    if (jwt_values.city && jwt_values.city !== "") {
        localStorage.setItem("city", jwt_values.city);
        localStorage.setItem("game", jwt_values.game || "");
    } else {
        return navigate(getHref("choose-city"));
    }
    return navigate(getHref(""));
};

const TikTokRedirect = () => {
    const navigate = useNavigate();

    useEffect(() => {
        const urlSearchParams = new URLSearchParams(window.location.search);
        const code: string | null = urlSearchParams.get("code");

        tikTokAuthenticationauth(code)
            .then((response) => {
                return Login(response?.data?.token, navigate);
            })
            .catch((error) => {
                console.log("error", error);
            });
    }, [navigate]);

    return <div>Redirect page</div>;
};

export default TikTokRedirect;
