import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import { loginAdmin } from '../../service';

import { PageLayout } from '../page-layout';
import { usePlaying } from '../../context/playing';
import { EmphasisTitle } from '../common/EmphasisTitle';
import { InputForm } from '../Input-form';
import { Paragraph } from '../common/Paragraph';
import Button from '../common/Button';

const LoginAdmin = () => {
  const [loading, setIsLoading] = React.useState<boolean>(false);
  const [email, setEmail] = React.useState<string>('');
  const [password, setPassword] = React.useState<string>('');
  const [loginError, setLoginError] = React.useState<boolean>(false);
  const navigate = useNavigate();
  const { origin } = usePlaying();

  const handleLoginButtonPressed = async () => {
    try {
      setIsLoading(true);
      localStorage.clear();
      const jwt_token = await loginAdmin(email, password);
      localStorage.setItem('token', jwt_token.data.token);
      window.dispatchEvent(new Event('login'));
      return navigate('/superadmin-dashboard');
    } catch (error) {
      setLoginError(true);
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  React.useEffect(() => {
    setTimeout(() => {
      setLoginError(false);
    }, 4000);
  }, [loginError]);

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    handleLoginButtonPressed();
  };

  return (
    <PageLayout className="mx-0 px-10 py-0 font-[Raleway] md:py-8" title="Painel ADM">
      <div className="mx-0 flex h-full w-full items-center justify-center">
        <div className="flex w-full flex-col items-center justify-center sm:w-[45rem]">
          <img
            src={
              origin.includes('neoenergia')
                ? '/img/rockinrio/logo-rock-neo.png'
                : '/svg/cidadeazullogo.svg'
            }
            alt="logo"
            className="h-72 w-72 object-contain"
          />
          <EmphasisTitle className="mb-8 mt-4 text-secondary">
            Administrador(a), faça seu login:
          </EmphasisTitle>
          <form
            onSubmit={handleSubmit}
            className="flex w-full flex-col items-center justify-center gap-6"
          >
            <InputForm
              className="px-8 text-start"
              name="email"
              label="Email"
              placeholder="Email"
              value={email}
              onChange={(e) => setEmail(e.currentTarget.value)}
            />
            <InputForm
              className="px-8 text-start"
              name="password"
              label="Senha"
              placeholder="Senha"
              value={password}
              type="password"
              onChange={(e) => setPassword(e.currentTarget.value)}
            />
            {loginError && (
              <Paragraph className="text-center font-semibold text-[#DE3838]">
                Email ou senha inválidos
              </Paragraph>
            )}
            <Button
              type="submit"
              className="w-full"
              value={!loading ? 'Entrar' : 'Carregando...'}
              inactive={loading}
            />
          </form>
        </div>
      </div>
    </PageLayout>
  );
};

export default LoginAdmin;
