import styled from "styled-components";

export const Table = styled.table`
    border-collapse: collapse;
    border-spacing: 0;
    empty-cells: show;
`

export const TD = styled.td`
    vertical-align: top;
    font-size: 12pt;
`

export const TH = styled.th`
    vertical-align: top;
    font-size: 12pt;
`

export const H1 = styled.h1`
    clear: both;
`

export const H2 = styled.h2`
    clear: both;
`

export const H3 = styled.h3`
    clear: both;
`

export const H4 = styled.h4`
    clear: both;
`

export const H5 = styled.h5`
    clear: both;
`

export const H6 = styled.h6`
    clear: both;
`

export const OL = styled.ol`
    margin: 0;
    padding: 0;
`

export const UL = styled.ul`
    margin: 0;
    padding: 0;
`

export const LI = styled.li`
    list-style: none;
    margin: 0;
    padding: 0;
`

export const P1 = styled.p`
    font-size: 1.8rem;
    line-height: 200%;
    margin-bottom: 0cm;
    margin-top: 0cm;
    text-align: center !important;
    font-family: Arial;
    writing-mode: horizontal-tb;
    direction: ltr;
    margin-left: 0cm;
    margin-right: 0cm;
    text-indent: 1.249cm;
`

export const P10 = styled.p`
    font-size: 11pt;
    line-height: 115%;
    margin-bottom: 0cm;
    margin-top: 0cm;
    text-align: left !important;
    font-family: Arial;
    writing-mode: horizontal-tb;
    direction: ltr;
`

export const P11 = styled.p`
    font-size: 11pt;
    line-height: 115%;
    margin-bottom: 0cm;
    margin-top: 0cm;
    text-align: justify !important;
    font-family: Arial;
    writing-mode: horizontal-tb;
    direction: ltr;
    margin-left: 0cm;
    margin-right: 0cm;
    text-indent: 1.249cm;
`

export const P12 = styled.p`
    font-size: 11pt;
    line-height: 115%;
    margin-bottom: 0cm;
    margin-top: 0cm;
    text-align: justify !important;
    font-family: Arial;
    writing-mode: horizontal-tb;
    direction: ltr;
    margin-left: 0cm;
    margin-right: 0cm;
    text-indent: 1.27cm;
`

export const P13 = styled.p`
    font-size: 1.8rem;
    line-height: 150%;
    margin-bottom: 0cm;
    margin-top: 0cm;
    text-align: justify !important;
    font-family: Arial;
    writing-mode: horizontal-tb;
    direction: ltr;
`

export const P2 = styled.p`
    font-size: 12pt;
    line-height: 115%;
    margin-bottom: 0cm;
    margin-top: 0cm;
    text-align: center !important;
    font-family: "Times New Roman";
    writing-mode: horizontal-tb;
    direction: ltr;
    margin-left: 0cm;
    margin-right: 0cm;
    text-indent: 1.249cm;
    font-weight: bold;
`

export const P3 = styled.p`
    font-size: 12pt;
    line-height: 115%;
    margin-bottom: 0cm;
    margin-top: 0cm;
    text-align: justify !important;
    font-family: "Times New Roman";
    writing-mode: horizontal-tb;
    direction: ltr;
    font-weight: bold;
`

export const P4 = styled.p`
    font-size: 12pt;
    line-height: 115%;
    margin-bottom: 0cm;
    margin-top: 0cm;
    text-align: left !important;
    font-family: "Times New Roman";
    writing-mode: horizontal-tb;
    direction: ltr;
`

export const P5 = styled.p`
    font-size: 12pt;
    line-height: 115%;
    margin-bottom: 0cm;
    margin-top: 0cm;
    text-align: justify !important;
    font-family: "Times New Roman";
    writing-mode: horizontal-tb;
    direction: ltr;
    margin-left: 0cm;
    margin-right: 0cm;
    text-indent: 1.249cm;
`

export const P6 = styled.p`
    font-size: 1.8rem;
    line-height: 150%;
    margin-bottom: 0cm;
    margin-top: 0cm;
    text-align: justify !important;
    font-family: "Times New Roman";
    writing-mode: horizontal-tb;
    direction: ltr;
    margin-left: 0cm;
    margin-right: 0cm;
    text-indent: 1.27cm;
`

export const P7 = styled.p`
    font-size: 12pt;
    line-height: 115%;
    margin-bottom: 0cm;
    margin-top: 0cm;
    text-align: justify !important;
    font-family: "Times New Roman";
    writing-mode: horizontal-tb;
    direction: ltr;
    margin-left: 0cm;
    margin-right: 0cm;
    text-indent: 1.27cm;
    background-color: #ffffff;
`

export const P8 = styled.p`
    font-size: 12pt;
    line-height: 115%;
    margin-bottom: 0cm;
    margin-top: 0cm;
    text-align: justify !important;
    font-family: "Times New Roman";
    writing-mode: horizontal-tb;
    direction: ltr;
    background-color: #ffffff;
`

export const P9 = styled.p`
    font-size: 12pt;
    line-height: 115%;
    margin-bottom: 0cm;
    margin-top: 0cm;
    text-align: justify !important;
    font-family: "Times New Roman";
    writing-mode: horizontal-tb;
    direction: ltr;
    margin-left: 0cm;
    margin-right: 0cm;
    text-indent: 1.27cm;
    background-color: #ffff00;
`

export const NORMAL = styled.p`
    font-size: 11pt;
    font-family: Arial;
    writing-mode: horizontal-tb;
    direction: ltr;
    margin-top: 0cm;
    margin-bottom: 0cm;
    line-height: 115%;
    text-align: left !important;
`

export const T1 = styled.span`
    font-family: "Times New Roman";
    font-size: 1.8rem;
    font-weight: bold;
`

export const T2 = styled.span`
    font-family: "Times New Roman";
    font-size: 1.8rem;
`

export const T3 = styled.span`
    font-family: "Times New Roman";
    font-size: 12pt;
    background-color: #ffffff;
`

export const T4 = styled.span`
    font-family: "Times New Roman";
    font-size: 12pt;
    font-style: italic;
`

export const T5 = styled.span`
    font-family: "Times New Roman";
    font-size: 12pt;
    text-decoration: underline;
    font-weight: bold;
`

export const T6 = styled.span`
    color: #0563c1;
    font-family: "Times New Roman";
    font-size: 12pt;
    text-decoration: underline;
`

export const T7 = styled.span`
    color: #1155cc;
    font-family: "Times New Roman";
    font-size: 12pt;
    text-decoration: underline;
`
