import { Filters } from '.';
import { gradeClassifier } from '../../util/grade-classifier';
import { companiesByCity } from '../../util/verifyCityBelongsClient';

const applyFilters = (video: VideoData, filters: Filters) => {
  const { company, city, world, mission, class: classCode, rating, game } = filters;
  if (company && companiesByCity[video.city.toLowerCase()].toLowerCase() !== company.toLowerCase())
    return false;
  if (city && !video.city.toLowerCase().trim().includes(city.toLowerCase())) return false;
  if (world && !video.world.toLowerCase().includes(world.toLowerCase())) return false;
  if (mission && video.mission.toLowerCase() !== mission.toLowerCase()) return false;
  if (rating && gradeClassifier(video.total_score!) !== rating) return false;
  if (game && video.game.toLowerCase() !== game.toLowerCase()) return false;
  if (classCode && video.class_code !== classCode) return false;
  return true;
};

export { applyFilters };
