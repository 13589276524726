import jwt from "jwt-decode";
import { Prefs } from "../App";

const verifyAdmin = (): boolean => {
    let admin = false;

    const token = localStorage.getItem("token");

    if (token && token !== "") {
        const jwt_values: Prefs = jwt(token);
        if (jwt_values && jwt_values.type === "ADMIN") {
            admin = true;
        }
    }

    return admin;
};

export default verifyAdmin;
