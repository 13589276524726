import React from 'react';
import Modal from 'react-modal';

interface IModalProps {
  width?: string;
  height?: string;
  maxWidth?: string;
  maxHeight?: string;
  children: React.ReactElement;
}

export interface IModalRef {
  handleModal: () => void;
}

const ModalComponent = React.forwardRef(
  (props: React.PropsWithChildren<IModalProps>, ref: React.ForwardedRef<IModalRef>) => {
    const {
      children,
      width = '97%',
      height = '97%',
      maxWidth = '100%',
      maxHeight = '100%',
    } = props;
    const [open, setOpen] = React.useState<boolean>(false);

    const handleModal = () => {
      setOpen((prevState) => {
        return !prevState;
      });
    };

    React.useImperativeHandle(ref, () => ({
      handleModal,
    }));

    return (
      <Modal
        isOpen={open}
        style={{
          overlay: { zIndex: 999, backgroundColor: 'rgba(0, 0, 0, 0.75)' },
          content: {
            width: width,
            height: height,
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            transform: 'translate(-50%, -50%)',
            padding: '0',
            maxWidth: maxWidth,
            maxHeight: maxHeight,
          },
        }}
      >
        <div className="relative z-[999] flex h-full w-full flex-col items-center justify-center">
          <img
            onClick={() => setOpen(false)}
            src="/img/icons/close.webp"
            className="absolute right-8 top-8 h-8 transform cursor-pointer hover:scale-110"
            alt="close"
          />
          {children}
        </div>
      </Modal>
    );
  },
);

export default ModalComponent;
