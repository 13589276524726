import React from 'react';
import { MouseEventHandler, useEffect, useState } from 'react';
import Button from '../../components/common/Button';
import { PageLayout } from '../../components/page-layout';
import { CityIcon } from './components/city-icon';
import { getSettings, setCity } from '../../service';
import { isProduction } from '../../hooks/isProduction';
import { useNavigate } from 'react-router-dom';
import { firstCharacterToUppercase } from '../../util/first-character-touppercase';
import { usePlaying } from '../../context/playing';
import { EmphasisTitle } from '../../components/common/EmphasisTitle';
import { useLoading } from '../../hooks/use-loading';
import { toast } from 'react-toastify';

export const ChooseCity = () => {
  const { origin } = usePlaying();
  const { setIsLoading } = useLoading();

  const cities = [
    { name: 'teresina', className: 'top-[22%] left-[67%]' },
    { name: 'cabofrio', className: 'top-[64%] left-[72%]' },
    { name: 'sinop', className: 'top-[38%] left-[42%]' },
    { name: 'piracicaba', className: 'top-[64%] left-[50%]' },
    { name: 'guaratingueta', className: 'top-[64%] left-[62%]' },
    { name: 'jacarei', className: 'top-[54%] left-[56%]' },
  ];
  const deparaCityClient: Record<string, string> = React.useMemo(() => {
    return {
      teresina: 'aegea',
      cabofrio: 'aegea',
      sinop: 'aegea',
      jacarei: 'basf',
      piracicaba: 'aegea',
      guaratingueta: 'basf',
      rockinrio: 'rockinrio',
    };
  }, []);

  const [selectedCity, setSelectedCity] = useState<string>(
    localStorage.getItem('city') ?? (origin.includes('neoenergia') ? 'rockinrio' : cities[0].name),
  );
  const [selectedGame, setSelectedGame] = useState<string>(deparaCityClient[selectedCity]);
  const navigate = useNavigate();

  useEffect(() => {
    setIsLoading(true);
    getSettings().then((profile) => {
      if (profile.data.city && profile.data.city !== '') {
        setSelectedCity(profile.data.city);
        setSelectedGame(deparaCityClient[profile.data.city]);
      }
      setIsLoading(false);
    });
  }, [deparaCityClient]);

  const updateCity = async (city: string) => {
    try {
      setIsLoading(true);
      await setCity(city, selectedGame);
      localStorage.setItem('city', city);
      localStorage.setItem('game', selectedGame);
      setIsLoading(false);
    } catch (error: any) {
      console.error(error.response.data.message);
      toast.error(error.response.data.message);
      setIsLoading(false);
    }
  };

  const handleCityClick: MouseEventHandler<HTMLImageElement> = async (event) => {
    const newCity = event.currentTarget.id;

    setSelectedCity(newCity);
    setSelectedGame(deparaCityClient[newCity]);
    await updateCity(newCity);
  };

  const handlePlayButtonClick = async () => {
    try {
      await setCity(selectedCity, selectedGame);

      localStorage.setItem('city', selectedCity);
      localStorage.setItem('game', selectedGame);

      navigate('/');
    } catch (error) {
      console.error(error);
    }
  };

  const handleGalleryClick = async () => {
    try {
      await setCity(selectedCity, selectedGame);

      localStorage.setItem('city', selectedCity);
      localStorage.setItem('game', selectedGame);

      // @ts-ignore
      const client = deparaCityClient[selectedCity];
      navigate(`/video-gallery?client=${client}`);
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <PageLayout className="mx-0 gap-3 p-8">
      <div className="z-10 flex w-full flex-col items-start gap-4">
        <EmphasisTitle className="hidden pb-12 md:inline">
          Cidade selecionada:{' '}
          <span className="text-secondary">{firstCharacterToUppercase(selectedCity)}</span>
        </EmphasisTitle>
        <EmphasisTitle className="inline pb-12 text-secondary md:hidden">
          Escolha sua cidade:
        </EmphasisTitle>
      </div>

      <div className="relative mb-8 flex w-full max-w-[810px] flex-col self-center">
        <div className="relative">
          <img src="/svg/brasilmap.svg" alt="Mapa do Brasil" className="w-full" />
          {origin.includes('neoenergia') ? (
            <CityIcon
              id={'rockinrio'}
              selected
              className={'left-[50%] top-[45%]'}
              onClick={handleCityClick}
              city={'rockinrio'}
            />
          ) : (
            cities.map((city, index) => {
              return (
                <CityIcon
                  key={index}
                  id={city.name}
                  selected={selectedCity === city.name}
                  className={city.className}
                  onClick={handleCityClick}
                  city={city.name}
                />
              );
            })
          )}
          <EmphasisTitle className="absolute bottom-72 hidden text-secondary md:left-52 md:inline lg:left-64">
            Escolha sua cidade:
          </EmphasisTitle>
        </div>
      </div>
      <div className="mt-8 flex w-full items-center justify-center gap-8 md:absolute md:bottom-0 md:right-10 md:w-fit lg:bottom-24 xl:right-80 2xl:right-96">
        <Button
          className="h-12 text-2xl sm:h-14 sm:text-3xl md:h-16"
          value="Jogar"
          onClick={() => navigate('/')}
        />
        <Button
          className="h-12 text-2xl sm:h-14 sm:text-3xl md:h-16"
          value="Galeria do Jogador"
          onClick={() => navigate(`/video-gallery`)}
        />
      </div>
    </PageLayout>
  );
};
