import {
  P1,
  P10,
  P11,
  P12,
  P13,
  P2,
  P3,
  P4,
  P5,
  P6,
  P7,
  P8,
  P9,
  T1,
  T2,
  T3,
  T4,
  T5,
  T6,
  T7,
  NORMAL,
} from './regulation-style';

export default () => {
  return (
    <div className="black-background">
      <div dir="ltr" className="black-background-container">
        <P1>
          <T1>Regulamento - Concurso Cultural Missão Cidade Azul</T1>
        </P1>

        <P6>&nbsp;</P6>
        <P13>
          <T2>
            1. O Concurso Cultural Missão Cidade Azul (“Concurso”) é voltado a qualquer pessoa
            física, usuário da plataforma Missão Cidade Azul (“Participantes”) localizado na cidade
            de Guaratinguetá, São Paulo, Brasil, e visa premiar os autores dos vídeos de maior
            qualidade e envolvimento enviados entre 17 de Outubro de 2023 e 04 de Dezembro de 2023.
          </T2>
        </P13>

        <P6>&nbsp;</P6>
        <P13>
          <T2>
            2. O Concurso é válido para todos os usuários da plataforma Missão Cidade Azul dentro da
            cidade de Guaratinguetá, São Paulo, Brasil.
          </T2>
        </P13>

        <P6>&nbsp;</P6>
        <P13>
          <T2>
            3. Para participar do Concurso, o Participante deverá estar inscrito na plataforma da
            Missão Cidade Azul e submeter vídeos que demonstrem a realização das missões do jogo,
            conforme as instruções fornecidas na plataforma, durante o período de 17 de Outubro de
            2023 e 04 de Dezembro de 2023.
          </T2>
        </P13>

        <P6>&nbsp;</P6>
        <P13>
          <T2>
            4. Os vídeos poderão ser enviados através da plataforma Missão Cidade Azul, seguindo as
            instruções de submissão disponíveis no site.
          </T2>
        </P13>

        <P6>&nbsp;</P6>
        <P13>
          <T2>
            5. Os participantes poderão participar do Concurso enviando apenas 1 vídeo para cada
            missão, desde que cada vídeo seja distinto e atenda aos critérios estabelecidos neste
            regulamento.
          </T2>
        </P13>

        <P6>&nbsp;</P6>
        <P13>
          <T2>
            6. Uma comissão julgadora formada por membros da equipe da Missão Cidade Azul escolherá
            os vídeos mais qualificados dentre todos os Participantes.
          </T2>
        </P13>

        <P6>&nbsp;</P6>
        <P13>
          <T2>
            7. O Participante vencedor será premiado com um iPhone 12 Apple 64GB Azul Tela de 6,1”,
            Câmera Dupla de 12MP, iOS, que será entregue em até 60 dias após a data de divulgação do
            resultado.
          </T2>
        </P13>

        <P6>&nbsp;</P6>
        <P13>
          <T2>
            8. A escolha dos vencedores será realizada no dia 15 de Dezembro de 2024 e a divulgação
            dos vencedores será feita através do site da Missão Cidade Azul e em suas redes sociais.
          </T2>
        </P13>

        <P6>&nbsp;</P6>
        <P13>
          <T2>
            9. São proibidos, e não serão aceitos ou válidos para participação no Concurso, os
            vídeos que: (i) possuírem conteúdo imoral ou ilícito; (ii) não forem enviados através da
            plataforma; ou (iii) cuja autenticidade não puder ser comprovada.
          </T2>
        </P13>

        <P6>&nbsp;</P6>
        <P13>
          <T2>
            10. A Missão Cidade Azul entrará em contato até 5 (cinco) dias após a escolha do
            vencedor, utilizando-se dos dados cadastrados na plataforma, para combinar a entrega do
            prêmio.
          </T2>
        </P13>

        <P6>&nbsp;</P6>
        <P13>
          <T2>
            11. O prêmio será enviado ao endereço do Participante conforme informado posteriormente
            pelo mesmo após contato.
          </T2>
        </P13>

        <P6>&nbsp;</P6>
        <P13>
          <T2>
            12. A Missão Cidade Azul não assumirá nenhuma responsabilidade em decorrência de
            informações incorretas fornecidas pelos Participantes.
          </T2>
        </P13>

        <P6>&nbsp;</P6>
        <P13>
          <T2>
            13. O prêmio é intransferível, não poderá ser convertido em dinheiro, transferido para
            terceiros ou trocado por outro produto.
          </T2>
        </P13>
        <P6>&nbsp;</P6>
        <P13>
          <T2>
            14. Os Participantes autorizam de forma não onerosa a utilização do seu nome, seu vídeo,
            sua imagem e/ou som de voz na Internet e na mídia existente ou que venha a ser
            futuramente criada, sem qualquer ônus para a Missão Cidade Azul.
          </T2>
        </P13>

        <P6>&nbsp;</P6>
        <P13>
          <T2>
            15. Todos os Participantes concordam e cedem, antecipadamente, de forma total e não
            onerosa os direitos autorais patrimoniais de seus vídeos, para que a Missão Cidade Azul
            possa publicá-los ou usá-los de qualquer forma e para qualquer finalidade.
          </T2>
        </P13>

        <P6>&nbsp;</P6>
        <P13>
          <T2>
            16. Não poderão participar deste Concurso os empregados ou colaboradores da Missão
            Cidade Azul, bem como seus parentes de primeiro grau.
          </T2>
        </P13>

        <P6>&nbsp;</P6>
        <P13>
          <T2>
            17. Os Participantes são responsáveis pelos vídeos enviados, declarando-se desde já como
            legítimos e exclusivos titulares de seus direitos.
          </T2>
        </P13>

        <P6>&nbsp;</P6>
        <P13>
          <T2>18. Da escolha do vídeo vencedor não caberá recurso.</T2>
        </P13>

        <P6>&nbsp;</P6>
        <P13>
          <T2>
            19. Eventuais dúvidas podem ser esclarecidas através do canal de contato da Missão
            Cidade Azul por e-mail ou WhatsApp.
          </T2>
        </P13>
        <P6>&nbsp;</P6>
        <P13>
          <T2>
            20. Os Participantes declaram e afirmam ter conhecimento dos termos e condições acima
            dispostos e, através da participação no Concurso, manifestam sua concordância com todos
            os pontos apresentados acima, de maneira incondicional e irretratável.
          </T2>
        </P13>

        <P6>&nbsp;</P6>
        <P13>
          <T2>
            21. Este concurso não possui qualquer modalidade de sorte ou pagamentos pelos
            participantes, nem vinculação destes ou dos vencedores à aquisição ou uso de qualquer
            bem, direito ou serviço, promovido de acordo com a Lei n° 5768/71 e Decreto n°
            70.951/72, artigo 30.
          </T2>
        </P13>
        <P6>&nbsp;</P6>
        <P13>
          <T2>
            22. A Missão Cidade Azul está comprometida com o tratamento de dados com
            responsabilidade e transparência conforme a Lei Federal nº13.709/2018, a Lei Geral de
            Proteção de Dados (LGPD). Os participantes do concurso autorizam o uso de seus dados
            pessoais para fins deste concurso.
          </T2>
        </P13>

        <NORMAL>&nbsp;</NORMAL>
      </div>
    </div>
  );
};
