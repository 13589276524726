import React from 'react';
import { Outlet } from 'react-router-dom';
import { PageContent } from './components/page-content/page-content';
import { PlayingProvider } from './context/playing';

function Public() {
  return (
    <React.Fragment>
      <PlayingProvider>
        <PageContent>
          <Outlet />
        </PageContent>
      </PlayingProvider>
      {/* <PortraitDenier /> */}
    </React.Fragment>
  );
}

export default Public;
