import * as React from 'react';
import PageTitle from '../../common/PageTitle';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import jwt from 'jwt-decode';
import { emailConfirmationTeacher } from '../../../service';
import { Paragraph } from '../../common/Paragraph';

const TeacherEmailConfirmation = () => {
  const navigate = useNavigate();
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const [redirectPainelProfessor, setRedirectPainelProfessor] = React.useState<boolean>(false);
  const [showError, setShowError] = React.useState<boolean>(false);

  useEffect(() => {
    emailConfirmationTeacher(urlParams.get('email') ?? '', urlParams.get('code') ?? '')
      .then((data) => {
        if (localStorage.getItem('teacher-token')) {
          localStorage.clear();
        }

        const token_decoded: any = jwt(data.data.token);

        localStorage.setItem('teacher', 'true');
        localStorage.setItem('teacher-token', data.data.token);
        localStorage.setItem('email', token_decoded.email);
        localStorage.setItem('avatarName', token_decoded.name);
        window.dispatchEvent(new Event('login'));
        setRedirectPainelProfessor(true);
        setShowError(false);
      })
      .catch((_err) => {
        console.error({ _err });
        setShowError(true);
      });
  });

  if (redirectPainelProfessor) {
    navigate('/painelprofessor');
  }

  return (
    <div className="flex h-full w-full flex-1">
      {showError ? (
        <Paragraph className="mt-20 px-10 text-3xl font-semibold leading-relaxed text-red-600">
          Não foi possível realizar a confirmação do email, verifique se o email já foi confirmado
          anteriormente.
        </Paragraph>
      ) : (
        <></>
      )}
      <PageTitle value="Confirmação de email" />
    </div>
  );
};

export default TeacherEmailConfirmation;
