import React from 'react';
import PageMargin from '../../components/page-margin/page-margin';
import { Paragraph } from '../../components/common/Paragraph';
import { EmphasisTitle } from '../../components/common/EmphasisTitle';

export const StepByStep: React.FC = () => {
  return (
    <PageMargin>
      <div className="flex flex-1 flex-col gap-10 p-10 font-[Raleway]">
        <h1 className="text-4xl font-bold">Passo a Passo</h1>
        <Paragraph>A criação de conta possui três etapas:</Paragraph>
        <ul className="ml-8 list-disc">
          <li className="text-2xl leading-relaxed md:text-3xl md:leading-relaxed">
            <b>Criar Conta: </b>login e senha
          </li>
          <li className="text-2xl leading-relaxed md:text-3xl md:leading-relaxed">
            <b>Criar Perfil: </b>nome do jogador, nome do avatar, código de turma (opcional para uso
            de professores) e escolha do avatar
          </li>
          <li className="text-2xl leading-relaxed md:text-3xl md:leading-relaxed">
            <b>Escolher Cidade: </b>escolher em qual cidade vai jogar
          </li>
        </ul>
        <div className="flex flex-row gap-8">
          <img
            className="w-2/6 border-none object-contain sm:border"
            src="img/step-by-step/player1.jpg"
            alt="step1"
          />
          <Paragraph>
            O acesso ao jogador está na página inicial da plataforma, no canto superior direito.
          </Paragraph>
        </div>
        <EmphasisTitle className="text-2xl sm:text-2xl md:text-3xl">Criar Conta</EmphasisTitle>
        <div className="flex flex-col gap-8 sm:flex-row">
          <img
            className="w-full border object-contain sm:w-3/6"
            src="img/step-by-step/player2.jpg"
            alt="step2"
          />
          <Paragraph>
            Para jogar, o jogador precisa criar um cadastro, que pode ser por email e senha. Se
            tiver conta do Google ou Tik Tok, pode usar essas contas para fazer login.
          </Paragraph>
        </div>
        <div className="flex flex-row gap-8">
          <img
            className="h-auto w-2/6 border-none object-contain sm:border"
            src="img/step-by-step/player3.jpg"
            alt="step3"
          />
          <Paragraph>
            No caso de que o cadastro seja feito por email, o jogador receberá um email de
            confirmação de cadastro.
          </Paragraph>
        </div>
        <EmphasisTitle className="text-2xl sm:text-2xl md:text-3xl">Criar Perfil</EmphasisTitle>
        <div className="flex flex-col gap-8 sm:flex-row">
          <img
            className="w-full border object-contain sm:w-3/6"
            src="img/step-by-step/player4.jpg"
            alt="step4"
          />
          <Paragraph>
            Depois de criar login e senha, o jogador será levado à tela de Perfil. Na tela de Perfil
            o jogador deve inserir seu nome, criar um nome para o avatar, colocar o Código de Turma
            e escolher uma opção de avatar.
          </Paragraph>
        </div>
        <EmphasisTitle className="text-2xl sm:text-2xl md:text-3xl">
          Inserir Código de Turma
        </EmphasisTitle>
        <Paragraph>
          O Código de Turma deve ser fornecido pelo professor, quando o jogo for utilizado pelos
          professores.
          <br />O código de turma não é obrigatório no momento de criação da conta e, caso for
          usado, pode ser inserido posteriormente.
        </Paragraph>
        <EmphasisTitle className="text-2xl sm:text-2xl md:text-3xl">Alterar Perfil</EmphasisTitle>
        <div className="flex flex-col gap-8 sm:flex-row">
          <img
            className="w-full border object-contain sm:w-3/6"
            src="img/step-by-step/player5.jpg"
            alt="step5"
          />
          <Paragraph>
            A qualquer momento, depois de ter criado a conta, o jogador pode alterar seu nome, o
            nome de seu avatar e o Código de Turma. Para fazer isso, o jogador deve clicar no ícone
            de conta, no menu superior direito, no item Alterar Perfil.
          </Paragraph>
        </div>
        <EmphasisTitle className="text-2xl sm:text-2xl md:text-3xl">
          Escolher a Cidade
        </EmphasisTitle>
        <div className="flex flex-col gap-8 sm:flex-row">
          <img
            className="w-full border object-contain sm:w-3/6"
            src="img/step-by-step/player6.jpg"
            alt="step6"
          />
          <Paragraph>
            Depois de passar pela tela de Perfil, o jogador será levado à tela de Escolher Cidade. O
            jogador precisa escolher uma cidade e pode escolher qualquer uma das cidades
            apresentadas no mapa.
          </Paragraph>
        </div>
        <EmphasisTitle className="text-2xl sm:text-2xl md:text-3xl">Alterar a Cidade</EmphasisTitle>
        <div className="flex flex-col gap-8 sm:flex-row">
          <img
            className="w-full border object-contain sm:w-3/6"
            src="img/step-by-step/player7.jpg"
            alt="step7"
          />
          <div>
            <Paragraph className="mb-4">
              A qualquer momento, depois de ter criado a conta, o jogador pode alterar a cidade onde
              está jogando. Para fazer isso, o jogador deve clicar no ícone de conta, no menu
              superior direito, no item Alterar Cidade.
            </Paragraph>
            <Paragraph>
              <b>Obs: </b>Se o jogador alterar a cidade, ele começa um novo jogo.
            </Paragraph>
          </div>
        </div>
        <EmphasisTitle className="text-2xl sm:text-2xl md:text-3xl">Alterar Senha</EmphasisTitle>
        <div className="flex flex-col gap-8 sm:flex-row">
          <img
            className="w-full border object-contain sm:w-3/6"
            src="img/step-by-step/player8.jpg"
            alt="step8"
          />
          <Paragraph>
            Caso o jogador esquecer a senha de acesso ou sentir a necessidade de alterá-la, é só
            clicar em Esqueceu sua senha.
          </Paragraph>
        </div>
        <div className="flex flex-row gap-8">
          <img
            className="w-2/6 border object-contain"
            src="img/step-by-step/player9.jpg"
            alt="step9"
          />
          <Paragraph>Neste caso, o jogador receberá um email de alteração de senha.</Paragraph>
        </div>
      </div>
    </PageMargin>
  );
};
