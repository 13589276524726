interface EvaluationsListProps {
  justifyBetween?: boolean;
  evaluations: {
    content?: boolean;
    duration?: boolean;
    originality?: boolean;
  };
}

export const defaultEvaluationSRC = '/img/evaluations/denied.png';

export const EvaluationsList = ({
  evaluations = {
    content: false,
    duration: false,
    originality: false,
  },
  justifyBetween = true,
}: EvaluationsListProps) => {
  return (
    <div className={`flex w-full gap-3 ${justifyBetween && 'justify-between'}`}>
      <div className="flex items-center gap-2 text-2xl font-semibold">
        <img
          className="h-10"
          src={evaluations?.content ? '/img/evaluations/content.png' : defaultEvaluationSRC}
          alt="Avaliação"
        />
        Conteúdo
      </div>
      <div className="flex items-center gap-2 text-2xl font-semibold">
        <img
          className="h-10"
          src={evaluations?.duration ? '/img/evaluations/duration.png' : defaultEvaluationSRC}
          alt="Avaliação"
        />
        Duração
      </div>
      <div className="flex items-center gap-2 text-2xl font-semibold">
        <img
          className="h-10"
          src={evaluations?.originality ? '/img/evaluations/originality.png' : defaultEvaluationSRC}
          alt="Avaliação"
        />
        Originalidade
      </div>
    </div>
  );
};
