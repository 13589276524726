import React, { useCallback, useEffect, useState } from 'react';
import teacherTokenDidNotExpire from '../../util/teacherTokenDidNotExpire';
import tokenDidNotExpire from '../../util/tokenDidNotExpire';
import verifyTeacherRoute from '../../util/verifyTeacherRoute';
import { useLocation, useNavigate } from 'react-router-dom';

interface ProtectedRouteProps {
  children?: React.ReactNode;
}

const ProtectedRoute = (props: ProtectedRouteProps) => {
  const navigate = useNavigate();
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const location = useLocation();

  const routes = React.useMemo(() => {
    return {
      user: [
        '/start',
        '/choose-avatar',
        '/choose-city',
        '/journal',
        '/video-gallery',
        '/game',
        // "/public-video-gallery",
      ],
      admin: ['/superadmin-dashboard'],
    };
  }, []);

  const removeLocalStorageItems = (items: string[]) => {
    items.forEach((item) => {
      if (localStorage.getItem(item)) {
        localStorage.removeItem(item);
      }
    });
  };

  const checkTeacherToken = useCallback(() => {
    let teacherToken = localStorage.getItem('teacher-token');
    if (!teacherToken || teacherToken === 'undefined' || !teacherTokenDidNotExpire()) {
      setIsLoggedIn(false);
      removeLocalStorageItems(['teacher-token']);
      return navigate('/login-professor');
    }
    setIsLoggedIn(true);
  }, [navigate]);

  const checkNormalUserToken = useCallback(() => {
    const userToken = localStorage.getItem('token');
    if (!userToken || userToken === 'undefined' || !tokenDidNotExpire()) {
      setIsLoggedIn(false);
      localStorage.clear();
      return navigate(routes.admin.includes(location.pathname) ? '/login-admin' : '/login');
    }
    setIsLoggedIn(true);
  }, [navigate, location.pathname, routes.admin]);

  const checkUserToken = useCallback(() => {
    if (verifyTeacherRoute(location.pathname)) {
      checkTeacherToken();
    } else {
      checkNormalUserToken();
    }
  }, [location.pathname, checkTeacherToken, checkNormalUserToken]);

  useEffect(() => {
    checkUserToken();
  }, [isLoggedIn, checkUserToken]);

  return <React.Fragment>{isLoggedIn ? props.children : null}</React.Fragment>;
};

export default ProtectedRoute;
