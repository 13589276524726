import React, { createContext, useContext, useState } from 'react';

interface IPlayingContextType {
  isPlaying: boolean;
  setIsPlaying: React.Dispatch<React.SetStateAction<boolean>>;
  isWatching: boolean;
  setIsWatching: React.Dispatch<React.SetStateAction<boolean>>;
  origin: string;
  setOrigin: React.Dispatch<React.SetStateAction<string>>;
}

const PlayingContext = createContext<IPlayingContextType | undefined>(undefined);
export const PlayingProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [isPlaying, setIsPlaying] = useState<boolean>(false);
  const [isWatching, setIsWatching] = useState<boolean>(false);
  const [origin, setOrigin] = useState<string>('missaocidadeazul');

  return (
    <PlayingContext.Provider
      value={{ isPlaying, setIsPlaying, isWatching, setIsWatching, origin, setOrigin }}
    >
      {children}
    </PlayingContext.Provider>
  );
};
export const usePlaying = (): IPlayingContextType => {
  const context = useContext(PlayingContext);
  if (!context) {
    throw new Error('usePlaying must be used within a PlayingProvider');
  }
  return context;
};
