import React from 'react';
import PageMargin from '../../components/page-margin/page-margin';
import { EmphasisTitle } from '../../components/common/EmphasisTitle';
import { Paragraph } from '../../components/common/Paragraph';

export const AboutGames: React.FC = () => {
  return (
    <PageMargin>
      <div className="flex flex-1 flex-col gap-6 p-10 font-[Raleway]">
        <h1 className="text-4xl font-bold">Sobre os Jogos</h1>
        <EmphasisTitle className="font-black sm:text-3xl">Ciclo dos Jogos</EmphasisTitle>
        <Paragraph>
          Os jogos são lançados mensalmente em ciclos semestrais Na cidade virtual os jogadores
          respondem a desafios Depois são convidados a realizar missões no mundo real. que irão
          comprovar com videos enviados a plataforma, Os melhores jogadores são recompensados a cada
          ciclo.
        </Paragraph>
        <EmphasisTitle className="text-2xl sm:text-2xl md:text-3xl">
          1º Ciclo de Jogos - de 15 de Abril a 16 de Julho de 2024
        </EmphasisTitle>
        <Paragraph>Durante o primeiro semestre de 2024, lançaremos 3 jogos.</Paragraph>
        <ul className="ml-8 list-disc">
          <li className="mb-2 text-2xl leading-relaxed md:text-3xl md:leading-relaxed">
            Reuso Água
          </li>
          <li className="mb-2 text-2xl leading-relaxed md:text-3xl md:leading-relaxed">
            Historia do Saneamento
          </li>
          <li className="text-2xl leading-relaxed md:text-3xl md:leading-relaxed">
            Resíduos Orgânicos
          </li>
        </ul>
        <Paragraph>
          No final do 1º ciclo vamos premiar os 3 melhores jogadores de qualquer um dos jogos (não é
          a somatória da pontuação dos jogos).
        </Paragraph>
        <Paragraph>
          Os melhores jogadores serão os que mais pontuarem na cidade virtual e com o vídeo enviado
          essa pontuação aparece na galeria do jogador. A Galeria do Jogador soma os pontos dos
          desafios da cidade virtual aos pontos dos vídeos enviados.
        </Paragraph>
        <img src="img/about-games/first-cycle.jpg" alt="first-cycle" />
        <EmphasisTitle className="text-2xl sm:text-2xl md:text-3xl">
          2º Ciclo de Jogos - de 15 de Agosto a 15 de Novembro de 2024
        </EmphasisTitle>
        <p className="text-2xl leading-relaxed md:text-3xl md:leading-relaxed">
          Durante o segundo semestre de 2024, lançaremos 1 jogo.
        </p>
        <ul className="ml-8 list-disc">
          <li className="text-2xl leading-relaxed md:text-3xl md:leading-relaxed">
            Resíduos Recicláveis
          </li>
        </ul>
        <p className="text-2xl leading-relaxed md:text-3xl md:leading-relaxed">
          No final do 2º ciclo vamos premiar os 3 melhores jogadores de qualquer um dos jogos (não é
          a somatória da pontuação dos jogos).
        </p>
        <p className="text-2xl leading-relaxed md:text-3xl md:leading-relaxed">
          Os melhores jogadores serão os que mais pontuarem na cidade virtual e com o vídeo enviado
          essa pontuação aparece na galeria do jogador. A Galeria do Jogador soma os pontos dos
          desafios da cidade virtual aos pontos dos vídeos enviados.
        </p>
        <img src="img/about-games/second-cycle.jpg" alt="second-cycle" />
        <EmphasisTitle className="text-2xl sm:text-2xl md:text-3xl">
          Avaliação dos vídeos
        </EmphasisTitle>
        <Paragraph>
          Nós fazemos a curadoria dos vídeos e damos a pontuação levando em consideração três
          fatores: conteúdo, duração e originalidade.
        </Paragraph>
        <div className="flex flex-col gap-4">
          <div className="flex flex-row items-center gap-4">
            <img
              className="h-10 w-10 md:h-12 md:w-12"
              src="img/icons/blue-check.png"
              alt="content"
            />
            <Paragraph>
              <b>Conteúdo: </b>Se o vídeo responde à missão proposta.
            </Paragraph>
          </div>
          <div className="flex flex-row items-center gap-4">
            <img
              className="h-10 w-10 md:h-12 md:w-12"
              src="img/icons/green-check.png"
              alt="duration"
            />
            <Paragraph>
              <b>Duração: </b>Se o vídeo está próximo de 1 minuto.
            </Paragraph>
          </div>
          <div className="flex flex-row items-center gap-4">
            <img
              className="h-10 w-10 md:h-12 md:w-12"
              src="img/icons/pink-check.png"
              alt="originality"
            />
            <Paragraph>
              <b>Originalidade: </b>Se o vídeo é divertido, cativante, estimulante.
            </Paragraph>
          </div>
        </div>
        <EmphasisTitle className="text-2xl sm:text-2xl md:text-3xl">
          Jornada do Jogador
        </EmphasisTitle>
        <Paragraph>
          Cada jogo tem a duração de um mês, e o jogo é destravado de sete em sete dias.
        </Paragraph>
        <img src="img/about-games/mensal-game.jpg" alt="second-cycle" />
        <Paragraph>
          <b>Funciona assim: </b>o jogador começa na primeira semana da cidade virtual. Enquanto vai
          jogando, o jogador acumula pontos, que são ganhos quando ele coleta itens pela cidade
          virtual e quando resolve os desafios propostos. Os pontos são convertidos em tokens.
        </Paragraph>
        <Paragraph>
          Quando ele termina o desafio da primeira semana, o jogo avisa que ele precisará voltar em
          7 dias para jogar novamente.
        </Paragraph>
        <Paragraph>
          Após 7 dias, é habilitada a segunda semana e o jogador pode jogar novamente.
        </Paragraph>
        <Paragraph>
          Enquanto vai jogando, o jogador acumula pontos que são ganhos quando ele coleta itens pela
          cidade virtual e quando resolve os desafios propostos. Os pontos são convertidos em
          tokens.
        </Paragraph>
        <Paragraph>
          Após 7 dias, é habilitada a terceira semana e o jogador pode jogar novamente.
        </Paragraph>
        <Paragraph>
          Enquanto vai jogando, o jogador acumula pontos que são ganhos quando ele coleta itens pela
          cidade virtual e quando resolve os desafios propostos. Os pontos são convertidos em
          tokens.
        </Paragraph>
        <Paragraph>
          Na terceira semana é apresentada a missão do mundo real, que é realizada através de um
          vídeo que o jogador precisa produzir. Ele terá até o final do ciclo para enviar o vídeo.
        </Paragraph>
        <EmphasisTitle className="text-2xl sm:text-2xl md:text-3xl">
          Missão do Mundo Real
        </EmphasisTitle>
        <Paragraph>
          Depois de completada a terceira semana, o jogo apresenta ao jogador a missão que ele
          precisa realizar no mundo real. Ele fará isso através da Galeria do Jogador, enviando para
          lá um vídeo produzido por ele mesmo.
        </Paragraph>
        <a
          className="cursor-pointer text-2xl font-black text-semantic sm:text-3xl"
          href="https://d2mkrcqum113x3.cloudfront.net/Kit+Figurinhas.zip"
          target={'blank'}
        >
          Você pode usar as nossas figurinhas para fazer seus vídeos! Clique aqui e baixe as
          figurinhas.
        </a>
        <EmphasisTitle className="text-2xl sm:text-2xl md:text-3xl">Premiações</EmphasisTitle>

        <div className="grid w-full grid-cols-1 gap-8 sm:grid-cols-2 sm:gap-16">
          <div>
            <img className="w-full" src="img/about-games/nft-player.jpg" alt="nft" />
          </div>
          <div className="flex flex-col">
            <Paragraph className="hidden sm:block">
              Aos melhores vídeos de cada ciclo será concedido um certificado NFT que dá direito a
              um kit de criador de conteúdo, que inclui um ring light, um bastão de led e um
              microfone. Além do kit, cada vencedor ganhará uma consultoria de criação de conteúdo.
            </Paragraph>
            <div className="mt-0 w-full border border-gray-400 sm:mt-8 sm:w-5/6">
              <img className="w-full" src="img/about-games/kit-tiktok.jpg" alt="kit" />
            </div>
          </div>
        </div>
        <Paragraph className="inline sm:hidden">
          Aos melhores vídeos de cada ciclo será concedido um certificado NFT que dá direito a um
          kit de criador de conteúdo, que inclui um ring light, um bastão de led e um microfone.
          Além do kit, cada vencedor ganhará uma consultoria de criação de conteúdo.
        </Paragraph>
        <EmphasisTitle className="text-2xl sm:text-2xl md:text-3xl">
          A Galeria do Jogador
        </EmphasisTitle>
        <Paragraph>
          A Galeria do Jogador é a página pessoal do jogador, onde ele acompanha o status de seus
          jogos, pontos e tokens, aprovações, etc.
        </Paragraph>
        <Paragraph>
          A Galeria do Jogador apresenta cada jogo em um quadro separado. Se o jogo ainda não foi
          lançado, o quadro aparece com cor clarinha. Quando o jogo tiver sido lançado, ele aparece
          com cores fortes. 
        </Paragraph>
        <Paragraph>
          A Galeria do Jogador é a página pessoal do jogador, onde ele acompanha o status de seus
          jogos, pontos e tokens, aprovações, etc.
        </Paragraph>
        <Paragraph>
          Dentro desse quadro são apresentadas informações como a cidade, o mundo, o jogo, a missão,
          o status do vídeo enviado, a pontuação da cidade virtual, a pontuação do vídeo e a
          pontuação total, que é a soma das duas anteriores.
        </Paragraph>
        <Paragraph>
          Quando está na cidade virtual, o jogador pode acessar a Galeria do Jogador apertando a
          tecla ESC ou a tecla P, e lá poderá acompanhar o status de seu jogo.
        </Paragraph>
        <EmphasisTitle className="text-2xl sm:text-2xl md:text-3xl">
          Dependendo do status do jogo, o jogador verá diferentes informações:
        </EmphasisTitle>
        <ul className="ml-8 list-disc">
          <li className="mb-8 text-2xl leading-relaxed md:text-3xl md:leading-relaxed">
            <b>Status 1: </b>o jogador ainda não jogou a primeira semana e não acumulou tokens.
          </li>
          <li className="mb-8 text-2xl leading-relaxed md:text-3xl md:leading-relaxed">
            <b>Status 2: </b> o jogador terminou a primeira semana e acumulou 2 tokens de bronze.
            <br />
            Obs: se o jogador tiver jogado metade da primeira semana e acumulado um token, aparecerá
            apenas um token de bronze.
          </li>
          <li className="mb-8 text-2xl leading-relaxed md:text-3xl md:leading-relaxed">
            <b>Status 3: </b>o jogador terminou a segunda semana e acumulou 2 tokens de prata.
            <br /> Obs: se o jogador tiver jogado metade da primeira semana e acumulado um token,
            aparecerá apenas um token de prata.
          </li>
          <li className="mb-8 text-2xl leading-relaxed md:text-3xl md:leading-relaxed">
            <b>Status 4: </b>o jogador terminou a terceira semana e acumulou 2 tokens de ouro. Neste
            momento, o campo para envio do vídeo da missão aparece ao jogador.
            <br /> Logo abaixo do vídeo, aparece um botão com um link para ver o vídeo de
            apresentação da missão novamente e outro com um link para aprender como inserir o vídeo.
            <br />
            Obs: se o jogador tiver jogado metade da primeira semana e acumulado um token, aparecerá
            apenas um token de ouro, e neste caso, não é apresentado o campo de envio de vídeo.
          </li>
          <li className="mb-8 text-2xl leading-relaxed md:text-3xl md:leading-relaxed">
            <b>Status 5: </b>após o envio do vídeo, o jogador precisa aguardar a aprovação ou
            reprovação pela plataforma.
            <br /> Obs: o jogador pode escolher apagar o vídeo clicando na lixeira no canto superior
            direito do vídeo.
          </li>
          <li className="mb-8 text-2xl leading-relaxed md:text-3xl md:leading-relaxed">
            <b>Status 6: </b>após o envio do vídeo, existe a possibilidade do jogador apagar o vídeo
            para poder enviar outro. Se ele fizer isso, o campo para envio de vídeo é aberto
            novamente, junto com o aviso de que o jogador apagou o vídeo.
          </li>
          <li className="mb-8 text-2xl leading-relaxed md:text-3xl md:leading-relaxed">
            <b>Status 7: </b>após a aprovação do vídeo pela plataforma, o status altera para
            aprovado. <br />
            Neste momento, a pontuação do vídeo é apresentada, assim como a pontuação total (soma da
            pontuação virtual + a pontuação do vídeo). Os bullets de conteúdo, duração e
            originalidade também aparecem com as avaliações feitas pela plataforma para o vídeo.
          </li>
          <li className="mb-8 text-2xl leading-relaxed md:text-3xl md:leading-relaxed">
            <b>Status 8: </b>o vídeo pode ser reprovado pela plataforma. Neste caso, o status altera
            para reprovado. O jogador é convidado a apagar o vídeo e enviar um novo, cumprindo os
            critérios.
            <br /> Neste momento, a pontuação do vídeo é apresentada, assim como a pontuação total
            (soma da pontuação virtual + a pontuação do vídeo). Os bullets de conteúdo, duração e
            originalidade também aparecem com as avaliações feitas pela plataforma para o vídeo.
          </li>
          <li className="text-2xl leading-relaxed sm:mb-4 md:text-3xl md:leading-relaxed">
            <b>Status 9: </b>o jogador ainda não jogou a primeira semana e não acumulou tokens.
          </li>
        </ul>
        <EmphasisTitle className="text-2xl sm:text-2xl md:text-3xl">A Galeria Geral</EmphasisTitle>
        <Paragraph>
          Quando o vídeo é aprovado pela plataforma, ele passa a fazer parte também da Galeria
          Geral. A Galeria Geral apresenta os vídeos de todos os jogadores de todas as cidades e
          clientes e pode ser vista por todos os jogadores. A Galeria Geral possui filtros que podem
          ser usados para filtrar os vídeos por Empresa, Cidade, Mundo, Jogo, Missão (vídeo) e
          Avaliação.
          <br /> O filtro Avaliação está relacionado à pontuação de cada jogo, ou seja, ao total de
          pontos (soma dos pontos na cidade virtual + pontuação do vídeo).
        </Paragraph>
        <EmphasisTitle className="text-2xl sm:text-2xl md:text-3xl">
          Classificação feita pelo filtro Avaliação:
        </EmphasisTitle>
        <ul className="ml-8 list-disc">
          <li className="mb-2 text-2xl leading-relaxed md:text-3xl md:leading-relaxed">
            <b>Está ótimo!!!</b> = 8.801 a 10.000 pontos
          </li>
          <li className="mb-2 text-2xl leading-relaxed md:text-3xl md:leading-relaxed">
            <b>Bom ;)</b> = 7.701 a 8.800 pontos
          </li>
          <li className="mb-2 text-2xl leading-relaxed md:text-3xl md:leading-relaxed">
            <b>Cumpriu :)</b> = 6.700 a 7.700 pontos
          </li>
        </ul>
      </div>
    </PageMargin>
  );
};
