import * as React from "react";
import { Fragment } from "react";
import { cn } from "../../util/cn";

type Props = React.HTMLAttributes<HTMLInputElement> & {
    placeholder: string;
    style?: React.CSSProperties;
    onChange?: React.ChangeEventHandler<HTMLInputElement>;
    type?: string;
    value?: string;
    id?: string;
    name?: string;
    disabled?: boolean;
};

const TextInput = ({
    placeholder,
    style,
    onChange,
    type,
    value,
    id,
    name,
    disabled = false,
    className,
    ...rest
}: Props) => {
    const [internalValue, setInternalValue] = React.useState<string>(value ?? "");
    const [showPassword, setShowPassword] = React.useState<boolean>(false);

    const handleChange: React.ChangeEventHandler<HTMLInputElement> = (e) => {
        setInternalValue(e.target.value);
        onChange ? onChange(e) : (() => {})();
    };

    React.useEffect(() => {
        setInternalValue(value ?? "");
    }, [value]);

    React.useEffect(() => {
        if (type !== "password") return;

        if (showPassword) {
            if (id) document.getElementById(id)?.setAttribute("type", "text");
        } else {
            if (id) document.getElementById(id)?.setAttribute("type", "password");
        }
    }, [showPassword]);

    return (
        <div className={cn("text-input w-4/5", className)}>
            <input
                {...rest}
                type={type ?? "text"}
                name={name ?? ""}
                id={id ?? ""}
                value={internalValue ?? ""}
                placeholder={placeholder}
                style={style}
                onChange={handleChange}
                disabled={disabled}
            />

            {type === "password" ? (
                <div className="eye-icon" onClick={() => setShowPassword(!showPassword)}>
                    <img
                        id="eyeIconView"
                        src={`/img/${showPassword ? `closedEye_password` : `eye_password`}.png`}
                        alt=""
                    />
                </div>
            ) : (
                <></>
            )}
        </div>
    );
};

export default TextInput;
