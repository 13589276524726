import { ReactNode } from 'react';
import PageTitle from '../common/PageTitle';
import { cn } from '../../util/cn';

interface PageLayoutProps extends React.HTMLAttributes<HTMLDivElement> {
  children: ReactNode;
  pageTitle?: string;
  footer?: boolean;
  marginFromTitle?: boolean;
}

export const PageLayout = ({
  children,
  pageTitle,
  className,
  marginFromTitle = false,
  footer = true,
}: PageLayoutProps) => {
  return (
    <main className="w-full flex-1 text-neutral-900">
      {pageTitle && <PageTitle value={pageTitle} />}
      <div
        className={cn(
          `flex w-full flex-col gap-16 p-16 ${marginFromTitle ? 'mt-32' : ''}`,
          className,
        )}
      >
        {children}
      </div>
    </main>
  );
};
