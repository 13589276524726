import React from 'react';
import PageMargin from '../../components/page-margin/page-margin';
import { useNavigate } from 'react-router-dom';
import { Paragraph } from '../../components/common/Paragraph';
import { EmphasisTitle } from '../../components/common/EmphasisTitle';

export const TeacherGuide: React.FC = () => {
  const navigate = useNavigate();
  return (
    <PageMargin>
      <div className="flex flex-1 flex-col gap-10 p-10 font-[Raleway]">
        <h1 className="text-4xl font-bold">Guia do Professor</h1>
        <Paragraph>
          O professor pode utilizar o jogo como apoio para suas aulas ou como atividades
          extracurriculares e eletivas.
        </Paragraph>
        <Paragraph>Neste guia vamos falar sobre:</Paragraph>
        <ul className="ml-8 list-disc">
          <li className="mb-3 cursor-pointer text-2xl leading-relaxed md:text-3xl md:leading-relaxed">
            Como utilizar o conteúdo dos jogos
          </li>
          <li
            className="cursor-pointer text-2xl leading-relaxed md:text-3xl md:leading-relaxed"
            onClick={() => navigate('/step-by-step-teacher')}
          >
            Como utilizar o Painel do Professor
          </li>
        </ul>
        <EmphasisTitle className="text-2xl sm:text-2xl md:text-3xl">
          Como utilizar o conteúdo dos jogos
        </EmphasisTitle>
        <div className="grid grid-cols-1 gap-8 sm:grid-cols-2">
          <div>
            <img
              className="w-full border object-contain"
              src="img/teacher-guide/call.jpg"
              alt="call"
            />
          </div>
          <div>
            <EmphasisTitle className="mb-9 text-2xl sm:text-2xl md:text-3xl">
              Animação inicial - O Chamado
            </EmphasisTitle>
            <Paragraph>
              É uma animação que narra a história, apresentando a relação dos personagens dos jogos
              com as cidades e o desenrolar dos eventos que os afetam. Este vídeo estabelece uma
              conexão entre cultura e meio ambiente, através de personagens de origem
              afro-brasileira e indígena, que representam elementos da natureza e desempenham um
              papel crucial no equilíbrio da própria natureza. A animação é apresentada na página
              inicial da plataforma.
            </Paragraph>
          </div>
        </div>
        <Paragraph>
          O vídeo O Chamado tem um papel significativo, funcionando como uma ponte entre as
          atividades virtuais dos jogadores e ações no mundo real e contribui para uma conexão
          autêntica e respeitosa com essas tradições.
        </Paragraph>
        <Paragraph>
          O Chamado serve principalmente para convocar os jogadores a participarem de missões
          específicas fora do ambiente virtual. Por exemplo, pode incitar os jogadores a realizar
          tarefas ambientais como plantar árvores ou separar resíduos, promovendo práticas
          sustentáveis no cotidiano dos usuários. Essas atividades são projetadas para reforçar os
          conceitos e valores aprendidos no jogo, encorajando os jogadores a aplicar esses
          ensinamentos de forma prática e impactante na sua comunidade.
        </Paragraph>
        <Paragraph>
          Além de motivar a ação, o vídeo também ajuda a reforçar a narrativa e os temas da
          plataforma, integrando as lições do mundo virtual com compromissos tangíveis no mundo
          real, fortalecendo o engajamento e o impacto educacional da Missão Cidade Azul.
        </Paragraph>
        <Paragraph>
          <b>Sugestão: </b>O professor pode iniciar a jornada de jogos apresentando aos alunos o
          vídeo e os personagens.
        </Paragraph>
        <EmphasisTitle className="text-2xl sm:text-2xl md:text-3xl">Personagens</EmphasisTitle>
        <Paragraph>
          Os personagens desempenham um papel crucial, atuando como mentores que guiam e educam os
          jogadores dentro do ambiente virtual. Cada personagem é mentor de um mundo e representa
          diferentes culturas, especialmente povos indígenas e a cultura afro-brasileira, refletindo
          as relações dessas comunidades com o meio ambiente. Esses personagens são desenvolvidos
          por profissionais que também representam essas culturas, garantindo uma representação
          autêntica e respeitosa.
        </Paragraph>
        <Paragraph>
          Dentro dos jogos, que ocorrem em uma cidade virtual com temáticas ambientais, como O Mundo
          da Água, O Mundo do Saneamento e o Mundo dos Resíduos, os personagens aparecem como guias.
          Eles fornecem dicas e ensinamentos, ajudando os jogadores a navegar pelos desafios
          propostos e a coletar elementos necessários para avançar. Além disso, esses mentores
          também têm a função de ligar as atividades virtuais com ações no mundo real, incentivando
          os jogadores a realizar missões concretas, como separar resíduos ou plantar árvores.
        </Paragraph>
        <Paragraph>
          Essa interação não apenas enriquece a experiência lúdica, mas também promove uma
          conscientização sobre práticas sustentáveis e a importância da cultura e do meio ambiente
          na vida real. Os personagens, portanto, são essenciais para a dinâmica educativa e
          imersiva da plataforma.
        </Paragraph>
        <img
          className="w-full object-contain"
          src="img/teacher-guide/characters.jpg"
          alt="characters"
        />
        <div>
          <Paragraph className="mb-4">
            <b>Tuna: </b>Mentora do Mundo da Água e do jogo Reúso de Água
          </Paragraph>
          <a
            className="text-2xl font-normal underline md:text-3xl"
            href="https://www.youtube.com/watch?v=IaKvP9JAmw8&ab_channel=TheBlueCity"
            target="blank"
          >
            • Vídeo de Tuna
          </a>
        </div>

        <div>
          <Paragraph className="mb-4">
            <b>Okan: </b>Mentora do Mundo da Água e do jogo Economia de Água
          </Paragraph>
          <a
            className="text-2xl font-normal underline md:text-3xl"
            href="https://www.youtube.com/watch?v=aynfr3Kl6Rs&ab_channel=TheBlueCity"
            target="blank"
          >
            • Vídeo de Okan
          </a>
        </div>

        <div>
          <Paragraph className="mb-4">
            <b>Yapu: </b>Mentor do Mundo do Saneamento e dos jogos A História do Saneamento e
            Saneamento & Saúde
          </Paragraph>
          <a
            className="text-2xl font-normal underline md:text-3xl"
            href="https://www.youtube.com/watch?v=QuWWPVR_PoM&ab_channel=TheBlueCity"
            target="blank"
          >
            • Vídeo de Yapu
          </a>
        </div>

        <div>
          <Paragraph className="mb-4">
            <b>Amò: </b>Mentora do Mundo dos Resíduos e do jogo Resíduos Orgânicos
          </Paragraph>
          <a
            className="text-2xl font-normal underline md:text-3xl"
            href="https://www.youtube.com/watch?v=ZkzQvFkeZfo&ab_channel=TheBlueCity"
            target="blank"
          >
            • Vídeo de Amò
          </a>
        </div>

        <div>
          <Paragraph className="mb-4">
            <b>Ibiporã: </b>Mentor do Mundo dos Resíduos e do jogo Resíduos Recicláveis
          </Paragraph>
          <a
            className="text-2xl font-normal underline md:text-3xl"
            href="https://www.youtube.com/watch?v=gSs-1eb2EH0&ab_channel=TheBlueCity"
            target="blank"
          >
            • Vídeo de Ibiporã
          </a>
        </div>
        <EmphasisTitle className="text-2xl sm:text-2xl md:text-3xl">Mundos</EmphasisTitle>
        <Paragraph className="mb-4">
          Os mundos são elementos centrais que estruturam tanto a jogabilidade quanto os objetivos
          educacionais do jogo. Cada mundo dentro da plataforma é tematicamente focado em diferentes
          aspectos ambientais, permitindo aos jogadores explorar e aprender sobre questões
          específicas de sustentabilidade e gestão ambiental. Os principais mundos incluem O Mundo
          da Água, O Mundo do Saneamento e o Mundo dos Resíduos, cada um projetado para engajar os
          jogadores em temáticas cruciais para a compreensão e ação ambiental.
        </Paragraph>
        <Paragraph className="mb-4">
          Cada mundo aborda tópicos específicos que são vitais para a educação ambiental. Por
          exemplo:
        </Paragraph>
        <div className="grid grid-cols-1 gap-8 md:grid-cols-3">
          <div className="flex flex-col items-start gap-8 sm:flex-row md:flex-col">
            <img
              className="w-full object-contain sm:w-2/4 md:w-full"
              src="img/teacher-guide/water-world.jpg"
              alt="water-world"
            />
            <div className="flex flex-col gap-4">
              <EmphasisTitle className="text-2xl sm:text-2xl md:text-3xl">
                O Mundo da Água
              </EmphasisTitle>
              <Paragraph className="mb-4">
                O Mundo da Água ensina sobre o ciclo da água, a importância da conservação da água e
                as consequências da poluição hídrica.
              </Paragraph>
              <div>
                <Paragraph className="mb-4">Protagonistas Tuna e Okan</Paragraph>
                <a
                  className="text-2xl font-normal underline md:text-3xl"
                  href="https://www.youtube.com/watch?v=nh_XE7nO0HQ&ab_channel=TheBlueCity"
                  target="blank"
                >
                  • Vídeo do Mundo da Água
                </a>
              </div>
            </div>
          </div>
          <div className="flex flex-col items-start gap-8 sm:flex-row md:flex-col">
            <img
              className="w-full object-contain sm:w-2/4 md:w-full"
              src="img/teacher-guide/sanitation-world.jpg"
              alt="sanitation-world"
            />
            <div className="flex flex-col gap-4">
              <EmphasisTitle className="text-2xl sm:text-2xl md:text-3xl">
                O Mundo do Saneamento
              </EmphasisTitle>
              <Paragraph className="mb-4">
                O Mundo do Saneamento destaca a importância do tratamento adequado de esgoto,
                prevenção da contaminação e saúde pública.
              </Paragraph>
              <div>
                <Paragraph className="mb-4">Protagonista Yapu</Paragraph>
                <a
                  className="text-2xl font-normal underline md:text-3xl"
                  href="https://www.youtube.com/watch?v=tQE4ULke__E&ab_channel=TheBlueCity"
                  target="blank"
                >
                  • Vídeo do Mundo do Saneamento
                </a>
              </div>
            </div>
          </div>
          <div className="flex flex-col items-start gap-8 sm:flex-row md:flex-col">
            <img
              className="w-full object-contain sm:w-2/4 md:w-full"
              src="img/teacher-guide/waste-world.jpg"
              alt="waste-world"
            />
            <div className="flex flex-col gap-4">
              <EmphasisTitle className="text-2xl sm:text-2xl md:text-3xl">
                O Mundo dos Resíduos
              </EmphasisTitle>
              <Paragraph className="mb-4">
                O Mundo dos Resíduos ensina sobre compostagem, reciclagem, gestão de resíduos
                sólidos e práticas de descarte responsável.
              </Paragraph>
              <div>
                <Paragraph className="mb-4">Protagonista Yapu</Paragraph>
                <a
                  className="text-2xl font-normal underline md:text-3xl"
                  href="https://www.youtube.com/watch?v=RYIqaxg9QV4&ab_channel=TheBlueCity"
                  target="blank"
                >
                  • Vídeo do Mundo dos Resíduos
                </a>
              </div>
            </div>
          </div>
        </div>
        <Paragraph className="mb-4">
          <b>Sugestão: </b>O professor pode utilizar os mundos para introduzir os temas ambientais
          aos alunos.
        </Paragraph>
        <a
          className="cursor-pointer text-2xl font-black text-semantic md:text-3xl"
          href="https://d2mkrcqum113x3.cloudfront.net/narrativa_jogos.pdf"
        >
          Baixe aqui o PDF com todo o material de narrativa do jogo.
        </a>
        <EmphasisTitle className="text-2xl sm:text-2xl md:text-3xl">
          Jornada do Jogador
        </EmphasisTitle>
        <Paragraph className="mb-4">
          Cada jogo tem a duração de um mês, e o jogo é destravado de sete em sete dias. Com esta
          dinâmica o professor pode se programar, por exemplo, para jogar com os alunos uma vez por
          semana.
        </Paragraph>
        <p className="mb-4 text-2xl font-semibold leading-relaxed text-secondary md:text-3xl">
          Entenda melhor a Jornada do Jogador em Sobre os Jogos.
        </p>
        <EmphasisTitle className="text-2xl sm:text-2xl md:text-3xl">Premiações</EmphasisTitle>
        <div className="grid w-full grid-cols-1 gap-16 sm:grid-cols-2">
          <div>
            <img className="w-full" src="img/teacher-guide/teacher-nft.jpg" alt="nft" />
          </div>
          <div className="flex flex-col">
            <div>
              <EmphasisTitle className="mb-2 text-2xl sm:text-2xl md:text-3xl">
                Professores
              </EmphasisTitle>
              <Paragraph>
                Os professores também são premiados com o kit tik toker, formando uma dupla com o
                melhor aluno. Aos melhores vídeos de cada ciclo será concedido um certificado NFT
                que dá direito a um com um kit criador de conteúdo, que inclui um ring light, um
                bastão led e um microfone.
              </Paragraph>
            </div>
            <div className="mt-20 hidden w-5/6 border border-gray-400 sm:mt-8 sm:inline">
              <img className="w-full" src="img/about-games/kit-tiktok.jpg" alt="kit" />
            </div>
          </div>
        </div>
        <div className="grid w-full grid-cols-1 gap-16 sm:grid-cols-2">
          <div>
            <img className="w-full" src="img/teacher-guide/school-nft.jpg" alt="teacher-nft" />
          </div>
          <div className="flex flex-col gap-4">
            <EmphasisTitle className="text-2xl sm:text-2xl md:text-3xl">Escolas</EmphasisTitle>
            <Paragraph className="mb-4">
              Aos professores participantes de cada ciclo será concedido um certificado NFT de
              participação.
            </Paragraph>
          </div>
        </div>
      </div>
    </PageMargin>
  );
};
