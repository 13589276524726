import { ReactNode } from "react"
import { PageLayout } from "../../components/page-layout"

const Text = ({ children }: { children: ReactNode }) => {
    return (
        <p className="text-start text-3xl font-bold">
            {children}
        </p>
    )
}

export const CompetitionRules = () => {
    return (
        <PageLayout className="bg-neutral-950 text-neutral-50">
            <div className="flex flex-col gap-14 items-start">
                <h1 className="text-5xl font-bold">Regulamento - Concurso Cultural Missão Cidade Azul</h1>
                <Text>
                    1. O Concurso Cultural Missão Cidade Azul (“Concurso”) é voltado a qualquer pessoa física, usuário da plataforma Missão
                    Cidade Azul (“Participantes”) localizado na cidade de Guaratinguetá, São Paulo, Brasil, e visa premiar os autores dos
                    vídeos de maior qualidade e envolvimento enviados entre 02 de Outubro de 2023 e 11 de Dezembro de 2023.
                </Text>
                <Text>
                    2. O Concurso é válido para todos os usuários da plataforma Missão Cidade Azul dentro da cidade de Guaratinguetá, São
                    Paulo, Brasil.
                </Text>
                <Text>
                    3. Para participar do Concurso, o Participante deverá estar inscrito na plataforma da Missão Cidade Azul e submeter
                    vídeos que demonstrem a realização das missões do jogo, conforme as instruções fornecidas na plataforma, durante o
                    período de 02 de Outubro de 2023 e 11 de Dezembro de 2023.
                </Text>
                <Text>
                    4. Os vídeos poderão ser enviados através da plataforma Missão Cidade Azul, seguindo as instruções de submissão
                    disponíveis no site.
                </Text>
                <Text>
                    5. Os participantes poderão participar do Concurso enviando apenas 1 vídeo para cada missão, desde que cada vídeo
                    seja distinto e atenda aos critérios estabelecidos neste regulamento.
                </Text>
                <Text>
                    6. Uma comissão julgadora formada por membros da equipe da Missão Cidade Azul escolherá os vídeos mais
                    qualificados dentre todos os Participantes.
                </Text>
            </div>
        </PageLayout>
    )
}