import { FaQuestion } from 'react-icons/fa';
import { DropdownMenu } from '../dropdown-menu';
import { companies, cities, worlds, missions, ratings, games } from './options';
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from '../tooltip';
import { Input } from '../input';
import { FilterDisplay } from './filters-display';
import React from 'react';

export interface Filters {
  company?: string;
  city?: string;
  world?: string;
  mission?: string;
  rating?: string;
  class?: string;
  game?: string;
  period?: {
    start?: string;
    end?: string;
  };
}

interface VideoFiltersProps {
  filters: Filters;
  disableCharacterSearch?: boolean;
  setFilters: React.Dispatch<React.SetStateAction<Filters>>;
  enableRatingFilter?: boolean;
  enableCityFilter?: boolean;
  enableCompanyFilter?: boolean;
  enableGameFilter?: boolean;
  enableMissionFilter?: boolean;
  enableWorldFilter?: boolean;
  enablePeriodFilter?: boolean;
  hideClassFilter?: boolean;
  enableShowFiltered?: boolean;
  closePeriodFilter?: React.Dispatch<React.SetStateAction<boolean>>;
  disablePeriodFilter?: boolean;
}

export const FilterComponent = ({
  filters,
  setFilters,
  enableRatingFilter = false,
  enableCompanyFilter = false,
  disableCharacterSearch = false,
  enableCityFilter,
  enableGameFilter = true,
  enableMissionFilter = true,
  enableWorldFilter = true,
  enablePeriodFilter,
  hideClassFilter,
  enableShowFiltered = true,
  disablePeriodFilter = false,
}: VideoFiltersProps) => {
  const handleSetFilters = (key: string, value: string | object) => {
    setFilters((prev) => {
      return {
        ...prev,
        [key]: value,
      };
    });
  };
  const handleRemoveFilter = (key: string) => {
    setFilters((prev) => {
      return {
        ...prev,
        [key]: '',
      };
    });
  };

  const isFiltering = Object.values(filters).some((value) => value !== '');

  return (
    <>
      <div className="flex w-full flex-col gap-10">
        <div className="flex flex-col items-center gap-4 lg:flex-row lg:justify-between">
          <div className="flex flex-col items-center gap-4 md:flex-row">
            <span className="hidden text-3xl font-bold md:inline">Filtros:</span>
            {enablePeriodFilter && (
              <DropdownMenu
                label="Período"
                value={{ ...filters.period }}
                onChange={(value) => handleSetFilters('period', value)}
                type="date"
                disabled={disablePeriodFilter}
              />
            )}
            {enableCompanyFilter && (
              <DropdownMenu
                label="Empresa"
                value={filters.company || ''}
                options={companies}
                onChange={(value) => handleSetFilters('company', value.trim())}
                type="list"
              />
            )}
            {enableCityFilter && (
              <DropdownMenu
                parentState={filters.company}
                label="Cidade"
                value={filters.city || ''}
                options={cities}
                onChange={(value) => handleSetFilters('city', value.trim())}
                type="list"
              />
            )}
            {enableWorldFilter && (
              <DropdownMenu
                label="Mundo"
                value={filters.world || ''}
                options={worlds}
                onChange={(value) => handleSetFilters('world', value.trim())}
                type="list"
              />
            )}
            {enableGameFilter && (
              <DropdownMenu
                parentState={filters.world}
                label="Jogo"
                value={filters.game || ''}
                options={games}
                onChange={(value) => handleSetFilters('game', value.trim())}
                type="list"
              />
            )}
            {enableMissionFilter && (
              <DropdownMenu
                parentState={filters.game}
                label="Missão"
                value={filters.mission || ''}
                options={missions}
                onChange={(value) => handleSetFilters('mission', value.trim())}
                type="list"
              />
            )}
            {enableRatingFilter && (
              <DropdownMenu
                label="Avaliação"
                value={filters.rating || ''}
                options={ratings}
                onChange={(value) => handleSetFilters('rating', value.trim())}
                type="list"
              />
            )}
          </div>
          {!hideClassFilter && (
            <div className="-ml-16 flex gap-4 md:ml-0">
              <TooltipProvider>
                <Tooltip>
                  <TooltipTrigger>
                    <FaQuestion className="h-14 w-14 rounded-full border border-gray-400 bg-gray-200 p-4 text-neutral-500" />
                  </TooltipTrigger>
                  <TooltipContent className="max-w-72 rounded-2xl border border-gray-400 bg-[#E0E0E0] p-6 text-lg">
                    <p className="text-2xl font-medium leading-snug text-gray-500">
                      O Código de Turma é utilizado pelos professores para filtrar os jogos de suas
                      turmas.
                    </p>
                  </TooltipContent>
                </Tooltip>
              </TooltipProvider>
              <Input
                className="h-16 min-w-80 text-2xl font-medium md:w-56 md:min-w-56"
                disabled={disableCharacterSearch}
                placeholder="Código de turma"
                value={filters.class}
                onChange={({ target }) => handleSetFilters('class', target.value)}
              />
            </div>
          )}
        </div>
        {isFiltering && enableShowFiltered && (
          <FilterDisplay
            fixedClass={disableCharacterSearch}
            filters={filters}
            removeFilter={handleRemoveFilter}
          />
        )}
      </div>
    </>
  );
};
