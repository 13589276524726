export const formatDateFromString = (dateString: string) => {
  if (!dateString || typeof dateString !== 'string' || dateString.length !== 10) {
    throw new Error('Data no formato inválido. Esperado: "YYYY-MM-DD"');
  }

  const year = dateString.substring(0, 4);
  const month = dateString.substring(5, 7);
  const day = dateString.substring(8, 10);

  return `${day}/${month}/${year}`;
};
