import jwt from "jwt-decode";
import { Prefs } from "../App";

const verifyTeacher = (): boolean => {
    let teacher = false;

    const token = localStorage.getItem("teacher-token");

    if (token && token !== "") {
        const jwt_values: Prefs = jwt(token);

        if (jwt_values && jwt_values.type === "TEACHER") {
            teacher = true;
        }
    }

    return teacher;
};

export default verifyTeacher;
