import { cn } from '../../../../../util/cn';

type Badge = number;
interface TokenProps extends React.HTMLAttributes<HTMLDivElement> {
  value?: number;
  disableLabel?: boolean;
}

const badges: Record<Badge, string> = {
  0: '/img/challengegray.png',
  1: '/img/icons/bronze-token.png',
  2: '/img/icons/silver-token.png',
  3: '/img/icons/golden-token.png',
};

export const NewToken = ({ value, className, disableLabel = false }: TokenProps) => {
  return (
    <div
      className={cn(
        'flex h-14 w-full flex-row items-center justify-start gap-2 rounded-2xl border border-dashed border-black pl-2 font-[Raleway] font-bold',
        className,
      )}
    >
      <img className="h-12" src={value ? badges[value] : badges[0]} alt="Token do desafio" />
      {!disableLabel && 'Token'}
    </div>
  );
};
