import * as React from 'react';

import { recoverPassword, recoverPasswordTeacher } from '../../service';
import { PageLayout } from '../page-layout';
import { usePlaying } from '../../context/playing';
import { Paragraph } from '../common/Paragraph';
import { EmphasisTitle } from '../common/EmphasisTitle';
import { InputForm } from '../Input-form';
import Button from '../common/Button';
import { useNavigate } from 'react-router-dom';
import getHref from '../../util/getHref';

const RecoverPassword = () => {
  const [email, setEmail] = React.useState<string>('');
  const [emailSent, setEmailSent] = React.useState<boolean>(false);
  const [loading, setIsLoading] = React.useState<boolean>(false);
  const role = new URLSearchParams(window.location.search).get('role');
  const { origin } = usePlaying();
  const navigate = useNavigate();
  const handleRecoverPasswordPressed = async () => {
    if (email === '') return;
    try {
      setIsLoading(true);
      if (role === 'teacher') {
        await recoverPasswordTeacher(email);
      } else {
        await recoverPassword(email, origin);
      }
    } catch (error) {
      setEmailSent(true);
    } finally {
      setIsLoading(false);
    }
    setEmailSent(true);
  };

  return (
    <PageLayout className="mx-0 px-10 py-0 font-[Raleway] md:py-8">
      <div className="mx-0 flex h-full w-full items-center justify-center">
        <div className="flex w-full flex-col items-center justify-center sm:w-[45rem]">
          <img
            src={
              origin.includes('neoenergia')
                ? '/img/rockinrio/logo-rock-neo.png'
                : '/svg/cidadeazullogo.svg'
            }
            alt="logo"
            className="h-72 w-72 object-contain"
          />
          <EmphasisTitle className="mt-6 text-secondary">Redefinição de senha:</EmphasisTitle>
          <Paragraph className="my-6 text-center font-semibold">
            Insira no campo abaixo seu e-mail cadastrado para prosseguir com a redefinição de senha.
          </Paragraph>
          <div className="w-full px-14">
            <InputForm
              className="mb-8 bg-[#F3F3F3] px-8 text-start"
              value={email}
              label="Email"
              name="email"
              placeholder="Email"
              onChange={(e) => setEmail(e.currentTarget.value)}
            />
            {emailSent && (
              <Paragraph className="mb-6 text-center font-semibold text-[#DE3838]">
                As instruções para redefinir sua senha foram enviadas ao seu email.
                <br /> Confira na caixa de entrada ou spam.
              </Paragraph>
            )}
            <Button
              className="mb-6 w-full"
              onClick={handleRecoverPasswordPressed}
              value={!loading ? 'Redefinir senha' : 'Carregando...'}
              inactive={loading || emailSent}
            />
            {emailSent && (
              <div
                className="cursor-pointer text-center text-2xl font-bold leading-relaxed text-secondary md:text-3xl md:leading-relaxed"
                onClick={() => navigate(getHref('login'))}
              >
                Voltar para o login
              </div>
            )}
          </div>
        </div>
      </div>
    </PageLayout>
  );
};

export default RecoverPassword;
