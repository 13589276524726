import React from 'react';
import { InputForm } from '../Input-form';
import Button from './Button';
import { EmphasisTitle } from './EmphasisTitle';
import ModalComponent, { IModalRef } from './Modal';
import { Paragraph } from './Paragraph';

interface IModalResetPasswordProps {
  ref: React.ForwardedRef<IModalRef>;
  formik: any;
  loading: boolean;
  message: string;
}

export const ResetPasswordModal = React.forwardRef<IModalRef, IModalResetPasswordProps>(
  ({ formik, loading, message }, ref) => {
    return (
      <ModalComponent ref={ref} width="fit-content" height="fit-content">
        <form
          onSubmit={formik.handleSubmit}
          className="mx-36 my-16 flex w-full items-center justify-center"
        >
          <div className="mx-8 flex w-full flex-col items-center justify-center gap-6">
            <EmphasisTitle className="my-4 text-secondary">Redefinição de senha!</EmphasisTitle>
            <Paragraph className="text mb-4 text-center font-semibold">
              Aqui você escolhe uma nova senha.
              <br />
              Você vai precisar dela para entrar na plataforma.
            </Paragraph>
            <div className="flex w-8/12 flex-col gap-6">
              <InputForm
                className="px-8 text-start"
                name="password"
                label="Senha"
                placeholder="Senha"
                formik={formik}
                value={formik.values.password}
                error={formik.errors.password}
                type="password"
              />
              <InputForm
                className="px-8 text-start"
                name="confirmPassword"
                label="confirmPassword"
                placeholder="Confirme senha"
                formik={formik}
                value={formik.values.confirmPassword}
                error={formik.errors.confirmPassword}
                type="password"
              />
              {message && (
                <Paragraph className="mt-4 text-center font-semibold text-[#DE3838]">
                  {message}
                </Paragraph>
              )}
              <Button
                className="w-full"
                type="submit"
                value={!loading ? 'Confirmar' : 'Carregando...'}
                inactive={loading}
              />
            </div>
          </div>
        </form>
      </ModalComponent>
    );
  },
);
