type Props = {
  value: string;
};

const PageTitle = ({ value }: Props) => {
  return (
    <div className="absolute right-0 top-0 flex h-16 w-fit min-w-64 items-center justify-center rounded-b-3xl bg-secondary p-4 font-[Raleway] text-2xl font-bold text-secondaryForeground lg:h-24 lg:min-w-96 lg:p-8 lg:text-4xl">
      {value}
    </div>
  );
};

export default PageTitle;
