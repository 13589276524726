import React from 'react';
import { HTMLAttributes } from 'react';
import { cn } from '../../util/cn';
import { usePlaying } from '../../context/playing';

interface IFooterProps extends HTMLAttributes<HTMLDivElement> {}

export const Footer: React.FC<IFooterProps> = ({ className }) => {
  const { isPlaying, origin } = usePlaying();
  const handleLogoClick: React.MouseEventHandler<HTMLImageElement> = (e) => {
    window.open(e.currentTarget.alt, '_blank');
  };
  return (
    <div>
      <div
        className={`mb-6 flex items-center justify-center gap-4 sm:hidden ${isPlaying && 'hidden'} ${origin === 'neoenergia' && 'hidden'}`}
      >
        <img
          className="h-12 w-12 cursor-pointer object-contain text-primaryForeground"
          src="/img/icons/linkedin-mobile.png"
          alt="https://www.linkedin.com/company/the-blue-city/"
          onClick={handleLogoClick}
        />
        <img
          className="h-12 w-12 cursor-pointer object-contain text-primaryForeground"
          src="/img/icons/youtube-mobile.png"
          alt="https://www.youtube.com/channel/UC4CousnVU1Pzhxu0-PzvaWg"
          onClick={handleLogoClick}
        />
        <img
          className="h-12 w-12 cursor-pointer object-contain text-primaryForeground"
          src="/img/icons/instagram-mobile.png"
          alt="https://www.instagram.com/acidadeazul/"
          onClick={handleLogoClick}
        />
        <img
          className="h-12 w-12 cursor-pointer object-contain text-primaryForeground"
          src="/img/icons/tiktok-mobile.png"
          alt="https://www.tiktok.com/@acidadeazul"
          onClick={handleLogoClick}
        />
      </div>
      <div className={cn(`bg-primary text-neutral-50 ${isPlaying && 'hidden'}`, className)}>
        <div className="flex h-20 max-h-20 w-full items-center justify-between">
          <div className="ml-4 hidden h-20 w-2/4 items-center gap-4 sm:flex">
            <img
              className="h-12 w-12 cursor-pointer object-contain text-primaryForeground"
              src="/img/icons/linkedin.png"
              alt="https://www.linkedin.com/company/the-blue-city/"
              onClick={handleLogoClick}
            />
            <img
              className="h-12 w-12 cursor-pointer object-contain text-primaryForeground"
              src="/img/icons/youtube.png"
              alt="https://www.youtube.com/channel/UC4CousnVU1Pzhxu0-PzvaWg"
              onClick={handleLogoClick}
            />
            <img
              className="h-12 w-12 cursor-pointer object-contain text-primaryForeground"
              src="/img/icons/instagram.png"
              alt="https://www.instagram.com/acidadeazul/"
              onClick={handleLogoClick}
            />
            <img
              className="h-12 w-12 cursor-pointer object-contain text-primaryForeground"
              src="/img/icons/tiktok.png"
              alt="https://www.tiktok.com/@acidadeazul"
              onClick={handleLogoClick}
            />
            <p className="text-xl font-normal text-primaryForeground sm:text-xl md:text-2xl lg:text-3xl">
              contato@thebluecity.app
            </p>
          </div>
          <p className="ml-2 inline text-sm font-normal text-primaryForeground sm:hidden">
            contato@thebluecity.app
          </p>
          <div className="flex w-full items-center justify-end gap-4 pl-4 pr-4 lg:w-2/4">
            <img
              className="h-auto w-32 cursor-pointer object-contain text-primaryForeground sm:w-48"
              src="/img/LogoBlue.png"
              id="AppLogo-img-footer"
              alt="https://www.thebluecity.app/pt"
              onClick={handleLogoClick}
            />
            <p className="text-sm font-normal text-primaryForeground sm:text-xl md:text-2xl lg:text-3xl">
              Copyright © The Blue City.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};
