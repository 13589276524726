import { cn } from '../../util/cn';
import React, { HTMLAttributes } from 'react';
import { Paragraph } from '../common/Paragraph';

interface IInputForm extends HTMLAttributes<HTMLInputElement> {
  name: string;
  label: string;
  formik?: any;
  placeholder: string;
  disabled?: boolean;
  value?: string;
  error?: string;
  type?: string;
  onChange?: React.ChangeEventHandler<HTMLInputElement>;
}

export const InputForm = ({
  name,
  label,
  formik,
  placeholder,
  disabled,
  value,
  error,
  type,
  className,
  onChange,
}: IInputForm) => {
  const [showPassword, setShowPassword] = React.useState<boolean>(false);
  return (
    <div className="relative flex w-full flex-col items-start">
      <input
        className={cn(
          'border-input focus-visible:ring-ring flex h-16 w-full rounded-xl border border-gray-400 bg-[#F3F3F3] px-3 py-1 text-center text-3xl leading-relaxed shadow-sm transition-colors file:border-0 file:bg-transparent file:text-sm file:font-medium placeholder:text-gray-500 focus-visible:outline-none focus-visible:ring-1 disabled:cursor-not-allowed disabled:opacity-50',
          className,
          error && 'mb-2 border-red-500',
        )}
        name={name}
        id={name}
        placeholder={placeholder}
        onChange={formik ? formik.handleChange : onChange}
        onBlur={formik && formik.handleBlur}
        value={value}
        disabled={disabled}
        type={type === 'password' ? (showPassword ? 'text' : 'password') : type}
      />
      {type === 'password' ? (
        <div className="absolute right-6 top-3" onClick={() => setShowPassword(!showPassword)}>
          <img
            className="h-10 w-10 cursor-pointer"
            src={`/img/${showPassword ? `closedEye_password` : `eye_password`}.png`}
            alt="password"
          />
        </div>
      ) : (
        <></>
      )}
      {error && <Paragraph className="-mb-4 text-red-500 md:text-2xl">{error}</Paragraph>}
    </div>
  );
};
