interface Cities {
  [key: string]: string[];
}

export const citiesFromClient: Cities = {
  basf: ['guaratingueta'],
  aegea: ['teresina', 'sinop', 'piracicaba', 'cabofrio'],
};

export const companiesByCity: { [key: string]: string } = {
  teresina: 'aegea',
  cabofrio: 'aegea',
  sinop: 'aegea',
  jacarei: 'basf',
  piracicaba: 'aegea',
  guaratingueta: 'basf',
  rockinrio: 'rockinrio',
};

const verifyCityBelongsClient = (client: string | null): boolean => {
  if (!client) return false;
  const cities_for_client = citiesFromClient[client];
  return cities_for_client.includes(localStorage.getItem('city') ?? '');
};

export default verifyCityBelongsClient;
