import { useCallback, useEffect, useState } from 'react';
import { InstagramEmbed, TikTokEmbed, YouTubeEmbed } from 'react-social-media-embed';

interface VideoRenderProps extends React.HTMLAttributes<HTMLDivElement> {
  video: string;
  service: string;
  parentRef: React.RefObject<HTMLDivElement>;
}

export const VideoRender = ({ className, video, service, parentRef }: VideoRenderProps) => {
  const [width, setWidth] = useState(0);
  const resize = useCallback(() => {
    setTimeout(() => {
      setWidth(parentRef.current?.offsetWidth!);
    }, 300);
  }, [parentRef]);

  useEffect(() => {
    window.addEventListener('resize', resize);
    resize();
    return () => {
      window.removeEventListener('resize', resize);
    };
  }, [resize]);

  const renders: Record<string, JSX.Element> = {
    youtube: <YouTubeEmbed width={width} height={width * 0.6} url={video} />,
    tiktok: <TikTokEmbed width={width} url={video} />,
    instagram: <InstagramEmbed width={width} url={video} />,
  };
  return renders[service];
};
