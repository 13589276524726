import getClient from '../../util/getClient';
import getHref from '../../util/getHref';
import jwt from 'jwt-decode';
import { useGoogleLogin } from '@react-oauth/google';
import { useNavigate } from 'react-router-dom';
import { Prefs } from '../../App';
import { googleAuthentication } from '../../service';
import { cn } from '../../util/cn';

// import FacebookLogin from "@greatsumini/react-facebook-login";
// import { FACEBOOK_CLIENT_ID } from "../../config";
// import { facebookAuthentication, googleAuthentication } from "../../service";

type Props = {
  setLoginError: React.Dispatch<React.SetStateAction<boolean>>;
  authType: string;
  className?: string;
};

const OAuthIcons = ({ setLoginError, authType = 'USER', className }: Props) => {
  const navigate = useNavigate();
  const loginGoogle = useGoogleLogin({
    redirect_uri: window.location.href,
    onSuccess: async (codeResponse) => {
      const jwt_token = await googleAuthentication(
        codeResponse.code,
        window.location.origin,
        authType,
      );
      await createAuthenticationUsingToken(jwt_token);
    },
    onError: (response) => console.error(response),
    flow: 'auth-code',
  });

  const createAuthenticationUsingToken = async (jwt_token: any) => {
    try {
      localStorage.clear();
      const jwt_values: Prefs = jwt(jwt_token.data.token);

      if (jwt_values.type === 'TEACHER') {
        localStorage.setItem('teacher-token', jwt_token.data.token);
        localStorage.setItem('email', jwt_values.email);
        localStorage.setItem('teacher', 'true');
        localStorage.setItem('avatarName', jwt_values?.name || '');
        window.dispatchEvent(new Event('login'));
        return navigate('/painelprofessor');
      }

      localStorage.setItem('token', jwt_token.data.token);

      if (jwt_values.avatar && jwt_values.avatar !== '') {
        localStorage.setItem('avatar', jwt_values.avatar);
        localStorage.setItem('email', jwt_values.email);
        localStorage.setItem('avatarName', jwt_values.avatarName || '');
        window.dispatchEvent(new Event('login'));
      } else {
        return navigate(getHref('choose-avatar'));
      }

      if (jwt_values.city && jwt_values.city !== '') {
        localStorage.setItem('city', jwt_values.city);
        localStorage.setItem('game', jwt_values.game || '');
      } else {
        return navigate(getHref('choose-city'));
      }

      return navigate(getHref(''));
    } catch (error) {
      console.error({ error });
      setLoginError(true);
    }
  };

  return (
    <img
      className={cn('h-20 transform cursor-pointer hover:scale-110', className)}
      src="/svg/google.svg"
      alt="google-login"
      onClick={() => loginGoogle()}
    />
  );
};

export default OAuthIcons;
