import { cn } from '../../util/cn';

interface IParagraphProps {
  children: React.ReactNode;
  className?: string;
}
export const Paragraph = ({ className, children }: IParagraphProps) => {
  return (
    <p className={cn('text-2xl leading-relaxed md:text-3xl md:leading-relaxed', className)}>
      {children}
    </p>
  );
};
