// @ts-nocheck
import './style.css';
import LogRocket from 'logrocket';

import App from './App';
import ChooseAvatar from './components/Content/ChooseAvatar/ChooseAvatar';
import { ChooseCity } from './pages/choose-city';
import Game from './components/Content/Game';
import Home from './components/Content/Home/Home';
import { Login } from './components/Content/Login';
import LoginAdmin from './components/Content/LoginAdmin';
import NotFound from './components/common/NotFound';
import { PrivacyPolicy } from './components/Content/PrivacyPolicy';
import Profile from './components/Content/Profile';
import ProtectedRoute from './components/common/ProtectedRoute';
import Public from './Public';
import React, { Fragment, useEffect, useState } from 'react';
import ReactDOM from 'react-dom/client';
import RecoverPassword from './components/Content/RecoverPassword';
import Register from './components/Content/Register';
import RegulationAEGEA from './components/Content/RegulationAEGEA';
import RegulationBASF from './components/Content/RegulationBASF';
import TeacherEmailConfirmation from './components/Content/Teacher/TeacherEmailConfirmation';
import { LoginTeacher } from './components/Content/Teacher/LoginTeacher';
import RegisterTeacher from './components/Content/Teacher/RegisterTeacher';
import TeacherUpdate from './components/Content/Teacher/TeacherUpdate';
import { TermsAndConditions } from './components/Content/TermsAndConditions';
import TikTokRedirect from './components/Content/TikTokRedirect';
import UpdatePassword from './components/Content/UpdatePassword';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { MenuContext } from './components/common/context/Menu.Context';
import VLibras from './components/common/VLibras';
import { NewVideoGallery } from './pages/new-video-gallery';
import { VideoGallery } from './pages/video-gallery';
import { NewPublicGallery } from './pages/public-gallery';
import TeacherDashboardNew from './components/Content/Teacher/TeacherDashboardNew';
import { AdminPanel } from './pages/admin-panel';
import { TeacherClass } from './pages/teacher/class';
import { CompetitionRules } from './pages/competition-rules';
import TiktokPixel from 'tiktok-pixel';
import { AboutUs } from './pages/about-us/about-us';
import { AboutGames } from './pages/about-games/about-games';
import { Punctuation } from './pages/punctuation/punctuation';
import { StepByStep } from './pages/step-by-step/step-by-step';
import { TeacherGuide } from './pages/teacher-guide/teacher-guide';
import { StepByStepTeacher } from './pages/step-by-step-teacher/step-by-step-teacher';
import CulturalCompetition from './components/Content/CulturalCompetition';
import PerformancePanel from './pages/performance-panel/performance-panel';
import ScrollTop from './components/scroll-top/scroll-top';
import RegisterStudent from './components/Content/RegisterStudent';

LogRocket.init('ilaz7z/the-blue-city');
TiktokPixel.init('7304340345390006273');

const client_ids = ['aegea', 'basf'];

const MainApplication = () => {
  const [movieMode, setMovieMode] = useState<boolean>(false);

  useEffect(() => {
    const handleLogRocketIdentify = () => {
      const token = localStorage.getItem('token') ?? localStorage.getItem('teacher-token');
      const email = localStorage.getItem('email');
      const isTeacher = localStorage.getItem('teacher');
      if (token) {
        return LogRocket.identify(email, {
          email,
          type: isTeacher ? 'teacher' : 'student',
        });
      }
    };
    // navigator.mediaDevices.getUserMedia({ video: true });

    window.addEventListener('storage', handleLogRocketIdentify());
    return () => window.removeEventListener('login', handleLogRocketIdentify());
  }, []);

  return (
    <MenuContext.Provider value={{ movieMode, setMovieMode }}>
      <VLibras />
      <ScrollTop />
      <Routes>
        <Route path="/" element={<Public />}>
          <Route path="" element={<Home />} />
          {client_ids.map((path, index) => (
            <Fragment key={'routes-autentication-' + index}>
              <Route key={'route-login-' + index} path={path + '/login'} element={<Login />} />
            </Fragment>
          ))}
          <Route key={'login'} path={'/login'} element={<Login />} />
          <Route path="register" element={<Register />} />
          <Route path="register-student" element={<RegisterStudent />} />
          <Route path="choose-avatar" element={<ChooseAvatar />} />
          <Route path="cadastro-professor" element={<RegisterTeacher />} />
          <Route path="login-professor" element={<LoginTeacher />} />
          <Route path="teacher-email-confirmation" element={<TeacherEmailConfirmation />} />
          <Route path="login-admin" element={<LoginAdmin />} />
          <Route path="terms-and-conditions" element={<TermsAndConditions />} />
          <Route path="privacy-policy" element={<PrivacyPolicy />} />
          <Route path="regulation-basf" element={<RegulationBASF />} />
          <Route path="regulation-aegea" element={<RegulationAEGEA />} />
          <Route path="tiktokcallback" element={<TikTokRedirect />} />
          <Route path="recover-password" element={<RecoverPassword />} />
          <Route path="update-password" element={<UpdatePassword />} />
          <Route path="competition-rules" element={<CompetitionRules />} />
          <Route path="about-us" element={<AboutUs />} />
          <Route path="about-games" element={<AboutGames />} />
          <Route path="punctuation" element={<Punctuation />} />
          <Route path="step-by-step" element={<StepByStep />} />
          <Route path="teacher-guide" element={<TeacherGuide />} />
          <Route path="step-by-step-teacher" element={<StepByStepTeacher />} />
          <Route path="cultural-competition" element={<CulturalCompetition />} />
        </Route>

        <Route path="/" element={<App />}>
          <Route
            path="game/:gameId"
            element={
              <ProtectedRoute>
                <Game />
              </ProtectedRoute>
            }
          />
          <Route
            path="choose-city"
            element={
              <ProtectedRoute>
                <ChooseCity />
              </ProtectedRoute>
            }
          />
          <Route
            path="video-gallery"
            element={
              <ProtectedRoute>
                {/* <NewVideoGallery /> */}
                <VideoGallery />
              </ProtectedRoute>
            }
          />
          <Route
            path="public-video-gallery"
            element={
              <ProtectedRoute>
                <NewPublicGallery />
              </ProtectedRoute>
            }
          />
          <Route
            path="profile"
            element={
              <ProtectedRoute>
                <Profile />
              </ProtectedRoute>
            }
          />
          <Route
            path="painelprofessor"
            element={
              <ProtectedRoute>
                <TeacherDashboardNew />
              </ProtectedRoute>
            }
          />
          <Route
            path="painelprofessor/:id"
            element={
              <ProtectedRoute>
                <TeacherClass />
              </ProtectedRoute>
            }
          />
          <Route
            path="performance-panel"
            element={
              <ProtectedRoute>
                <PerformancePanel />
              </ProtectedRoute>
            }
          />
          <Route
            path="superadmin-dashboard"
            element={
              <ProtectedRoute>
                <AdminPanel />
              </ProtectedRoute>
            }
          />
          <Route
            path="atualizacao-professor"
            element={
              <ProtectedRoute>
                <TeacherUpdate />
              </ProtectedRoute>
            }
          />
        </Route>

        <Route path="*" element={<NotFound />} />
      </Routes>
    </MenuContext.Provider>
  );
};

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

root.render(
  <React.StrictMode>
    <BrowserRouter basename={'/'}>
      <MainApplication />
    </BrowserRouter>
  </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
