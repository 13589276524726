import tokenDidNotExpire from "./tokenDidNotExpire";

// import jwt from "jwt-decode";
// import { Prefs } from "../App";

const verifyLogged = (): boolean => {
    return (localStorage.getItem("token") != null && tokenDidNotExpire());
};

export default verifyLogged;
