import getClient from '../../../util/getClient';
import getHref from '../../../util/getHref';
import verifyLogged from '../../../util/verifyLogged';
import { useLocation, useNavigate } from 'react-router-dom';
import { isTeacher } from '../../../hooks/isTeacher';
import { usePlaying } from '../../../context/playing';
import { MenuItem } from '../../common/MenuItem';
import { AboutUsMenu } from './AboutUsMenu';

interface NavbarProps {
  hidePlayerItems: boolean;
}
const NavBar = ({ hidePlayerItems }: NavbarProps) => {
  const navigate = useNavigate();
  const location = useLocation();
  const logged = verifyLogged();
  const client = getClient();
  const { origin } = usePlaying();

  const goToBanners = () => {
    navigate(getHref(''));
    requestAnimationFrame(() => {
      const homeBanner = document.querySelector('#water-reuse-game-card');
      homeBanner?.scrollIntoView({ behavior: 'smooth', block: 'center' });
    });
  };

  const handleGalleryClick = () => {
    if (logged && !localStorage.getItem('city')) return navigate(getHref('choose-city'));
    if (!logged) {
      return navigate(getHref('login'));
    }
    return navigate(getHref('video-gallery'));
  };

  const handlePainelProfessorClick = () => {
    return navigate(getHref('painelprofessor'));
  };

  const handlePublicGalleryClick = () => {
    return navigate(getHref('public-video-gallery'));
  };

  const handlePlayClick = () => {
    if (logged && client.length === 0) return goToBanners();
    if (!logged) {
      return navigate(getHref('login'));
    }
    return navigate(getHref('game'));
  };

  if (hidePlayerItems) return null;

  return (
    <div className="align-center z-60 hidden w-[50%] items-center justify-around sm:flex sm:w-[58%] sm:flex-row">
      <>
        <MenuItem
          textClassName="font-semibold"
          text="Jogar"
          onClick={handlePlayClick}
          visible={
            (!isTeacher() && origin.includes('missaocidadeazul')) || origin.includes('localhost')
          }
        />
        <MenuItem
          textClassName="font-semibold"
          text="Galeria do Jogador"
          onClick={handleGalleryClick}
          visible={
            (!isTeacher() && origin.includes('missaocidadeazul')) || origin.includes('localhost')
          }
          active={location.pathname === getHref('video-gallery')}
        />
        <MenuItem
          textClassName="font-semibold"
          text="Galeria do Jogador"
          onClick={handlePainelProfessorClick}
          visible={isTeacher()}
        />
        <MenuItem
          textClassName="font-semibold"
          text="Galeria Geral"
          onClick={handlePublicGalleryClick}
          visible={origin.includes('missaocidadeazul') || origin.includes('localhost')}
          active={location.pathname === getHref('public-video-gallery')}
        />
        <div className="hidden sm:inline">
          <AboutUsMenu />
        </div>
      </>
    </div>
  );
};

export default NavBar;
