import React from 'react';
import PageMargin from '../../components/page-margin/page-margin';
import { EmphasisTitle } from '../../components/common/EmphasisTitle';
import { Paragraph } from '../../components/common/Paragraph';

export const AboutUs: React.FC = () => {
  return (
    <PageMargin>
      <div className="flex flex-1 flex-col gap-10 p-10 font-[Raleway]">
        <h1 className="text-4xl font-bold">Quem Somos</h1>
        <EmphasisTitle className="font-black sm:text-3xl">The Blue City</EmphasisTitle>
        <Paragraph>
          The Blue City é uma plataforma socioambiental que utiliza mundos virtuais 3D e gameficação
          para diferentes públicos:
        </Paragraph>
        <div className="grid w-full grid-cols-1 place-items-center gap-8 sm:grid-cols-2 md:grid-cols-4">
          <div className="flex flex-col items-center justify-center gap-8">
            <img src="img/about-us/quemsomos1.jpg" alt="education" />
            <div className="w-1/2 text-center">
              <p className="h-16 font-[Raleway] text-2xl font-semibold">Educação pela Ação</p>
            </div>
          </div>
          <div className="flex flex-col items-center justify-center gap-8">
            <img src="img/about-us/quemsomos2.jpg" alt="engagement" />
            <div className="w-1/2 text-center">
              <p className="h-16 text-2xl font-semibold">Engajamento de Consumidores</p>
            </div>
          </div>
          <div className="flex flex-col items-center justify-center gap-8">
            <img src="img/about-us/quemsomos3.jpg" alt="engagement-collaboration" />
            <div className="w-1/2 text-center">
              <p className="h-16 text-2xl font-semibold">Engajamento de Colaboradores</p>
            </div>
          </div>
          <div className="flex flex-col items-center justify-center gap-8">
            <img src="img/about-us/quemsomos4.jpg" alt="train" />
            <div className="w-1/2 text-center">
              <p className="h-16 text-2xl font-semibold">Treinamento em Equipe</p>
            </div>
          </div>
        </div>
        <EmphasisTitle className="font-black sm:text-3xl">Missão Cidade Azul</EmphasisTitle>
        <Paragraph>
          A Missão Cidade Azul é a versão educacional da plataforma The Blue City. É uma plataforma
          de jogos socioambientais que integra narrativas que exploram a relação entre diferentes
          culturas e o meio ambiente, destacando, por exemplo, as tradições dos povos indígenas e da
          cultura afro-brasileira. Essas histórias são criadas e protagonizadas por personagens que
          não só representam essas culturas e desenvolvidas por profissionais que as representam.
        </Paragraph>
        <img src="img/about-us/journey.png" alt="journey" />
        <Paragraph>
          No coração da plataforma, situam-se jogos ambientais em uma cidade virtual onde os
          jogadores, ao criar seus avatares, podem explorar mundos temáticos como O Mundo da Água, O
          Mundo do Saneamento e o Mundo dos Resíduos. Esses mundos são animados pelos personagens
          das narrativas, que assumem o papel de mentores. Eles orientam os jogadores, fornecendo
          dicas e conhecimento essencial para a jornada.
        </Paragraph>
        <Paragraph>
          Além da experiência virtual, a Missão Cidade Azul estende seu impacto para ações no mundo
          real, desafiando os jogadores a realizar missões como separação de resíduos ou plantio de
          árvores. Essas atividades são introduzidas através de vídeos criados por representantes
          das culturas destacadas, incentivando os jogadores a não apenas aprender, mas também a
          aplicar práticas sustentáveis em suas comunidades.
        </Paragraph>
        <Paragraph>
          Ao documentar e compartilhar suas experiências com as missões no mundo real, os jogadores
          enriquecem a plataforma, transformando-a em um espaço dinâmico de troca de conhecimentos e
          experiências, onde cada avatar serve como uma galeria das contribuições individuais para a
          sustentabilidade.
        </Paragraph>
        <EmphasisTitle className="font-black sm:text-3xl">Visão</EmphasisTitle>
        <Paragraph>
          A visão da Missão Cidade Azul é promover a conscientização e a educação ambiental através
          de uma plataforma interativa de jogos, incentivando a integração entre diferentes culturas
          e o meio ambiente. Ela busca inspirar ações positivas no mundo real que contribuam para a
          sustentabilidade e a preservação dos recursos naturais, empoderando indivíduos e
          comunidades a adotarem práticas mais sustentáveis e respeitosas ao meio ambiente.
        </Paragraph>
        <EmphasisTitle className="font-black sm:text-3xl">Missão</EmphasisTitle>
        <Paragraph>
          A missão da Missão Cidade Azul é engajar usuários através de uma combinação de narrativas
          culturais e jogos socioambientais que promovam a conscientização e ação ambiental. Através
          da educação e do entretenimento, a plataforma visa estimular mudanças comportamentais
          positivas nos jogadores, encorajando-os a realizar missões tanto no ambiente virtual
          quanto no real, que reflitam práticas sustentáveis e respeitosas em relação ao meio
          ambiente e às diversas culturas representadas.
        </Paragraph>
      </div>
    </PageMargin>
  );
};
