import { ButtonHTMLAttributes } from 'react';

interface GameCardProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  imagePath: string;
  clickable?: boolean;
  alt: string;
}

export const GameCard = ({ disabled, imagePath, clickable, alt, ...rest }: GameCardProps) => {
  return (
    <button
      disabled={disabled}
      className={`bg-none transition-all ${disabled ? 'cursor-not-allowed opacity-25 filter' : 'hover:brightness-110'}`}
      {...rest}
    >
      <img src={imagePath} width="100%" alt={alt} />
    </button>
  );
};
