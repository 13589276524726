import React, { useCallback, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  getTeacherListClass,
  getTeacherProfile,
  registerClass,
  removeTeacherClass,
  updateTeacher,
} from '../../../service';
import { toast } from 'react-toastify';
import * as Yup from 'yup';
import { PageLayout } from '../../page-layout';
import PageMargin from '../../page-margin/page-margin';
import Button from '../../common/Button';
import { InputForm } from '../../Input-form';
import { SelectForm } from '../../select-form';
import { allCities } from '../../../util/consts';
import { useLoading } from '../../../hooks/use-loading';
import ModalComponent, { IModalRef } from '../../common/Modal';
import { Paragraph } from '../../common/Paragraph';
import { useQuery } from '@tanstack/react-query';
import { capitalizeAfterSpace } from '../../../util/capitalizeString';
type ListClasses = {
  PK: string;
  SK: string;
  year: string;
  code: string;
  type: string;
  teacher_email: string;
  created_at: string;
};

const validationSchema = Yup.object({
  name: Yup.string()
    .required('Campo nome é obrigatório')
    .min(3, 'O nome deve ter pelo menos 3 caracteres'),
  email: Yup.string().required('Campo email obrigatório').email('Email inválido'),
  city: Yup.string().required('Campo cidade é obrigatório'),
  school: Yup.string()
    .required('Campo escola é obrigatório')
    .min(4, 'O nome da escola deve ter pelo menos 4 caracteres'),
});

const TeacherDashboardNew = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = React.useState<boolean>(false);
  const [name, setName] = React.useState<string>('');
  const [email, setEmail] = React.useState<string>('');
  const [school, setSchool] = React.useState<string>('');
  const [city, setCity] = React.useState<string>('');
  const [isEditing, setIsEditing] = React.useState<boolean>(false);
  const [profileSuccessMessage, setProfileSuccessMessage] = React.useState<string>('');
  const [classMessage, setClassMessage] = React.useState<string>('');

  const [classYear, setClassYear] = React.useState<string>('');
  const [classDelete, setClassDelete] = React.useState<string | null>(null);
  const [classType, setClassType] = React.useState<string>('Online');

  const modalDeleteRef = React.useRef<IModalRef>(null);

  const { setIsLoading } = useLoading();

  const listClasses = useQuery({
    queryKey: ['list-classes'],
    queryFn: getTeacherListClass,
  });

  const teacherProfile = useQuery({
    queryKey: ['teacher-profile'],
    queryFn: getTeacherProfile,
  });

  const handleDeleteYes = async () => {
    if (classDelete) {
      await removeClass(classDelete);
    }
    modalDeleteRef.current?.handleModal();
  };

  const handleDeleteNo = () => {
    modalDeleteRef.current?.handleModal();
  };

  React.useEffect(() => {
    console.log(listClasses.isLoading);
    if (listClasses.isLoading) {
      return setIsLoading(true);
    }
    return setIsLoading(false);
  }, [listClasses.isLoading, setIsLoading]);

  const handleClassInfo = async () => {
    if (!name || !email || !city || !school) {
      setClassMessage('Complete seus dados pessoais antes de criar os códigos de turma!');
      return;
    }
    setIsLoading(true);
    await registerClass(classYear, classType)
      .then(() => {
        setClassYear('');
        setClassMessage('Classe criada com sucesso!');
      })
      .finally(() => {
        setIsLoading(false);
        listClasses.refetch();
      });
  };

  const removeClass = async (classCode: string) => {
    setIsLoading(true);
    removeTeacherClass(classCode, email).finally(() => {
      setIsLoading(false);
      listClasses.refetch();
    });
  };

  const handleDelete = (class_code: string | null) => {
    modalDeleteRef?.current?.handleModal();
    setClassDelete(class_code);
  };

  const updateTeacherProfile = async () => {
    const valid = await validationSchema.validate({ name, email, city, school }).catch((error) => {
      toast.error(error.errors[0]);
      console.log(error);
      return false;
    });

    if (!valid) return;
    try {
      setLoading(true);
      await updateTeacher(name, email, city, school, false);
      localStorage.setItem('avatarName', name);
      localStorage.setItem('email', email);
      window.dispatchEvent(new Event('login'));
      setProfileSuccessMessage('Perfil atualizado com sucesso!');
      setIsEditing(false);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (!teacherProfile.data?.data) return;
    const profile = teacherProfile.data.data;
    setName(() => {
      setEmail(profile.email);
      setSchool(profile.school);
      setCity(profile.city);
      return profile.name;
    });
  }, [teacherProfile.data?.data]);

  const handleViewClass = useCallback(
    (classId: string) => {
      if (!classId) return;
      navigate(`/painelprofessor/${classId}`);
    },
    [navigate],
  );

  useEffect(() => {
    if (profileSuccessMessage) {
      setTimeout(() => {
        setProfileSuccessMessage('');
      }, 4000);
    }
  }, [profileSuccessMessage]);

  useEffect(() => {
    if (classMessage) {
      setTimeout(() => {
        setClassMessage('');
      }, 4000);
    }
  }, [classMessage]);

  return (
    <PageLayout pageTitle="Painel do Professor">
      <PageMargin>
        <div className="flex flex-col items-center justify-center lg:hidden">
          <h1 className="mt-16 text-center text-4xl leading-relaxed">
            Esta página está disponível apenas para Desktop
          </h1>
        </div>
        <div className="hidden flex-col gap-24 lg:flex">
          <p className="mt-16 text-4xl font-semibold leading-relaxed">
            Professora ou professor, bem-vinda(o) ao Painel do Professor. Aqui você encontra o
            material de apoio ao jogo e também como criar códigos de turma para suas turmas.
          </p>

          <div className="grid grid-cols-1 sm:grid-cols-2 sm:divide-x sm:divide-gray-300">
            <div className="flex w-full flex-col pr-16">
              <h2 className="text-4xl font-extrabold text-secondary">Dados pessoais</h2>
              <div className="mt-8">
                {!isEditing ? (
                  <div className="flex flex-col gap-4">
                    <div className="flex flex-row items-center gap-4">
                      <p className="text-3xl font-bold">Nome:</p>
                      <p className="text-3xl">{name}</p>
                    </div>
                    <div className="flex flex-row items-center gap-4">
                      <p className="text-3xl font-bold">E-mail:</p>
                      <p className="text-3xl">{email}</p>
                    </div>
                    <div className="flex flex-row items-center gap-4">
                      <p className="text-3xl font-bold">Senha:</p>
                      <p className="text-3xl">******</p>
                    </div>
                    <div className="flex flex-row items-center gap-4">
                      <p className="text-3xl font-bold">Cidade:</p>
                      <p className="text-3xl">{capitalizeAfterSpace(city) || ''}</p>
                    </div>
                    <div className="flex flex-row items-center gap-4">
                      <p className="text-3xl font-bold">Escola:</p>
                      <p className="text-3xl">{school}</p>
                    </div>
                  </div>
                ) : (
                  <div className="mt-8 flex w-full flex-col gap-8">
                    <InputForm
                      className="px-8 text-start"
                      name="name"
                      label="Nome"
                      placeholder="Nome"
                      value={name}
                      onChange={(e) => setName(e.target.value)}
                      error={!name ? 'Campo obrigatório' : undefined}
                    />
                    <InputForm
                      className="px-8 text-start"
                      name="email"
                      label="Email"
                      placeholder="Email"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      disabled
                    />
                    <SelectForm
                      className="px-8 text-start"
                      name="city"
                      placeholder="Cidade"
                      value={city}
                      options={allCities.map((city) => ({
                        value: city.value,
                        label: city.label,
                      }))}
                      onChange={(e) => setCity(e.target.value)}
                      error={!city ? 'Campo obrigatório' : undefined}
                    />
                    <InputForm
                      className="px-8 text-start"
                      name="school"
                      label="Escola"
                      placeholder="Escola"
                      value={school}
                      onChange={(e) => setSchool(e.target.value)}
                      error={!school ? 'Campo obrigatório' : undefined}
                    />
                  </div>
                )}
                {profileSuccessMessage && (
                  <Paragraph className="mt-6 text-left font-semibold text-[#DE3838]">
                    {profileSuccessMessage}
                  </Paragraph>
                )}
                <Button
                  className="my-8"
                  value={loading ? 'Carregando...' : !isEditing ? 'Alterar' : 'Salvar'}
                  onClick={() => (!isEditing ? setIsEditing(true) : updateTeacherProfile())}
                  inactive={loading}
                />
              </div>
              <div className="mt-16 flex w-full flex-col gap-4">
                <h2 className="text-4xl font-extrabold text-secondary">
                  Acesse o Material de Apoio de cada jogo:
                </h2>
                <p className="mb-8 text-3xl leading-relaxed">
                  Aqui você pode baixar o material de apoio para cada um dos mundos dentro do jogo,
                  incluindo os desafios e os vídeos das missões.
                </p>
                <div className="grid grid-cols-2 gap-8">
                  <div
                    className="cursor-pointer"
                    onClick={() =>
                      window.open(
                        'https://d2mkrcqum113x3.cloudfront.net/2024_ReusodeAgua_MaterialApoio.pdf',
                      )
                    }
                  >
                    <img src="/img/minigame-water2.png" alt="game-water" />
                  </div>
                  <div
                    className="cursor-pointer"
                    onClick={() =>
                      window.open(
                        'https://d2mkrcqum113x3.cloudfront.net/2024_HistoriaSaneamento_MaterialApoio.pdf',
                      )
                    }
                  >
                    <img src="/img/minigame-sanitation2.png" alt="game-sanitation" />
                  </div>
                  <div
                    className="cursor-pointer"
                    onClick={() =>
                      window.open(
                        'https://d2mkrcqum113x3.cloudfront.net/2024_ResiduosOrganicos_MaterialApoio.pdf',
                      )
                    }
                  >
                    <img src="/img/minigame-waste2.png" alt="game-waste" />
                  </div>
                  <div className="cursor-not-allowed opacity-25">
                    <img src="/img/banner6.png" alt="game-water" />
                  </div>
                </div>
              </div>
              <div className="mt-16 flex w-full flex-col gap-4">
                <h2 className="text-4xl font-extrabold text-secondary">
                  Acesse o modo offline de cada jogo:
                </h2>
                <p className="mb-8 text-3xl leading-relaxed">
                  Aqui você pode baixar o material do jogo offline para cada um dos mundos dentro do
                  jogo, incluindo os desafios e os videos das missões.
                </p>
                <div className="grid grid-cols-2 gap-8">
                  <div
                    className="cursor-pointer"
                    onClick={() =>
                      window.open(
                        'https://d2mkrcqum113x3.cloudfront.net/MissaoCidadeAzul_JogoARG_ReusodeAgua.pdf',
                      )
                    }
                  >
                    <img src="/img/off1.png" alt="game-water" />
                  </div>
                  <div
                    className="cursor-pointer"
                    onClick={() =>
                      window.open(
                        'https://d2mkrcqum113x3.cloudfront.net/MissaoCidadeAzul_JogoARG_AHistoriadoSaneamento.pdf',
                      )
                    }
                  >
                    <img src="/img/off2.png" alt="game-sanitation" />
                  </div>
                  <div
                    className="cursor-pointer"
                    onClick={() =>
                      window.open(
                        'https://d2mkrcqum113x3.cloudfront.net/MissaoCidadeAzul_JogoARG_ResiduosOrganicos.pdf',
                      )
                    }
                  >
                    <img src="/img/off3.png" alt="game-waste" />
                  </div>
                </div>
              </div>
            </div>
            <div className="mt-16 flex w-full flex-col pl-0 sm:mt-0 sm:pl-16">
              <h2 className="text-4xl font-extrabold text-secondary">
                Crie Códigos para suas Turmas:
              </h2>
              <p className="mb-8 mt-8 text-3xl leading-relaxed">
                Os códigos gerados aqui devem ser inseridos pelos alunos no momento de criar o
                perfil de jogador. Eles também podem ser inseridos posteriormente, clicando em
                alterar o perfil. Os códigos de turma servem para que você possa filtrar os vídeos
                de missões na Galeria Geral.
              </p>
              <div className="flex gap-4">
                <input
                  className="h-16 w-3/6 rounded-2xl border border-gray-300 bg-gray-100 p-4 text-[18px]"
                  placeholder="Insira o nome ou ano da turma"
                  value={classYear}
                  onChange={(e) => setClassYear(e.currentTarget.value)}
                />
                <label className="flex items-center gap-2 text-2xl">
                  <input
                    className="relative h-8 w-8 appearance-none rounded-full border-2 border-black"
                    type="checkbox"
                    onChange={() => setClassType('Online')}
                    checked={classType === 'Online'}
                  />
                  <span>Turma Online</span>
                </label>

                <label className="flex items-center gap-2 text-2xl">
                  <input
                    className="relative h-8 w-8 appearance-none rounded-full border-2 border-black"
                    type="checkbox"
                    onChange={() => setClassType('Offline')}
                    checked={classType === 'Offline'}
                  />
                  <span>Turma Offline</span>
                </label>

                <style>{`
                  input[type='checkbox']:checked {
                    background-color: transparent;
                  }

                  input[type='checkbox']:checked::after {
                    content: '';
                    display: block;
                    width: 50%;
                    height: 50%;
                    background-color: #C53030;
                    border-radius: 50%;
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                  }
                `}</style>
              </div>
              <div className="flex w-full flex-row gap-4">
                <Button
                  className="my-8 w-3/6 text-2xl lg:text-3xl"
                  value="Criar Código de Turma"
                  onClick={handleClassInfo}
                />
              </div>
              {classMessage && (
                <Paragraph className="mt-2 text-left font-semibold text-[#DE3838]">
                  {classMessage}
                </Paragraph>
              )}
              <h2 className="my-8 text-3xl font-bold">Código de turma criados</h2>
              <div className="flex w-full flex-col">
                {listClasses?.data?.data &&
                  listClasses?.data?.data.map((item) => (
                    <div
                      className="flex flex-col justify-start gap-4 lg:flex-row lg:items-center lg:justify-between"
                      key={item.id}
                    >
                      <div className="flex flex-col">
                        <p className="text-3xl font-bold leading-relaxed">Ano: {item.year}</p>
                        <p className="text-3xl leading-relaxed">Código da Turma: {item.code}</p>
                        <p className="text-3xl leading-relaxed">Tipo da Turma: {item.type}</p>
                      </div>
                      <div className="flex flex-row gap-4">
                        <Button
                          className="h-12 bg-[#009C9D] text-2xl"
                          value="Ver turma"
                          onClick={() => handleViewClass(item.code)}
                        />
                        <Button
                          className="h-12 bg-zinc-400 text-2xl"
                          value="Apagar"
                          onClick={() => handleDelete(item.code)}
                        />
                      </div>
                    </div>
                  ))}
              </div>
            </div>
          </div>
          <ModalComponent ref={modalDeleteRef} width="fit-content" height="fit-content">
            <div className="mx-8 my-8 mt-20 flex flex-col items-center">
              <Paragraph className="mb-8 text-center">
                Tem certeza que você deseja excluir esta turma? Essa ação é irreversível.
              </Paragraph>
              <div className="flex flex-row justify-between gap-4 sm:w-8/12">
                <Button value="Sim" onClick={handleDeleteYes} />
                <Button value="Não" onClick={handleDeleteNo} />
              </div>
            </div>
          </ModalComponent>
        </div>
      </PageMargin>
    </PageLayout>
  );
};

export default TeacherDashboardNew;
