import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { usePlaying } from '../../context/playing';
import getHref from '../../util/getHref';

interface IAppLogoProps {
  setMovieMode: (show: boolean) => void;
  hidePlayerItems: boolean;
}

const AppLogo: React.FC<IAppLogoProps> = ({ setMovieMode, hidePlayerItems }) => {
  const navigate = useNavigate();
  const [isHovered, setIsHovered] = React.useState(false);
  const location = useLocation();
  let isTeacherDashboard = location.pathname === '/painelprofessor';
  const { origin } = usePlaying();
  const logoUri = origin.includes('neoenergia') ? '/svg/rockinrio.svg' : '/svg/logo.svg';
  const title = origin.includes('neoenergia') ? 'Rock in Rio' : 'Missão Cidade Azul';

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  return (
    <div
      className="m-4 flex cursor-pointer items-center justify-between gap-4"
      onClick={() => {
        if (hidePlayerItems) {
          localStorage.clear();
          return navigate(getHref(''));
        }
        navigate('/');
        setMovieMode(false);
      }}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <img src={logoUri} className="h-12 md:h-16" alt="logo" />
      <div className="w-auto font-[Raleway] text-xl font-bold md:flex lg:text-3xl">{title}</div>
      {isHovered && isTeacherDashboard && (
        <span className="text-hover">
          Por favor, saia (deslogar) do <br />
          painel do professor para entrar <br />
          na plataforma de jogos
        </span>
      )}
    </div>
  );
};

export default AppLogo;
