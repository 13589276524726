const teacher_routes = [
  '/painelprofessor',
  '/painelprofessor/:id',
  '/professor/class/:id',
  '/atualizacao-professor',
  '/login-professor',
  '/cadastro-professor',
];

const verifyTeacherRoute = (route: string): boolean => {
  const isTeacherRoute = teacher_routes.some((teacherRoute) => {
    const regex = new RegExp(`^${teacherRoute.replace(/\/:\w+/g, '/\\w+')}$`);
    return regex.test(route);
  });
  return isTeacherRoute;
};

export default verifyTeacherRoute;
