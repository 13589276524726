import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';

import { PageLayout } from '../../components/page-layout';
import { getPublishedVideos } from '../../service';
import { Card } from '../../components/card';
import { VideoRender } from '../../components/video-render';
import { gradeClassifier } from '../../util/grade-classifier';
import { Filters, FilterComponent } from '../../components/filter-component';
import { ratings } from '../../components/filter-component/options';
import { companiesByCity } from '../../util/verifyCityBelongsClient';
import { firstCharacterToUppercase } from '../../util/first-character-touppercase';
import { applyFilters } from '../../components/filter-component/apply-filters';
import PageMargin from '../../components/page-margin/page-margin';
import { EmphasisTitle } from '../../components/common/EmphasisTitle';
import { useLoading } from '../../hooks/use-loading';
import PaginatedVideoList from '../../components/common/PaginatedVideoList';
import useBreakpoint from '../../hooks/use-breakpoint';
import { capitalizeWords } from '../../hooks/captalize-words';

export const NewPublicGallery = () => {
  const [filters, setFilters] = useState<Filters>({
    city: '',
    company: '',
    mission: '',
    rating: '',
    world: '',
    class: '',
    game: '',
  });

  const [currentPage, setCurrentPage] = React.useState<number>(0);
  const [itemsPerPage, setItemsPerPage] = React.useState<number>(0);
  const [videos, setVideos] = useState<VideoData[]>([]);
  const videoRenderParentRef = useRef<HTMLDivElement>(null);
  const { setIsLoading } = useLoading();
  const breakpoint = useBreakpoint();

  useLayoutEffect(() => {
    setIsLoading(true);
    const fetchVideos = async () => {
      const response = await getPublishedVideos();
      setVideos(response);
      setIsLoading(false);
    };
    fetchVideos();
  }, [setIsLoading]);

  useEffect(() => {
    if (breakpoint === '2xl' || breakpoint === 'xl' || breakpoint === 'lg') {
      return setItemsPerPage(() => {
        setCurrentPage(0);
        return 9;
      });
    }
    if (breakpoint === 'md') {
      return setItemsPerPage(() => {
        setCurrentPage(0);
        return 6;
      });
    }
    return setItemsPerPage(() => {
      setCurrentPage(0);
      return 3;
    });
  }, [breakpoint]);

  useEffect(() => {
    setCurrentPage(0);
  }, [filters]);

  const renderVideos = React.useMemo((): JSX.Element[] => {
    if (!videos || !videos.length) return [];
    return videos
      .filter((video) => applyFilters(video, filters))
      .map((video) => {
        return (
          <Card className="w-full items-start bg-[#E2E2E2]">
            <div className="mb-4 flex w-full justify-center" ref={videoRenderParentRef}>
              <VideoRender
                parentRef={videoRenderParentRef}
                className="flex w-full justify-center"
                video={video?.link_video}
                service={video?.service}
              />
            </div>
            <div className="flex flex-col gap-4">
              <div className="flex flex-row items-start gap-2">
                <p className="text-2xl">
                  <b>Nome do jogador:</b> {video?.userName ? capitalizeWords(video?.userName) : '-'}
                </p>
              </div>
              <div className="flex items-center gap-2">
                <p className="text-2xl font-extrabold">Avatar:</p>
                <p className="text-2xl">{video?.userAvatar}</p>
              </div>
              <div className="flex items-center gap-2">
                <p className="text-2xl font-extrabold">Empresa:</p>
                <p className="text-2xl">
                  {capitalizeWords(companiesByCity[video.city.toLowerCase().trim()])}
                </p>
              </div>
              <div className="flex items-center gap-2">
                <p className="text-2xl font-extrabold">Cidade:</p>
                <p className="text-2xl">
                  {firstCharacterToUppercase(capitalizeWords(video?.city))}
                </p>
              </div>
              <div className="flex items-center gap-2">
                <p className="text-2xl font-extrabold">Mundo:</p>
                <p className="text-2xl">{capitalizeWords(video?.world)}</p>
              </div>
              <div className="flex items-center gap-2">
                <p className="text-2xl font-extrabold">Jogo:</p>
                <p className="text-2xl">{capitalizeWords(video?.game)}</p>
              </div>
              <div className="flex items-center gap-2">
                <p className="text-2xl font-extrabold">Missão:</p>
                <p className="text-2xl">{capitalizeWords(video?.mission)}</p>
              </div>
              <div className="flex items-center gap-2">
                <p className="text-2xl font-extrabold">Pontuação total:</p>
                <p className="text-2xl">
                  {video?.totalGameScore === 0
                    ? video?.total_score
                    : (video?.score || 0) + video?.totalGameScore}
                </p>
              </div>
              <div className="flex items-center gap-2">
                <p className="text-2xl font-extrabold">Avaliação:</p>
                <p className="text-2xl">
                  {ratings.find((rating) => rating.value === gradeClassifier(video?.total_score!))
                    ?.label || 'Não cumpriu! :('}
                </p>
              </div>
              <div className="flex flex-row items-start gap-2">
                <p className="text-2xl">
                  <b>Nome da Escola:</b>{' '}
                  {video?.schoolName ? capitalizeWords(video?.schoolName) : '-'}
                </p>
              </div>
              <div className="flex items-center gap-2">
                <p className="text-2xl font-extrabold">Código de Turma:</p>
                <p className="text-2xl">{video?.class_code}</p>
              </div>
            </div>
          </Card>
        );
      });
  }, [filters, videos]);

  return (
    <PageLayout pageTitle="Galeria Geral">
      <PageMargin className="mt-10 min-h-[calc(100vh-21rem)]">
        <EmphasisTitle className="font-semibold sm:leading-relaxed">
          Bem-vinda(o) à Galeria Geral. Aqui você encontra os vídeos aprovados de todas as jogadoras
          ou jogadores. Utilize os filtros abaixo para filtrar os vídeos por cidade, por mundo, por
          missão e os melhores.
        </EmphasisTitle>
        <FilterComponent
          filters={filters}
          setFilters={setFilters}
          enableRatingFilter
          enableCompanyFilter
          enableCityFilter
        />

        {!renderVideos.length ? (
          <div className="flex h-full w-full items-center justify-center">
            <h1 className="text-4xl font-semibold">Não há videos publicados neste momento!</h1>
          </div>
        ) : (
          <PaginatedVideoList
            renderItems={() => renderVideos}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            itemsPerPage={itemsPerPage}
          />
        )}
      </PageMargin>
    </PageLayout>
  );
};
