import * as React from 'react';
import AvatarIcon from './AvatarIcon';
import { Paragraph } from '../../common/Paragraph';

const avatarIcons = [
  '/img/avatars/avatar0.png',
  '/img/avatars/avatar1.png',
  '/img/avatars/avatar2.png',
  '/img/avatars/avatar3.png',
  '/img/avatars/avatar4.png',
  '/img/avatars/avatar5.png',
  '/img/avatars/avatar6.png',
  '/img/avatars/avatar7.png',
];

type Props = {
  setSelectedAvatar: React.Dispatch<React.SetStateAction<string>>;
  avatar?: string;
  isCreating: boolean;
};

const AvatarList = ({ setSelectedAvatar, avatar, isCreating }: Props) => {
  const [avatars, setAvatars] = React.useState<HTMLImageElement[]>([]);

  React.useEffect(() => {
    const newAvatars: HTMLImageElement[] = [];
    document
      .querySelectorAll('.Avatar-icon')
      .forEach((element) => newAvatars.push(element as HTMLImageElement));
    setAvatars(newAvatars);
  }, []);

  const handleAvatarClick: React.MouseEventHandler<HTMLImageElement> = (
    event: React.MouseEvent<HTMLImageElement, MouseEvent>,
  ) => {
    setSelectedAvatar(event.currentTarget.id);
  };

  return (
    <div className="flex flex-col">
      <div className="mb-8 mt-8 flex w-full flex-row flex-wrap items-center justify-center gap-8">
        {avatarIcons.map((avatarItem, i) => (
          <AvatarIcon
            key={'avatar-icon-' + i}
            src={avatarItem}
            id={`avatar${i}`}
            selected={!avatar ? false : avatarItem.includes(avatar)}
            onClick={handleAvatarClick}
          />
        ))}
      </div>
    </div>
  );
};

export default AvatarList;
