import PageMargin from '../page-margin/page-margin';

const CulturalCompetition = () => {
  return (
    <PageMargin>
      <div className="my-20 flex flex-col gap-12 text-3xl font-medium leading-loose">
        <h1 className="text-4xl font-bold">
          Concurso Cultural Influenciador Ambiental Missão Cidade Azul
        </h1>
        <p>
          <b>1.</b> O Concurso Cultural Missão Cidade Azul (“Concurso”) é voltado a qualquer pessoa
          física, usuário da plataforma Missão Cidade Azul (“Participantes”) localizados no Brasil,
          e visa premiar melhores jogadores no primeiro ciclo de jogos – de 15 de Abril e 15 de
          Julho de 2024 e no segundo ciclo de jogos – de 15 de Agosto a 15 de Novembro de 2024.
        </p>

        <p>
          <b>2.</b> O Concurso é válido para todos os usuários da plataforma Missão Cidade Azul
          dentro do território do Brasil.
        </p>

        <p>
          <b>3.</b> Para participar do Concurso, o Participante deverá estar inscrito na plataforma
          da Missão Cidade Azul durante o período de 15 de Abril a 15 de Novembro de 2024.
        </p>

        <p>
          <b>4.</b> Os melhores jogadores são aqueles que possuem a melhor pontuação nos jogos
          virtuais e nos vídeos enviados. Os vídeos poderão ser enviados através da plataforma
          Missão Cidade Azul, seguindo as instruções de submissão disponíveis no site. Para entender
          o sistema de pontuação, visite o site, no menu Sobre Nós, Sobre os Jogos.
        </p>

        <p>
          <b>5.</b> Os participantes poderão participar do Concurso completando os desafios dos
          jogos virtuais e enviando apenas 1 vídeo para cada missão, desde que cada vídeo seja
          distinto e atenda aos critérios estabelecidos neste regulamento.
        </p>

        <p>
          <b>6.</b> Uma comissão julgadora formada por membros da equipe da Missão Cidade Azul
          escolherá os vídeos mais qualificados dentre todos os Participantes.
        </p>

        <p>
          <b>7.</b> Seis Participantes vencedores serão premiados com um kit criador de conteúdo,
          que inclui um ring light, um bastão led e um microfone. Além do kit, cada vencedor ganhará
          uma consultoria de criação de conteúdo que será entregue em até 30 dias após a data de
          divulgação do resultado. Três Participantes vencedores serão escolhidos no primeiro ciclo
          - de 15 de Abril e 15 de Julho de 2024 - e três Participantes no segundo ciclo - de 15 de
          Agosto a 15 de Novembro de 2024.
        </p>

        <p>
          <b>8.</b> A escolha dos vencedores será realizada no dia 20 de Julho de 2024 para o
          primeiro ciclo de jogos e 20 de Novembro de 2024 para o segundo ciclo de jogos e a
          divulgação dos vencedores será feita através do site da Missão Cidade Azul e em suas redes
          sociais.
        </p>

        <p>
          <b>9.</b> São proibidos, e não serão aceitos ou válidos para participação no Concurso, os
          vídeos que: (i) possuírem conteúdo imoral ou ilícito; (ii) não forem enviados através da
          plataforma; ou (iii) cuja autenticidade não puder ser comprovada.
        </p>

        <p>
          <b>10.</b> A Missão Cidade Azul entrará em contato até 5 (cinco) dias após a escolha do
          vencedor, utilizando-se dos dados cadastrados na plataforma, para combinar a entrega do
          prêmio.
        </p>

        <p>
          <b>11.</b> O prêmio será enviado ao endereço do Participante conforme informado
          posteriormente pelo mesmo após contato.
        </p>

        <p>
          <b>12.</b> A Missão Cidade Azul não assumirá nenhuma responsabilidade em decorrência de
          informações incorretas fornecidas pelos Participantes.
        </p>

        <p>
          <b>13.</b> O prêmio é intransferível, não poderá ser convertido em dinheiro, transferido
          para terceiros ou trocado por outro produto.
        </p>

        <p>
          <b>14.</b> Os Participantes autorizam de forma não onerosa a utilização do seu nome, seu
          vídeo, sua imagem e/ou som de voz na Internet e na mídia existente ou que venha a ser
          futuramente criada, sem qualquer ônus para a Missão Cidade Azul.
        </p>

        <p>
          <b>15.</b> Todos os Participantes concordam e cedem, antecipadamente, de forma total e não
          onerosa os direitos autorais patrimoniais de seus vídeos, para que a Missão Cidade Azul
          possa publicá-los ou usá-los de qualquer forma e para qualquer finalidade.
        </p>

        <p>
          <b>16.</b> Não poderão participar deste Concurso os empregados ou colaboradores da Missão
          Cidade Azul, bem como seus parentes de primeiro grau.
        </p>

        <p>
          <b>17.</b> Os Participantes são responsáveis pelos vídeos enviados, declarando-se desde já
          como legítimos e exclusivos titulares de seus direitos.
        </p>

        <p>
          <b>18.</b> Da escolha do vídeo vencedor não caberá recurso.
        </p>

        <p>
          <b>19.</b> Eventuais dúvidas podem ser esclarecidas através do canal de contato da Missão
          Cidade Azul por e-mail ou WhatsApp.
        </p>

        <p>
          <b>20.</b> Os Participantes declaram e afirmam ter conhecimento dos termos e condições
          acima dispostos e, através da participação no Concurso, manifestam sua concordância com
          todos os pontos apresentados acima, de maneira incondicional e irretratável.
        </p>

        <p>
          <b>21.</b> Este concurso não possui qualquer modalidade de sorte ou pagamentos pelos
          participantes, nem vinculação destes ou dos vencedores à aquisição ou uso de qualquer bem,
          direito ou serviço, promovido de acordo com a Lei n° 5768/71 e Decreto n° 70.951/72,
          artigo 30.
        </p>

        <p>
          <b>22.</b> A Missão Cidade Azul está comprometida com o tratamento de dados com
          responsabilidade e transparência conforme a Lei Federal nº13.709/2018, a Lei Geral de
          Proteção de Dados (LGPD). Os participantes do concurso autorizam o uso de seus dados
          pessoais para fins deste concurso.
        </p>
      </div>
    </PageMargin>
  );
};

export default CulturalCompetition;
